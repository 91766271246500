// Notify
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// API
import api from '../../../api/axios.config';

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import AccountFormSchema from './AccountFormSchema';

const AccountForm = ({
  status,
  account = {},
  getAccounts,
  toggleModal,
}) => {

  const username = localStorage.getItem('cdnzUser');
  const token = localStorage.getItem('cdnzAccessToken');

  const initialValues = {
    accountName: '',
    salesforceLink: '',
    accountOwnerName: '',
    accountOwnerRole: '',
    accountOwnerPhoneOwner: '',
    accountOwnerEmail: '',
    emergencyContactName: '',
    emergencyContactPhoneNumber: '',
    emergencyContactEmail: '',
    emergencyContactRole: '',
    clientNotificationEmail: '',
  };

  const handleSubmitCreate = async (values, formikBag) => {
    try {
      await AccountFormSchema.validate(values, { abortEarly: false });
      const response = await api.post(
        '/accounts',
        {
          ...values,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      );

      if (response.status === 200) {
        Notify.success(
          `The account "${values.accountName}" was successfully created.`,
          notifyOptions
        );
        await getAccounts();
        toggleModal();
      } else {
        Notify.failure('Something went wrong.', notifyOptions);
        throw new Error('Something went wrong');
      }
    } catch (error) {
      formikBag.setErrors(error.inner.reduce((errors, err) => {
        errors[err.path] = err.message;
        return errors;
      }, {}));
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  const handleSubmitUpdate = async (values, formikBag) => {
    try {
      await AccountFormSchema.validate(values, { abortEarly: false });
      const response = await api.put(
        '/accounts',
        {
          ...values,
          // Other parameters here
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      );

      if (response.status === 200) {
        Notify.success(
          `The account "${values.accountName}" was successfully updated.`,
          notifyOptions
        );
        await getAccounts();
        toggleModal();

        // window.location.href = '/';
      } else {
        Notify.failure('Something went wrong.', notifyOptions);
        throw new Error('Something went wrong');
      }
    } catch (error) {
      formikBag.setErrors(error.inner.reduce((errors, err) => {
        errors[err.path] = err.message;
        return errors;
      }, {}));
    } finally {
      formikBag.setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col">
      <h1 className="text-2xl font-bold text-center mb-6">
        {status === 'edit' ? 'Edit' : 'Create'} Account
      </h1>

      <Formik
        initialValues={status === 'edit' ? account : initialValues}
        validationSchema={AccountFormSchema}
        onSubmit={(values, formikBag) => {
          if (status === 'edit') {
            handleSubmitUpdate(values, formikBag);
          } else {
            handleSubmitCreate(values, formikBag);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="account-form">
            <div className="md:grid grid-cols-2 gap-8">
              <div className="account-container">
                {status === 'edit' &&
                  <>
                    <h3 className="mt-4 mb-2 font-bold">Account ID</h3>
                    <p>{!!account.accountID ? account.accountID : '-'}</p>
                  </>
                }

                <h3 className="mt-4 mb-2 font-bold">Salesforce Link</h3>
                <Field
                  type="text"
                  name="salesforceLink"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Salesforce Link"
                />
                <ErrorMessage
                  name="salesforceLink"
                  component="div"
                  className="text-red-500"
                />
              </div>
              <div className="account-container">
                <h3 className="mt-4 mb-2 font-bold">Account Name</h3>
                {status === 'edit' ? (
                  <p>{account.accountName}</p>
                ) : (
                  <>
                    <Field
                      type="text"
                      name="accountName"
                      className="rounded-md w-full py-1 px-2"
                      placeholder="Account Name"
                    />
                    <ErrorMessage
                      name="accountName"
                      component="div"
                      className="text-red-500"
                    />
                  </>
                )}
              </div>
            </div>

            <h2 className="mt-8 font-bold text-lg">Client details</h2>
            <div className="md:grid grid-cols-2 gap-8">
              <div>
                <h3 className="mt-3 mb-2 font-bold">Contact</h3>
                <Field
                  type="text"
                  name="accountOwnerName"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Account Contact"
                />
                <ErrorMessage
                  name="accountOwnerName"
                  component="div"
                  className="text-red-500"
                />

                <h3 className="mt-4 mb-2 font-bold">Role</h3>
                <Field
                  type="text"
                  name="accountOwnerRole"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Account Contact Role"
                />
                <ErrorMessage
                  name="accountOwnerRole"
                  component="div"
                  className="text-red-500"
                />

                <h3 className="mt-4 font-bold">Notification's Email</h3>
                <h4 className="mb-2">(Consider a distribution list)</h4>
                <Field
                  type="text"
                  name="clientNotificationEmail"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="clientNotificationEmail"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div>
                <h3 className="mt-3 mb-2 font-bold">Phone</h3>
                <Field
                  type="text"
                  name="accountOwnerPhoneOwner"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="(xxx)xxx-xxxx"
                />
                <ErrorMessage
                  name="accountOwnerPhoneOwner"
                  component="div"
                  className="text-red-500"
                />

                <h3 className="mt-4 mb-2 font-bold">Email</h3>
                <Field
                  type="text"
                  name="accountOwnerEmail"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="accountOwnerEmail"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>

            <h2 className="mt-8 font-bold text-lg">Cadenza service details</h2>
            <div className="md:grid grid-cols-2 gap-8">
              <div>
                <h3 className="mt-3 mb-2 font-bold">Contact</h3>
                <Field
                  type="text"
                  name="emergencyContactName"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Emergency Contact"
                />
                <ErrorMessage
                  name="emergencyContactName"
                  component="div"
                  className="text-red-500"
                />

                <h3 className="mt-4 mb-2 font-bold">Role</h3>
                <Field
                  type="text"
                  name="emergencyContactRole"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Emergency Contact Role"
                />
                <ErrorMessage
                  name="emergencyContactRole"
                  component="div"
                  className="text-red-500"
                />
              </div>

              <div>
                <h3 className="mt-3 mb-2 font-bold">Phone</h3>
                <Field
                  type="text"
                  name="emergencyContactPhoneNumber"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="(xxx)xxx-xxxx"
                />
                <ErrorMessage
                  name="emergencyContactPhoneNumber"
                  component="div"
                  className="text-red-500"
                />

                <h3 className="mt-4 mb-2 font-bold">Email</h3>
                <Field
                  type="text"
                  name="emergencyContactEmail"
                  className="rounded-md w-full py-1 px-2"
                  placeholder="Email"
                />
                <ErrorMessage
                  name="emergencyContactEmail"
                  component="div"
                  className="text-red-500"
                />
              </div>
            </div>

            <div className="flex justify-around mt-8">
              <button
                type="submit"
                className="rounded-md px-4 py-2 text-sm"
                disabled={isSubmitting}
              >
                {status === 'edit' ? 'Save' : 'Create Account'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AccountForm;
