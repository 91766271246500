import axios from 'axios';

export const baseURL = process.env.REACT_APP_BASE_URL;
export const axiosBaseUrl = axios.create({ baseURL });

export const token = {
  set(token) {
    axiosBaseUrl.defaults.headers.common['Authorization'] = token
      ? `Bearer ${token}`
      : '';
  },
  unset() {
    this.set();
  },
};
