// React
import React, { useEffect, useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import userOperations from '../redux/user/userOperations';
import { userSelectors } from '../redux/user/userSelectors';

// Components
import Loader from '../components/Loader';
import ProfileField from '../components/profile/ProfileField';
import ProfileInputField from '../components/profile/ProfileInputField';

// Icon
import { IconContext } from 'react-icons';
import { FiEdit } from 'react-icons/fi';

export default function Profile() {
  const userDetails = useSelector(userSelectors.getUserDetailsData);
  const userDetailsLoading = useSelector(userSelectors.getUserDetailsLoading);
  const isLoadingUpdateUser = useSelector(userSelectors.getUserUpdateLoading);

  // State
  const [isEditable, setIsEditable] = useState(false);
  const [formData, setFormData] = useState({
    accountNumber: userDetails.accountID,
    username: userDetails.userName,
    userSubtype: userDetails.userSubtype,
    phoneNumber: userDetails.phoneNumber,
    accountType: userDetails.accountType,
    fullName: userDetails.fullName,
    title: userDetails.title,
    email: userDetails.email,
  });

  // Separate state for edited data
  const [editedData, setEditedData] = useState(formData);

  // dispatch
  const dispatch = useDispatch();

  // get user details
  useEffect(() => {
    dispatch(userOperations.getUserDetails());
  }, [dispatch]);

  useEffect(() => {
    setFormData({
      ...formData,
      fullName: userDetails.fullName,
      phoneNumber: userDetails.phoneNumber,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.fullName, userDetails.phoneNumber]);

  // Handlers
  const handleEditClick = () => {
    if (!isEditable) {
      setIsEditable(true);
    } else {
      // Cancel editing and reset the form data
      setIsEditable(false);
      setEditedData(formData); // Reset edited data to original data
    }
  };

  useEffect(() => {
    if (!isEditable) {
      // Reset edited data to original data when canceling editing
      setEditedData(formData);
    }
  }, [isEditable, formData]);

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    setEditedData({
      ...editedData,
      [name]: value,
    });
  };

  const handleSubmitUpdate = () => {
    // Update the formData with the edited data immediately
    setFormData(editedData);

    // Dispatch the update operation
    dispatch(
      userOperations.updateUserDetails({
        userData: editedData,
        toggleModal: handleEditClick,
      })
    );
  };

  return (
    <main className="flex justify-center p-5 grow min-h-full ">
      <section className="container container-border bg-white p-6 md:px-36 overflow-auto relative">
        {userDetailsLoading ? (
          <div className="flex justify-center items-center h-full">
            <Loader classNames="h-32 w-32" />
          </div>
        ) : (
          <>
            <button
              className="absolute top-4 right-6"
              onClick={handleEditClick}
            >
              <IconContext.Provider value={{ color: '#0F3052' }}>
                <FiEdit size={28} />
              </IconContext.Provider>
            </button>
            <div className="profile-container">
              <div className="profile-container flex flex-col items-center my-6 ">
                <h1 className="text-2xl font-bold text-center mb-6">
                  Profile details
                </h1>

                <div className="w-full md:flex">
                  <div className="md:grid grid-cols-1 gap-8 w-4/5">
                    <div className="account-container static">
                      {!isEditable ? (
                        <ProfileField
                          title="Name"
                          content={formData.fullName}
                        />
                      ) : (
                        <ProfileInputField
                          title="Name"
                          type="text"
                          name="fullName"
                          value={editedData.fullName}
                          placeholder="Name"
                          onChange={handleChange}
                        />
                      )}
                      <ProfileField
                        title="Username"
                        content={userDetails.userName}
                      />
                      <ProfileField
                        title="Account Name"
                        content={userDetails.accountName}
                      />
                      <ProfileField
                        title="Role"
                        content={userDetails.title}
                      />
                    </div>
                  </div>
                  <div className="md:grid grid-cols-1 gap-8 w-4/5">
                    <div className="static">
                      {!isEditable ? (
                        <ProfileField
                          title="Phone"
                          content={formData.phoneNumber}
                        />
                      ) : (
                        <ProfileInputField
                          title="Phone"
                          type="text"
                          name="phoneNumber"
                          value={editedData.phoneNumber}
                          placeholder="User phone"
                          onChange={handleChange}
                        />
                      )}
                      <ProfileField title="Email" content={userDetails.email} />
                      <ProfileField
                        title="Account ID"
                        content={userDetails.accountID}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {isEditable && (
                <button
                  className="rounded-md px-4 py-2 text-sm absolute bottom-6 right-6 flex justify-center items-center"
                  onClick={handleSubmitUpdate}
                >
                  {isLoadingUpdateUser ? (
                    <Loader classNames="w-5 h-5" />
                  ) : (
                    'Update user profile'
                  )}
                </button>
              )}
            </div>
          </>
        )}
      </section>
    </main>
  );
}
