import React, { useState } from 'react';

// Assets
import { FiEdit } from 'react-icons/fi';
import { TiUserDeleteOutline } from 'react-icons/ti';
import accessControl from '../../helpers/accessControl';

function UserRow(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <tr
      className="border-b"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td
        className="pl-4 md:pl-8 cursor-pointer flex items-center ali gap-4 h-12"
        onClick={() => {
          props.assignActiveUser(props.userName);
        }}
      >
        {accessControl.isAdministrator() && (
          <div>
            <FiEdit
              className={`inline-flex self-center mr-3 ${
                isHovered ? null : 'md:invisible'
              }`}
              size={20}
              onClick={() => {
                props.toggleEditModal();
              }}
            />
            <TiUserDeleteOutline
              className={`inline-flex self-center ${
                isHovered ? null : 'md:invisible'
              }`}
              size={20}
              onClick={() => {
                props.toggleDeactivateModal();
              }}
            />
          </div>
        )}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveUser(props.userName);
          props.toggleModal();
        }}
      >
        {props.accountName}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveUser(props.userName);
          props.toggleModal();
        }}
      >
        {props.userName}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveUser(props.userName);
          props.toggleModal();
        }}
      >
        {props.fullName}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveUser(props.userName);
          props.toggleModal();
        }}
      >
        {props.userSubtype}
      </td>
    </tr>
  );
}

export default UserRow;
