import React, { useState, useEffect } from 'react';

// Components
import UserContainer from '../components/containers/UserContainer';
import api from '../api/axios.config';
import Loader from '../components/Loader';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

function Users() {
  const [isUsersDataLoading, setIsUserDataLoading] = useState(true);
  const [isAccountsDataLoading, setIsAccountsDataLoading] = useState(true);

  const [users, setUsers] = useState(null);
  const [accounts, setAccounts] = useState(null);

  // Fetch users data
  const fetchUsersData = () => {
    api.get(
      `/users`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setUsers(res?.data?.users?.users);
        setIsUserDataLoading(false);
      })
      .catch(() => {
        setIsUserDataLoading(false);
        Notify.warning('Users data are not loaded. Please try later.', notifyOptions);
      });
  }

  // Fetch accounts data
  const fetchAccountsData = () => {
    api.get(
      `/accounts`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setAccounts(res?.data?.account?.accountList);
        setIsAccountsDataLoading(false);
      })
      .catch(() => {
        setIsAccountsDataLoading(false);
        Notify.warning('Accounts data are not loaded. Please try later.', notifyOptions);
      });
  }

  useEffect(() => {
    fetchUsersData();
    fetchAccountsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchData = () => {
    fetchUsersData();
    fetchAccountsData();
  }

  if (isUsersDataLoading || isAccountsDataLoading)
    return (
      <div className="loader-global-container">
        <Loader classNames="w-32 h-32" />
      </div>
    );

  if (!users || !accounts)
    return (
      <h1>Error fetching users data. Please try later</h1>
    );

  return (
    <UserContainer
      users={users}
      accounts={accounts}
      refetchData={refetchData}
    />
  );
}

export default Users;
