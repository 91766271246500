// React
import React from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { assetRealTimeDataSelectors } from '../../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import assetRealTimeDataOperations from '../../../redux/assetRealTimeData/assetRealTimeDataOperations';

// Components
import Loader from '../../Loader';

export default function DeleteDischargingEvent(
  {
    toggleModal,
    activeDischargingEventID,
    dateFilterEvents,
    dischargeStartDateTime,
    dischargeEndDateTime,
    assetName
  }
) {
  const isLoadingDeleteDischargingEvent = useSelector(
    assetRealTimeDataSelectors.getDeleteDischargingEventLoading
  );

  // dispatch
  const dispatch = useDispatch();

  // Handlers
  const handleDeleteDischargingEventConfirm = () => {
    const bodyRequest = {
      eventId: activeDischargingEventID,
      remarks: "deleted",
      assetName
    }

    dispatch(
      assetRealTimeDataOperations.deleteDischargingEvent({
        toggleModal,
        bodyRequest
      })
    );

    const performDateFiltering = () => {
      dateFilterEvents('dischargingEvents', dischargeStartDateTime, dischargeEndDateTime);
    };

    setTimeout(performDateFiltering, 1000);
  };

  const handleDeleteDischargingEventReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to delete discharging event?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeleteDischargingEventConfirm}
        >
          {isLoadingDeleteDischargingEvent ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeleteDischargingEventReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
