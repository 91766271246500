import React from 'react';

// Assets
import { FiPlusCircle, FiEdit } from 'react-icons/fi';
import { IconContext } from 'react-icons';

const HomeTopBar = props => {
  return (
    <div className="w-full px-2 md:px-0 flex justify-end mt-4 mb-2 md:mt-2 md:mb-2">
      <button className="mr-6" onClick={props.toggleShowRemove}>
        <IconContext.Provider value={{ color: '#0F3052' }}>
          <FiEdit size={28} />
        </IconContext.Provider>
      </button>
      <button onClick={props.toggleModal}>
        <IconContext.Provider value={{ color: '#0F3052' }}>
          <FiPlusCircle size={28} />
        </IconContext.Provider>
      </button>
    </div>
  );
};

export default HomeTopBar;
