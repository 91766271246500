import { useEffect, useState } from 'react';

const CalculateFleetStatus = assets => {
  const initStatuses = {
    warnings: {
      healthy: 0,
      unhealthy: 0,
      down: 0,
    },
    state: {
      charging: 0,
      discharging: 0,
      idle: 0,
    },
    connection: {
      offline: 0,
      online: 0,
    },
  };

  const [statuses, setStatuses] = useState(initStatuses);

  let newStatuses = statuses;

  assets.forEach(item => {
    if (item.errorState === 'ok') {
      newStatuses.warnings.healthy++;
    } else if (item.errorState === 'warning') {
      newStatuses.warnings.unhealthy++;
    } else {
      newStatuses.warnings.down++;
    }

    if (item.chargingState === 'Charging') {
      newStatuses.state.charging++;
    } else if (item.chargingState === 'Discharging') {
      newStatuses.state.discharging++;
    } else {
      newStatuses.state.idle++;
    }

    if (item.connection === 'Online') {
      newStatuses.connection.online++;
    } else {
      newStatuses.connection.offline++;
    }
  });

  useEffect(() => {
    setStatuses(newStatuses);

    // NOTE: check
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses]);

  return statuses;
};

export default CalculateFleetStatus;
