import React, { useState } from 'react';

// Redux
import { useSelector } from 'react-redux';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';

// Access
import accessControl from '../../helpers/accessControl';

// Assets
import { FaTrash } from 'react-icons/fa';
import formatDate from '../../helpers/formatDate/formatDateForTables';
import formatTime from '../../helpers/formatDate/formatTimeForTables';

const DischargingEvents = (
  {
    setActiveDischargingEventID,
    toggleDeleteDischargingEventModal,
    userDetails,
    assetDifferenceFromUtc,
    toggleOptOutEventModal,
  }
) => {
  const filteredDischargingEvents = useSelector(assetRealTimeDataSelectors.getFilteredDischargingEvents);

  const [hoveredItemId, setHoveredItemId] = useState(false);

  const handleOptOut = (eventId) => {
    setActiveDischargingEventID(eventId);
    toggleOptOutEventModal();
  }

  const convertToAssetTime = (datetime) => {
    const eventDate = new Date(datetime);
    eventDate.setMinutes(eventDate.getMinutes() + assetDifferenceFromUtc + eventDate.getTimezoneOffset());

    const date = formatDate(eventDate);
    const time = formatTime(eventDate);
    return { date, time };
  };

  return (
    <div className="border rounded-lg grow pb-2 px-2 mx-3 md:px-4 md:mx-8 overflow-auto text-sm md:text-base relative w-auto">
      <table className="table-auto w-full ">
        <thead className="table-head">
          <tr>
            <th className="table-head-item"></th>
            <th className="table-head-item" style={{ paddingRight: '46px' }}>Mode</th>
            <th className="table-head-item">Date Start</th>
            <th className="table-head-item">Date End</th>
            <th className="table-head-item">Time Start</th>
            <th className="table-head-item">Time End</th>
            <th className="table-head-item">Power Level (kW)</th>
            <th className="table-head-item">Created By</th>
            <th className="table-head-item">State</th>
            <th className="table-head-item">Opt Out</th>
          </tr>
        </thead>
        <tbody>
          {filteredDischargingEvents?.map(item => {
            const startDate = convertToAssetTime(item.start_datetime);
            const endDate = convertToAssetTime(item.end_datetime);

            return (
              <tr
                key={item.event_id}
                className="border-b"
                onMouseEnter={() => setHoveredItemId(item.event_id)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                {
                  (
                    !accessControl.isAdministrator() ||
                    (item.mode === 'Passive DR' && userDetails.accountType !== 'Cadenza Account') ||
                    item.mode === 'Active DR'
                  ) ? (
                    <td>
                      <FaTrash
                        className="inline-flex self-center md:invisible"
                        size={20}
                      />
                    </td>
                  ) : (
                    <td
                      className="pr-2 py-2 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setActiveDischargingEventID(item.event_id);
                        toggleDeleteDischargingEventModal();
                      }}
                    >
                      <FaTrash
                        className={`inline-flex self-center ${hoveredItemId !== item.event_id && 'md:invisible'}`}
                        size={20}
                      />
                    </td>
                  )
                }
                <td className="pr-2 py-2 ">{item.mode}</td>
                <td className="pr-2 py-2">{startDate.date}</td>
                <td className="pr-2 py-2">{endDate.date}</td>
                <td className="pr-2 py-2">{startDate.time}</td>
                <td className="pr-2 py-2">{endDate.time}</td>
                <td className="pr-2 py-2 ">{item.power_level}</td>
                <td className="pr-2 py-2 ">{item.created_by}</td>
                <td className="pr-2 py-2 ">{item.state}</td>
                <td className="pr-2 py-2">
                  {
                    (
                      item.optout_able === 1 &&
                      item.state !== 'Opted Out' &&
                      item.state !== 'cancelled' &&
                      accessControl.isAdministrator()
                    ) && (
                      <button
                        style={{ minWidth: '1vw', minHeight: '0.5rem', }}
                        className='rounded-md px-2 py-1 text-xs'
                        onClick={() => handleOptOut(item.event_id)}
                      >
                        Opt Out
                      </button>
                    )
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DischargingEvents;
