// React
import React, { useState } from 'react';

import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// Components
import Loader from '../../Loader';

// Get user credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export default function OptOutDischargingEvent(
  {
    toggleModal,
    activeDischargingEventID,
    dateFilterEvents,
    dischargeStartDateTime,
    dischargeEndDateTime,
    assetName,
  }
) {
  const [isLoadingOptOutDischargingEvent, setIsLoadingOptOutDischargingEvent] = useState(false);

  // Handlers
  const handleOptOutDischargingEventConfirm = async () => {
    try {
      setIsLoadingOptOutDischargingEvent(true);

      const { data } = await api.post('/device/discharging-events/opt-out', {
        eventID: activeDischargingEventID,
        AssetName: assetName,
        optOut: true
      }, {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.status === 'success') {
        Notify.success(
          `The Opt Out was updated successfully`,
          notifyOptions,
        );

        setIsLoadingOptOutDischargingEvent(false);

        const performDateFiltering = () => {
          dateFilterEvents('dischargingEvents', dischargeStartDateTime, dischargeEndDateTime);
        };

        setTimeout(performDateFiltering, 1000);

        toggleModal();
      }
    } catch (err) {
      setIsLoadingOptOutDischargingEvent(false);
      Notify.failure(
        `There was an error updating the Opt Out`,
        notifyOptions,
      );
    };
  };

  const handleOptOutDischargingEventReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Opting out of an event will lower your demand response payments.`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleOptOutDischargingEventConfirm}
        >
          {isLoadingOptOutDischargingEvent ? <Loader classNames="w-5 h-5" /> : 'Confirm'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleOptOutDischargingEventReject}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
