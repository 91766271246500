import * as Yup from 'yup';

const BatteryModuleFormSchema = (status) => {
  const batteryModuleSchema = {
    replacementInstallationDate: Yup.string().required('Replacement Installation Date is required'),
    serialNumber: Yup.string().required('Serial Number is required'),
    modelNumber: Yup.string().required('Model Number is required'),
    softwareVersion: Yup.string().required('Software Version is required'),
    manufacturer: Yup.string().required('Manufacturer is required'),
  };

  if (status === 'edit') {
    batteryModuleSchema.replaceModule = Yup.string().required('Is it replace module?');
  }

  return Yup.object().shape(batteryModuleSchema);
}

export default BatteryModuleFormSchema;
