const formatRangeForFileName = (startDate, endDate, assetDifferenceFromUtc) => {
  if (startDate && endDate) {
    // To UTC time to get correct day

    const assetStartDate = new Date(startDate.getTime() - (- startDate.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    const assetEndDate = new Date(endDate.getTime() - (- endDate.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

    // Format the start date
    const startDay = assetStartDate.getDate().toString().padStart(2, '0');
    const startMonth = (assetStartDate.getMonth() + 1).toString().padStart(2, '0');
    const startYear = assetStartDate.getFullYear();

    // Format the end date
    const endDay = assetEndDate.getDate().toString().padStart(2, '0');
    const endMonth = (assetEndDate.getMonth() + 1).toString().padStart(2, '0');
    const endYear = assetEndDate.getFullYear();

    // Create the formatted date strings
    const formattedStartDate = `${startMonth}.${startDay}.${startYear}`;
    const formattedEndDate = `${endMonth}.${endDay}.${endYear}`;

    // Combine into the desired format
    const formattedDateRange = `${formattedStartDate} to ${formattedEndDate}`;

    return formattedDateRange;
  } else {
    return null;
  }
};

export default formatRangeForFileName
