import React from 'react';

export default function AssetDetailsInputField({
  title,
  access,
  inputValue,
  inputClassName,
  titleClassName,
  ...props
}) {
  return (
    <label>
      <span
        className={
          titleClassName || 'asset-title-data block my-2 mt-4 mb-2'
        }
      >
        {title}
      </span>
      <input
        className={inputClassName || 'rounded-md w-full py-1 px-2'}
        value={inputValue}
        disabled={!access}
        {...props}
      />
    </label>
  );
}
