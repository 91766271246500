// React
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import dashboardDataOperations from '../../redux/dashboardData/dashboardDataOperation';
import assetRealTimeDataOperations from '../../redux/assetRealTimeData/assetRealTimeDataOperations';
import { dashboardDataSelectors } from '../../redux/dashboardData/dashboardDataSelectors';
import { assetRealTimeDataSelectors } from '../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import userOperations from '../../redux/user/userOperations';
import { userSelectors } from '../../redux/user/userSelectors';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Helpers
import { idToText } from '../../helpers/widget/idToText';
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';
import getTimezoneFromCoordinates from '../../helpers/formatDate/getTimezoneFromCoordinates';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { validateAndConvertDatesToUTC } from '../../helpers/formatDate/validateAndConvertDatesToUTC';

// Components
import { ReactComponent as Battery } from '../../assets/battery_shape.svg';
import { ReactComponent as Thermometer } from '../../assets/thermometer_shape.svg';
import { ReactComponent as ThermometerSun } from '../../assets/thermometer_ambient_shape.svg';
import { ReactComponent as InverterOperation } from '../../assets/inverter_operation.svg';
import { ReactComponent as LoadPower } from '../../assets/battery-plug.svg';
import { ReactComponent as TargetPower } from '../../assets/charging-target.svg';
import { ReactComponent as BatteryThroughput } from '../../assets/charging-time.svg';
import ChargingEvents from '../widgets/ChargingEvents';
import DischargingEvents from '../widgets/DischargingEvents';
import GridPower from '../widgets/GridPower';
import VoltageCurrent from '../widgets/VoltageCurrent';
import PowerSoc from '../widgets/PowerSoc';
import MaxPower from '../widgets/MaxPower';
import DeleteChargingEvent from './chargingEvents/DeleteChargingEvent';
import DeleteDischargingEvent from './dischargingEvents/DeleteDischargingEvent';
import OptOutDischargingEvent from './dischargingEvents/OptOutDischargingEvent';
import Modal from '../modals/Modal';
import Loader from '../Loader';
import CreateDischargeEvent from './dischargingEvents/CreateDischargeEvent';
import CreateChargeEvent from './chargingEvents/CreateChargeEvent';

// Widget Components
import Widget from '../widgets/Widget';
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';
import api from '../../api/axios.config';

// Assets
import { FiX, FiChevronDown } from 'react-icons/fi';
import { PiFileCsv } from "react-icons/pi";
import { IconContext } from 'react-icons';
import { CSVLink } from "react-csv";

// Calendar
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

// Icons
import { BsCalendarEvent } from 'react-icons/bs';

const AssetRealTimeData = ({
  assetData: assetDetailsData = {},
  toggleAssetDetails,
  toggleAssetUpsDetails,
}) => {
  const modalRef = useRef();

  const { name: assetName = '' } = assetDetailsData;

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(
      dashboardDataOperations.getCurrentAsset({ assetName })
    );
  }, [dispatch, assetName]);

  const { assetDetails } = useSelector(
    dashboardDataSelectors.getCurrentAsset,
  );

  const currentAssetLoading = useSelector(
    dashboardDataSelectors.getCurrentAssetLoading,
  );

  // Fetch layout data
  const fetchLayoutData = (isDps) => {
    api.get(
      `/device/asset-page/${isDps ? 'layout-dps' : 'layout'}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setLayout(res?.data)
      })
      .catch(() => {
        Notify.warning('Layout with dps data are not loaded. Please try later.', notifyOptions);
      });
  }

  useEffect(() => {
    fetchLayoutData(assetDetailsData.enableDPS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const userDetails = useSelector(userSelectors.getUserDetailsData);

  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);

  // Get credentials
  const username = localStorage.getItem('cdnzUser');
  const token = localStorage.getItem('cdnzAccessToken');

  // Asset difference from UTC in minutes and asset timezone name
  const assetDifferenceFromUtc = getUtcOffsetDifference(assetDetailsData?.gpsCoordinates?.latitude, assetDetailsData?.gpsCoordinates?.longitude);
  const assetTimezone = getTimezoneFromCoordinates(assetDetailsData?.gpsCoordinates?.latitude, assetDetailsData?.gpsCoordinates?.longitude);

  const now = new Date();
  const nowAssetTime = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
  const assetTimeEndOfToday = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000).setHours(23, 59, 59, 999);

  const [layout, setLayout] = useState(null);

  const [isGridPowerLoading, setIsGridPowerLoading] = useState(true);
  const [isBatteryVoltageCurrentLoading, setIsBatteryVoltageCurrentLoading] = useState(true);
  const [isPowerSocLoading, setIsPowerSocLoading] = useState(true);
  const [isMaxPowerLoading, setIsMaxPowerLoading] = useState(true);
  const [isAssetHistoricalDataLoading, setIsAssetHistoricalDataLoading] = useState(false);
  const getChargingEventsLoading = useSelector(assetRealTimeDataSelectors.getChargingEventsLoading);
  const getDischargingEventsLoading = useSelector(assetRealTimeDataSelectors.getDischargingEventsLoading);

  const [showAddChargeEvent, setShowAddChargeEvent] = useState(false);
  const [showAddDischargeEvent, setShowAddDischargeEvent] = useState(false);
  const [showDeleteChargingEventModal, setDeleteChargingEventModal] = useState(false);
  const [showDeleteDischargingEventModal, setDeleteDischargingEventModal] = useState(false);
  const [showOptOutEventModal, setShowOptOutEventModal] = useState(false);
  const [isShowExportAssetData, setIsShowExportAssetData] = useState(false);

  const [activeChargingEventID, setActiveChargingEventID] = useState(null);
  const [activeDischargingEventID, setActiveDischargingEventID] = useState(null);

  const [chargeStartDateTime, setChargeStartDateTime] = useState('');
  const [chargeEndDateTime, setChargeEndDateTime] = useState('');
  const [dischargeStartDateTime, setDischargeStartDateTime] = useState('');
  const [dischargeEndDateTime, setDischargeEndDateTime] = useState('');
  const [powerStartDate, setPowerStartDate] = useState('');
  const [powerEndDate, setPowerEndDate] = useState('');
  const [batteryStartDate, setBatteryStartDate] = useState('');
  const [batteryEndDate, setBatteryEndDate] = useState('');
  const [powerSocStartDate, setPowerSocStartDate] = useState('');
  const [powerSocEndDate, setPowerSocEndDate] = useState('');
  const [powerAssetStartDate, setPowerAssetStartDate] = useState('');
  const [powerAssetEndDate, setPowerAssetEndDate] = useState('');
  const [batteryAssetStartDate, setBatteryAssetStartDate] = useState('');
  const [batteryAssetEndDate, setBatteryAssetEndDate] = useState('');
  const [powerSocAssetStartDate, setPowerSocAssetStartDate] = useState('');
  const [powerSocAssetEndDate, setPowerSocAssetEndDate] = useState('');
  const [currentYear, setCurrentYear] = useState(nowAssetTime.getFullYear());
  const [assetDataStartDateTime, setAssetDataStartDateTime] = useState('');
  const [assetDataEndDateTime, setAssetDataEndDateTime] = useState('');

  const [gridPower, setGridPower] = useState([]);
  const [batteryVoltageCurrent, setBatteryVoltageCurrent] = useState([]);
  const [powerSoc, setPowerSoc] = useState([]);
  const [maxPower, setMaxPower] = useState([]);
  const [assetData, setAssetData] = useState([]);

  // State to store scroll position
  const [scrollPosition, setScrollPosition] = useState(0);

  // Function to handle scroll position change
  const handleScroll = () => {
    const position = modalRef.current.scrollTop;
    setScrollPosition(position);
  };

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      modalRef.current?.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scrollToPreviousPosition,
  ]);

  // Width handling
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Toggle Create Charge Event Modal
  const toggleCreateChargeEvent = () => {
    setScrollPosition(0);
    setShowAddChargeEvent(!showAddChargeEvent);
  };

  // Toggle Create Discharge Event Modal
  const toggleCreateDischargeEvent = () => {
    setScrollPosition(0);
    setShowAddDischargeEvent(!showAddDischargeEvent);
  };

  // Toggle delete charging event modal
  const toggleDeleteChargingEventModal = () => {
    setScrollPosition(0);
    setDeleteChargingEventModal(!showDeleteChargingEventModal);
  };

  // Toggle delete discharging event modal
  const toggleDeleteDischargingEventModal = () => {
    setScrollPosition(0);
    setDeleteDischargingEventModal(!showDeleteDischargingEventModal);
  };

  // Toggle opt out event modal
  const toggleOptOutEventModal = () => {
    setScrollPosition(0);
    setShowOptOutEventModal(!showOptOutEventModal);
  };

  // Toggle show export asset data
  const toggleShowExportAssetData = () => {
    setScrollPosition(0);
    setIsShowExportAssetData(!isShowExportAssetData);
  };

  useEffect(() => {
    fetchInitialChartsData();
    fetchInitialTablesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get user details
  useEffect(() => {
    dispatch(userOperations.getUserDetails());
  }, [dispatch]);

  // Fetch initial charts data
  const fetchInitialChartsData = () => {
    setIsGridPowerLoading(true);
    setIsBatteryVoltageCurrentLoading(true);

    // Now user time
    const nowUserTime = new Date();

    // Start and end of day for asset time
    const startOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    endOfDay.setHours(23, 59, 59, 999);

    // difference between daylight savings time and standart time
    const dstOffsetDifference = endOfDay.getTimezoneOffset() !== startOfDay.getTimezoneOffset()
      ? (endOfDay.getTimezoneOffset() - startOfDay.getTimezoneOffset())
      : 0;

    // Apply the asset's time difference from UTC
    const startAssetTime = new Date(startOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc) * 60000);
    const endAssetTime = new Date(endOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc + dstOffsetDifference) * 60000);

    // Format the start and end dates as ISO 8601 strings
    const startToUTC = startAssetTime.toISOString();
    const endToUTC = endAssetTime.toISOString();

    // Fetch data for BESS Grid Power
    api.post(
      `/device/asset-data/${assetName}/historical`,
      {
        startDate: startToUTC,
        endDate: endToUTC,
        propName: 'gridPower',
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setGridPower(res.data.assetHistoricalData.gridPower);
        setPowerStartDate(startOfDay);
        setPowerEndDate(endOfDay);
        setPowerAssetStartDate(startAssetTime);
        setPowerAssetEndDate(endAssetTime);
        setIsGridPowerLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch grid power data', notifyOptions)
        setPowerStartDate(startOfDay);
        setPowerEndDate(endOfDay);
        setPowerAssetStartDate(startAssetTime);
        setPowerAssetEndDate(endAssetTime);
        setIsGridPowerLoading(false);
      });

    // Fetch data for Battery Voltage/Current
    api.post(
      `/device/asset-data/${assetName}/historical`,
      {
        startDate: startToUTC,
        endDate: endToUTC,
        propName: 'batteryVoltage',
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )
      .then(res => {
        setBatteryVoltageCurrent(res.data.assetHistoricalData.batteryVoltage);
        setBatteryStartDate(startOfDay);
        setBatteryEndDate(endOfDay);
        setBatteryAssetStartDate(startAssetTime);
        setBatteryAssetEndDate(endAssetTime);
        setIsBatteryVoltageCurrentLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch battery voltage/current data', notifyOptions)
        setBatteryStartDate(startOfDay);
        setBatteryEndDate(endOfDay);
        setBatteryAssetStartDate(startAssetTime);
        setBatteryAssetEndDate(endAssetTime);
        setIsBatteryVoltageCurrentLoading(false);
      });

    if (assetDetailsData.enableDPS) {
      // Fetch data for Power SOC
      setIsMaxPowerLoading(true);
      api.post(
        `/device/dps-performance`,
        {
          startDate: startToUTC,
          endDate: endToUTC,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
        .then(res => {
          setPowerSoc(res.data.dpsPerformanceData);
          setPowerSocStartDate(startOfDay);
          setPowerSocEndDate(endOfDay);
          setPowerSocAssetStartDate(startAssetTime);
          setPowerSocAssetEndDate(endAssetTime);
          setIsPowerSocLoading(false);
        })
        .catch(() => {
          Notify.warning('Failed to fetch dps performance data', notifyOptions)
          setPowerSocStartDate(startOfDay);
          setPowerSocEndDate(endOfDay);
          setPowerSocAssetStartDate(startAssetTime);
          setPowerSocAssetEndDate(endAssetTime);
          setIsPowerSocLoading(false);
        });

      // Fetch data for Max Power
      api.post(
        `/device/max-power`,
        {
          year: currentYear,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
        .then(res => {
          setMaxPower(res.data.maxPowerData);
          setIsMaxPowerLoading(false);
        })
        .catch(() => {
          Notify.warning('Failed to fetch max power data', notifyOptions)
          setIsMaxPowerLoading(false);
        });
    }
  };

  // Fetch initial tables data
  const fetchInitialTablesData = () => {
    // Now user time
    const nowUserTime = new Date();

    // Start and end of day for asset time
    const startOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(nowUserTime.getTime() - (- nowUserTime.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    endOfDay.setHours(23, 59, 59, 999);

    // Calculate the end of the next 7 days for asset time
    const endOfNext7Days = new Date(endOfDay.getTime());
    endOfNext7Days.setDate(endOfNext7Days.getDate() + 7);
    endOfNext7Days.setHours(23, 59, 59, 999);

    // difference between daylight savings time and standart time
    const dstOffsetDifference = endOfNext7Days.getTimezoneOffset() !== startOfDay.getTimezoneOffset()
      ? (endOfNext7Days.getTimezoneOffset() - startOfDay.getTimezoneOffset())
      : 0;

    // Apply the asset's time difference from UTC
    const startAssetTimeInUTC = new Date(startOfDay.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc) * 60000);
    const endAssetTimeInUTC = new Date(endOfNext7Days.getTime() - (nowUserTime.getTimezoneOffset() + assetDifferenceFromUtc + dstOffsetDifference) * 60000);

    // Format the start and end dates as ISO 8601 strings
    const startDate = startAssetTimeInUTC.toISOString();
    const endDate = endAssetTimeInUTC.toISOString();

    const bodyRequest = {
      startDatetime: startDate,
      endDatetime: endDate,
      assetName: assetName
    }

    // Fetch data for ChargingEvents
    dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
    setChargeStartDateTime(startOfDay)
    setChargeEndDateTime(endOfNext7Days)

    // Fetch data for DischargingEvents
    dispatch(assetRealTimeDataOperations.getDischargingEvents({ bodyRequest }));
    setDischargeStartDateTime(startOfDay)
    setDischargeEndDateTime(endOfNext7Days)
  };

  // Date filter functionality for charts
  const dateFilter = (element, startDateParam, endDateParam) => {
    if (!element) return;

    let startDate = startDateParam instanceof Date ? startDateParam : new Date(startDateParam);
    let endDate = endDateParam instanceof Date ? endDateParam : new Date(endDateParam);

    if (!startDate || !endDate) return;

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error("Invalid Date objects");
      return;
    }

    // Extract day, month, and year from selected date
    const localStartDay = startDate.getDate();
    const localStartMonth = startDate.getMonth();
    const localStartYear = startDate.getFullYear();

    const localEndDay = endDate.getDate();
    const localEndMonth = endDate.getMonth();
    const localEndYear = endDate.getFullYear();

    // Create new date objects using the extracted components
    const startOfDayUTC = new Date(Date.UTC(localStartYear, localStartMonth, localStartDay, 0, 0, 0, 0));
    const endOfDayUTC = new Date(Date.UTC(localEndYear, localEndMonth, localEndDay, 23, 59, 59, 999));

    // Adjust for the asset's timezone offset
    startOfDayUTC.setMinutes(startOfDayUTC.getMinutes() - assetDifferenceFromUtc);
    endOfDayUTC.setMinutes(endOfDayUTC.getMinutes() - assetDifferenceFromUtc);

    // difference between daylight savings time and standart time
    const dstOffsetDifference = endOfDayUTC.getTimezoneOffset() !== startOfDayUTC.getTimezoneOffset()
      ? (endOfDayUTC.getTimezoneOffset() - startOfDayUTC.getTimezoneOffset())
      : 0;
    // Adjust endOfDayUTC with dstOffsetDifference
    endOfDayUTC.setMinutes(endOfDayUTC.getMinutes() - dstOffsetDifference);

    // Format the start and end dates as ISO 8601 strings
    const startToUTC = startOfDayUTC.toISOString();
    const endToUTC = endOfDayUTC.toISOString();

    handleScroll();

    if (element === 'gridPower') {
      setIsGridPowerLoading(true);
    }
    if (element === 'batteryVoltage') {
      setIsBatteryVoltageCurrentLoading(true);
    }

    if (element === 'gridPower' || element === 'batteryVoltage') {
      api
        .post(
          `/device/asset-data/${assetName}/historical`,
          {
            startDate: startToUTC,
            endDate: endToUTC,
            propName: element,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              username: username,
            },
          },
        )
        .then(res => res.data)
        .then(data => {
          if (element === 'gridPower') {
            setGridPower(data.assetHistoricalData.gridPower);
            setPowerStartDate(startDateParam);
            setPowerEndDate(endDateParam);
            setPowerAssetStartDate(startOfDayUTC);
            setPowerAssetEndDate(endOfDayUTC);
            setIsGridPowerLoading(false);
          }
          if (element === 'batteryVoltage') {
            setBatteryVoltageCurrent(data.assetHistoricalData.batteryVoltage);
            setBatteryStartDate(startDateParam);
            setBatteryEndDate(endDateParam);
            setBatteryAssetStartDate(startOfDayUTC);
            setBatteryAssetEndDate(endOfDayUTC);
            setIsBatteryVoltageCurrentLoading(false);
          }
        })
        .catch(() => {
          if (element === 'gridPower') {
            Notify.warning('Failed to fetch grid power data', notifyOptions);
            setPowerStartDate(startDateParam);
            setPowerEndDate(endDateParam);
            setPowerAssetStartDate(startOfDayUTC);
            setPowerAssetEndDate(endOfDayUTC);
            setIsGridPowerLoading(false);
          }
          if (element === 'batteryVoltage') {
            Notify.warning('Failed to fetch battery voltage/current data', notifyOptions);
            setBatteryStartDate(startDateParam);
            setBatteryEndDate(endDateParam);
            setBatteryAssetStartDate(startOfDayUTC);
            setBatteryAssetEndDate(endOfDayUTC);
            setIsBatteryVoltageCurrentLoading(false);
          }
        });
    }

    if (element === 'peakShaving') {
      setIsPowerSocLoading(true);
      api.post(
        `/device/dps-performance`,
        {
          startDate: startToUTC,
          endDate: endToUTC,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
        .then(res => {
          setPowerSoc(res.data.dpsPerformanceData);
          setPowerSocStartDate(startDateParam);
          setPowerSocEndDate(endDateParam);
          setPowerSocAssetStartDate(startOfDayUTC);
          setPowerSocAssetEndDate(endOfDayUTC);
          setIsPowerSocLoading(false);
        })
        .catch(() => {
          Notify.warning(
            'Failed to fetch dps performance data. Please try later',
            notifyOptions,
          );
          setPowerSocStartDate(startDateParam);
          setPowerSocEndDate(endDateParam);
          setPowerSocAssetStartDate(startOfDayUTC);
          setPowerSocAssetEndDate(endOfDayUTC);
          setIsPowerSocLoading(false);
        });
    }
  };

  // Date filter functionality for tables
  const dateFilterEvents = (element, startDateTimeParam, endDateTimeParam) => {
    if (!element) return;

    const validatedDates = validateAndConvertDatesToUTC(startDateTimeParam, endDateTimeParam, assetDifferenceFromUtc);

    if (!validatedDates) {
      console.error("Invalid Date objects");
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    handleScroll();

    const bodyRequest = {
      startDatetime: formattedStartDateUTC,
      endDatetime: formattedEndDateUTC,
      assetName: assetName
    }

    if (element === 'chargingEvents') {
      dispatch(assetRealTimeDataOperations.getChargingEvents({ bodyRequest }));
      setChargeStartDateTime(startDateTimeParam)
      setChargeEndDateTime(endDateTimeParam)
    }

    if (element === 'dischargingEvents') {
      dispatch(assetRealTimeDataOperations.getDischargingEvents({ bodyRequest }));
      setDischargeStartDateTime(startDateTimeParam)
      setDischargeEndDateTime(endDateTimeParam)
    }
  };

  // Year filter functionality for max power chart
  const yearFilter = (element, year) => {
    if (!element || !year) return;

    handleScroll();

    setIsMaxPowerLoading(true);
    // Fetch data for Max Power
    api.post(
      `/device/max-power`,
      {
        year,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )
      .then(res => {
        setMaxPower(res.data.maxPowerData);
        setCurrentYear(year);
        setIsMaxPowerLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch max power data', notifyOptions)
        setIsMaxPowerLoading(false);
      });
  };

  // Date filter functionality for asset historical data
  const getHistoricalData = () => {
    const validatedDates = validateAndConvertDatesToUTC(assetDataStartDateTime, assetDataEndDateTime, assetDifferenceFromUtc);

    if (!validatedDates) {
      return;
    }

    const { formattedStartDateUTC, formattedEndDateUTC } = validatedDates;

    setIsAssetHistoricalDataLoading(true);

    // Fetch asset historical data
    api.post(
      `/device/asset-data/historical`,
      {
        startDate: formattedStartDateUTC,
        endDate: formattedEndDateUTC,
        assetName,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )
      .then(res => {
        setAssetData(res.data.historicalData);
        setIsAssetHistoricalDataLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch asset historical data', notifyOptions);
        setIsAssetHistoricalDataLoading(false);
      });
  };

  const rangeForFileName = formatRangeForFileNameLocalTime(new Date(assetDataStartDateTime), new Date(assetDataEndDateTime));

  const transformedData = transformDataForCSV(assetData, assetDifferenceFromUtc,
    ['InverterACLoadPower', 'SOC', 'InverterBatteryLifetime', 'InverterOperationStatus', 'InverterChargerStatus',
      'BMSCellMaxTemperatureC', 'BMSCellMaxTemperatureF', 'InverterAmbientTemperatureC', 'InverterAmbientTemperatureF']);

  const handleStartDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataStartDateTime(date.toString());
  };

  const handleEndDateTimeChange = ([date]) => {
    setScrollPosition(0);
    setAssetDataEndDateTime(date.toString());
  };

  const handleCalendarClose = () => {
    getHistoricalData();
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(layouts, layout,
        `${assetDetailsData.enableDPS
          ? 'assetDataWithDpsLayout'
          : 'assetDataWithoutDpsLayout'}`);
    }
  };

  const life = `${assetDetails?.batterySOC}` || 'N/A';
  const tempC = Number(assetDetails?.batteryTemperatureC).toFixed(0) || 'N/A';
  const tempF = Number(assetDetails?.batteryTemperatureF).toFixed(0) || 'N/A';
  const tempBar = tempC >= 50 ? 100 : Math.round(tempC * 2 + 15) || 'N/A';

  const aTempC = Number(assetDetails?.ambientTemperatureC).toFixed(0) || 'N/A';
  const aTempF = Number(assetDetails?.ambientTemperatureF).toFixed(0) || 'N/A';
  const aTempBar = aTempC >= 50 ? 100 : Math.round(aTempC * 2 + 15) || 'N/A';

  const loadPower = assetDetails?.loadPower || 'N/A';
  const powerGoal = assetDetails?.pGoal !== undefined ? assetDetails.pGoal : 'N/A';
  const batteryThroughput = assetDetails?.lifeTimeEnergyFromBattery || 'N/A';
  const inverterStatus = assetDetails?.operationMode || 'N/A';
  const chargerStatus = assetDetails?.chargerStatus || 'N/A';

  return (
    <div
      className='modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer'>
      <div
        ref={modalRef}
        className={`container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto 
        ${(showAddChargeEvent || showAddDischargeEvent || showDeleteChargingEventModal || showDeleteDischargingEventModal || showOptOutEventModal) ? 'overflow-y-hidden' : ''}`}>
        <header className='py-7 sticky top-0 bg-white z-50 drop-shadow'>
          <span
            className='absolute top-6 right-4'
            type='button'
            role='button'
            onClick={() => toggleAssetDetails()}
          >
            <FiX size={28} />
          </span>
          <h1 className='text-2xl font-bold text-center'>
            Real-Time Data: {assetName ? assetName : "Asset"}
          </h1>
          <button
            className={`absolute top-5 right-16 rounded-md px-4 py-2 text-sm flex justify-center items-center`}
            onClick={() => {
              toggleAssetDetails();
              toggleAssetUpsDetails();
            }}
          >
            Switch to UPS dashboard
          </button>
        </header>
        {currentAssetLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <div className='p-6 flex flex-col justify-between'>
            <div className='flex flex-row justify-end'>
              {isShowExportAssetData && (
                <div className="flex flex-col gap-1 md:flex-row md:items-center md:gap-2 lg:flex-wrap xl:flex-nowrap mb-4">
                  <p className="mr-2 font-medium text-lg">Export asset BESS historical data:</p>
                  <div className=" flex items-center justify-between md:justify-center">
                    <p className="mr-1">Start:</p>
                    <div className=" flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        name={`${assetName}-start`}
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        onChange={handleStartDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  <div className="flex items-center justify-between md:justify-center">
                    <p className="mr-1">End:</p>
                    <div className="flex items-center justify-around w-44 h-10 relative cursor-pointer">
                      <Flatpickr
                        placeholder="mm.dd.yyyy hh:mm"
                        className="calendar-input"
                        name={`${assetName}-end`}
                        onChange={handleEndDateTimeChange}
                        onClose={handleCalendarClose}
                        options={{
                          dateFormat: 'm.d.Y H:i',
                          enableTime: true,
                          minDate: assetDataStartDateTime ? new Date(assetDataStartDateTime) : null,
                          maxDate: nowAssetTime,
                          disableMobile: true,
                        }}
                      />
                      <BsCalendarEvent className="custom-icon absolute right-3 z-[-10]" />
                      <div className="w-full h-full absolute z-[-20] bg-white rounded-lg" />
                    </div>
                  </div>

                  {isAssetHistoricalDataLoading
                    ? <Loader classNames="w-5 h-5 mr-2 ml-1" />
                    : (<CSVLink
                      data={transformedData}
                      filename={`${assetName} BESS historical data ${rangeForFileName}.csv`}
                      className='csv-link mr-2 ml-1'
                    >
                      <button
                        type="button"
                        className='save-as-csv rounded-md px-1 py-1 text-xs'
                        title="Save as CSV"
                        disabled={!assetDataStartDateTime || !assetDataEndDateTime}
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFileCsv size={24} />
                        </IconContext.Provider>
                      </button>
                    </CSVLink>)
                  }
                </div>
              )}
              <FiChevronDown
                size={18}
                className={`cursor-pointer transition-transform ${isShowExportAssetData ? 'rotate-180' : null}`}
                onClick={toggleShowExportAssetData}
                title='Export asset historical data'
              />
            </div>
            <div className={`col-span-1 grid ${assetDetailsData.enableDPS ? 'grid-cols-7' : 'grid-cols-6'} gap-8 mb-8`}>
              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Inverter's Operation Mode
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <InverterOperation />
                </div>
                <p className='text-center text-sm font-medium sm:text-base'>
                  Inverter Status: {inverterStatus}
                </p>
                <p className='text-center text-sm font-medium sm:text-base'>
                  Charger Status: {chargerStatus}
                </p>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Backup Power
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <LoadPower />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {loadPower} kW
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery throughput
                </h2>
                <div className='h-[125px] w-[110px]'>
                  <BatteryThroughput />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {batteryThroughput} kWh
                  </p>
                </div>
              </div>
              {assetDetailsData.enableDPS && (
                <div className='flex flex-col items-center'>
                  <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                    Target Power
                  </h2>
                  <div className='h-[125px] w-[110px]'>
                    <TargetPower className='relative z-20 h-full w-full ' />
                  </div>
                  <div>
                    <p className='text-center text-sm font-medium sm:text-base'>
                      {powerGoal} kW
                    </p>
                  </div>
                </div>
              )}
              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery State of Charge
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <Battery className='relative z-10 h-full w-full' />
                  <div
                    className='absolute left-2 w-[100px] bottom-4 h-[89px]'
                  >
                    <div
                      className='absolute w-[100px] iconFilling'
                      style={{ height: `${life}%`, bottom: 0 }}
                    >
                    </div>
                  </div>
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {life}%
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Battery Temperature
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <Thermometer className='relative z-10 h-full w-full' />
                  <div
                    className='absolute left-2 bottom-0 w-[100px] iconFilling'
                    style={{ height: `${tempBar}%` }}
                  />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {tempC}&deg;C
                  </p>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {tempF}&deg;F
                  </p>
                </div>
              </div>

              <div className='flex flex-col items-center'>
                <h2 className='font-bold text-center h-[40px] sm:h-[50px]'>
                  Ambient Temperature
                </h2>
                <div className='relative overflow-hidden h-[125px] w-[110px]'>
                  <ThermometerSun className='relative z-10 h-full w-full' />
                  <div
                    className='absolute left-2 bottom-0 w-[100px] iconFilling'
                    style={{ height: `${aTempBar}%` }}
                  />
                </div>
                <div>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {aTempC}&deg;C
                  </p>
                  <p className='text-center text-sm font-medium sm:text-base'>
                    {aTempF}&deg;F
                  </p>
                </div>
              </div>
            </div>
            <ResponsiveGridLayout
              className='layout'
              layouts={{ lg: layout ? layout : [] }}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
              rowHeight={30}
              margin={[15, 15]}
              measureBeforeMount={true}
              useCSSTransforms={false}
              draggableHandle='.draggableHandle'
              draggableCancel='.dashboardNav--cancel'
              onLayoutChange={handleLayoutChange}
            >
              {layout?.map(element => {
                // Make a human-readable title
                const title = idToText(element.i);

                // Inject child components dynamically
                let componentSwitch;
                let filter;
                let widgetDateFilter;
                let widgetDateRange;
                let createEvent;
                let createEventAndFilters;
                let widgetDateTimeFilter;
                let widgetYearFilter;
                let year;
                let timezone;
                let assetDifferenceFromUtcInMinutes;

                switch (element.i) {
                  case 'BESSGridPower':
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: powerStartDate,
                      end: powerEndDate,
                    };
                    componentSwitch = isGridPowerLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <GridPower
                        assetName={assetName}
                        gridPower={gridPower}

                        powerStartDate={powerAssetStartDate}
                        powerEndDate={powerAssetEndDate}

                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;
                  case 'batteryVoltage/Current':
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: batteryStartDate,
                      end: batteryEndDate,
                    };
                    componentSwitch = isBatteryVoltageCurrentLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <VoltageCurrent
                        assetName={assetName}
                        batteryVoltageCurrent={batteryVoltageCurrent}

                        batteryStartDate={batteryAssetStartDate}
                        batteryEndDate={batteryAssetEndDate}

                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                        assetTimezone={assetTimezone}
                      />
                    );
                    break;
                  case 'dynamicPeakShavingPerformance':
                    widgetDateFilter = dateFilter;
                    widgetDateRange = {
                      start: powerSocStartDate,
                      end: powerSocEndDate,
                    };

                    componentSwitch = isPowerSocLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <PowerSoc
                        assetName={assetName}
                        powerSoc={powerSoc}

                        powerSocStartDate={powerSocAssetStartDate}
                        powerSocEndDate={powerSocAssetEndDate}

                        assetTimezone={assetTimezone}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    );
                    break;
                  case 'maxPower':
                    widgetYearFilter = yearFilter;
                    year = currentYear;

                    componentSwitch = isMaxPowerLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <MaxPower
                        maxPower={maxPower}
                        year={year}
                        assetName={assetName}
                      />
                    );
                    break;
                  case 'chargingEvents':
                    createEventAndFilters = true;
                    timezone = assetTimezone;
                    assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                    widgetDateTimeFilter = dateFilterEvents;
                    widgetDateRange = {
                      start: chargeStartDateTime,
                      end: chargeEndDateTime,
                    };
                    componentSwitch = getChargingEventsLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <ChargingEvents
                        toggleDeleteChargingEventModal={toggleDeleteChargingEventModal}
                        setActiveChargingEventID={setActiveChargingEventID}
                        isWidget={true}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    )
                    break;
                  case 'dischargingEvents':
                    createEventAndFilters = true;
                    timezone = assetTimezone;
                    assetDifferenceFromUtcInMinutes = assetDifferenceFromUtc;
                    widgetDateTimeFilter = dateFilterEvents;
                    widgetDateRange = {
                      start: dischargeStartDateTime,
                      end: dischargeEndDateTime,
                    };
                    componentSwitch = getDischargingEventsLoading ? (
                      <div className='w-full h-full flex justify-center items-center'>
                        <Loader />
                      </div>
                    ) : (
                      <DischargingEvents
                        toggleDeleteDischargingEventModal={toggleDeleteDischargingEventModal}
                        toggleOptOutEventModal={toggleOptOutEventModal}
                        setActiveDischargingEventID={setActiveDischargingEventID}
                        userDetails={userDetails}
                        assetDifferenceFromUtc={assetDifferenceFromUtc}
                      />
                    )
                    break;
                  default:
                    componentSwitch = null;
                    break;
                }

                return (
                  <div
                    key={element.i}
                    className='container-border grid-component__container overflow-hidden'
                  >
                    <Widget
                      title={title}
                      id={element.i}
                      hasFilter={filter}
                      dateFilter={widgetDateFilter}
                      dateTimeFilter={widgetDateTimeFilter}
                      yearFilter={widgetYearFilter}
                      dateRange={widgetDateRange}
                      year={year}
                      child={componentSwitch}
                      hasCreateEvent={createEvent}
                      hasCreateEventAndFilters={createEventAndFilters}
                      toggleCreateChargeEvent={toggleCreateChargeEvent}
                      toggleCreateDischargeEvent={toggleCreateDischargeEvent}
                      assetTimezone={timezone}
                      assetDifferenceFromUtc={assetDifferenceFromUtcInMinutes}
                      assetName={assetName}
                      assetTimeEndOfToday={assetTimeEndOfToday}
                    />
                  </div>
                );
              })}
            </ResponsiveGridLayout>
          </div>
        )}

        {!!showAddChargeEvent && (
          <Modal
            toggleModal={toggleCreateChargeEvent}
            isSmall={true}
            child={
              <CreateChargeEvent
                assetName={assetName}
                toggleModal={toggleCreateChargeEvent}
                userDetails={userDetails}
                dateFilterEvents={dateFilterEvents}
                chargeStartDateTime={chargeStartDateTime}
                chargeEndDateTime={chargeEndDateTime}
                toggleDeleteChargingEventModal={toggleDeleteChargingEventModal}
                setActiveChargingEventID={setActiveChargingEventID}
                enableDPS={assetDetailsData.enableDPS}
                assetDifferenceFromUtc={assetDifferenceFromUtc}
              />}
          />
        )}

        {!!showAddDischargeEvent && (
          <Modal
            toggleModal={toggleCreateDischargeEvent}
            isSmall={true}
            child={
              <CreateDischargeEvent
                assetName={assetName}
                toggleModal={toggleCreateDischargeEvent}
                userDetails={userDetails}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetDifferenceFromUtc={assetDifferenceFromUtc}
              />}
          />
        )}

        {!!showDeleteChargingEventModal && (
          <Modal
            toggleModal={toggleDeleteChargingEventModal}
            isSmall={true}
            child={
              <DeleteChargingEvent
                activeChargingEventID={activeChargingEventID}
                toggleModal={toggleDeleteChargingEventModal}
                dateFilterEvents={dateFilterEvents}
                chargeStartDateTime={chargeStartDateTime}
                chargeEndDateTime={chargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}

        {!!showDeleteDischargingEventModal && (
          <Modal
            toggleModal={toggleDeleteDischargingEventModal}
            isSmall={true}
            child={
              <DeleteDischargingEvent
                activeDischargingEventID={activeDischargingEventID}
                toggleModal={toggleDeleteDischargingEventModal}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}

        {!!showOptOutEventModal && (
          <Modal
            toggleModal={toggleOptOutEventModal}
            isSmall={true}
            child={
              <OptOutDischargingEvent
                activeDischargingEventID={activeDischargingEventID}
                toggleModal={toggleOptOutEventModal}
                dateFilterEvents={dateFilterEvents}
                dischargeStartDateTime={dischargeStartDateTime}
                dischargeEndDateTime={dischargeEndDateTime}
                assetName={assetName}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

export default AssetRealTimeData;


