// React
import React, { useState, useEffect } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// Redux
import { useDispatch } from 'react-redux';

// Helpers
import { idToText } from '../../helpers/widget/idToText';

// Utils
import prepLayout from '../../utils/PrepLayout';

// Components
import DashboardSelect from '../modals/DashboardSelect';
import AssetDetailsForm from '../modals/AssetDetailsForm';
import Modal from '../modals/Modal';
import HomeTopBar from '../pageComponents/home/HomeTopBar';
import AssetRealTimeData from '../modals/AssetRealtimeData';
import AssetUpsRealTimeData from '../modals/AssetUpsRealtimeData';
import Loader from '../Loader';

// Widget Components
import Widget from '../widgets/Widget';
import AssetList from '../widgets/AssetList';
import Power from '../widgets/Power';
import FleetStatus from '../widgets/FleetStatus';
import Energy from '../widgets/Energy';
import Trees from '../widgets/TreesSaved';
import ScheduledEvents from '../widgets/ScheduledEvents';
import AssetLocations from '../widgets/AssetLocations';
import AssetListFaults from '../widgets/AssetListFaults';
import AssetListUnassigned from '../widgets/AssetListUnassigned';
import api from '../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';


const HomeContainer = ({
  dashboardData = {},
  accountsData = [],
  getDashboardData,
}) => {
  const {
    assetList = [],
    dashboardLayout = [],
    events = [],
    treesCount
  } = dashboardData;

  // Init responsive grid layout
  const ResponsiveGridLayout = WidthProvider(Responsive);

  // For Redux
  const dispatch = useDispatch();

  // User credentials
  const token = localStorage.getItem('cdnzAccessToken');
  const username = localStorage.getItem('cdnzUser');

  // State
  const [layout, setLayout] = useState(dashboardLayout);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [addWidget, setAddWidget] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [showRealTimeDataModal, setShowRealTimeDataModal] = useState(false);
  const [showUpsRealTimeDataModal, setShowUpsRealTimeDataModal] = useState(false);
  const [targetData, setTargetData] = useState();

  const [isAccountEnergyLoading, setIsAccountEnergyLoading] = useState(true);
  const [isAccountPowerLoading, setIsAccountPowerLoading] = useState(true);

  const [powerStartDate, setPowerStartDate] = useState('');
  const [powerEndDate, setPowerEndDate] = useState('');
  const [energyStartDate, setEnergyStartDate] = useState('');
  const [energyEndDate, setEnergyEndDate] = useState('');

  const [power, setPower] = useState([]);
  const [energy, setEnergy] = useState([]);

  // Function to handle scroll position change
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // eslint-disable-next-line
  const scrollToPreviousPosition = () => {
    setTimeout(function () {
      window.scrollTo(0, scrollPosition);
    }, 0);
  };

  useEffect(() => {
    scrollToPreviousPosition();
  }, [
    scrollToPreviousPosition,
  ]);

  // Width handling
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Toggle show remove button on widgets
  const toggleShowRemove = () => {
    setScrollPosition(0);
    setShowRemove(!showRemove);
  };

  // Change modal states
  const toggleAddWidget = () => {
    setScrollPosition(0);
    setAddWidget(!addWidget);
  };

  // Toggle asset details modal
  const toggleAssetDetails = () => {
    setScrollPosition(0);
    setShowDetails(!showDetails);
  };

  // Toggle asset real-time data modal
  const toggleAssetRealTimeData = () => {
    setScrollPosition(0);
    setShowRealTimeDataModal(!showRealTimeDataModal);
  };

  // Toggle asset UPS real-time data modal
  const toggleAssetUpsRealTimeData = () => {
    setScrollPosition(0);
    setShowUpsRealTimeDataModal(!showUpsRealTimeDataModal);
  };

  // Toggle widget hidden status
  const toggleHideWidget = id => {
    // find the item with the given id
    let item = layout.find(item => item.i === id);
    // toggle the hidden property of the item
    item.hidden = !item.hidden;
    // copy the original items array
    const newItems = [...layout];
    // update the item in the newItems array
    newItems[newItems.indexOf(item)] = item;
    // update state with the newItems array
    setLayout(newItems);
  };

  // Set asset details modal target data
  const setTargetDetails = targetItem => {
    setTargetData(targetItem);
  };

  const handleLayoutChange = layouts => {
    if (windowDimensions.width > 1024) {
      prepLayout(layouts, layout, 'homeLayout');
    }
  };

  // Fetch initial data for charts
  const fetchInitialChartsData = () => {
    const startOfDayLocalTime = new Date();
    startOfDayLocalTime.setHours(0, 0, 0, 0);

    const endOfDayLocalTime = new Date();
    endOfDayLocalTime.setHours(23, 59, 59, 999);

    const startToUTC = startOfDayLocalTime.toUTCString();
    const endToUTC = endOfDayLocalTime.toUTCString();

    // Calculate dates for account energy in UTC
    const now = new Date();
    const dayForEnergy = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), 0, 0, 0, 0));

    // Fetch data for account power
    api.post(
      `/device/power`,
      {
        startDate: startToUTC,
        endDate: endToUTC,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setPower(res.data.powerData.power);
        setPowerStartDate(startOfDayLocalTime);
        setPowerEndDate(endOfDayLocalTime);
        setIsAccountPowerLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch account power data', notifyOptions);
        setPowerStartDate(startOfDayLocalTime);
        setPowerEndDate(endOfDayLocalTime);
        setIsAccountPowerLoading(false);
      });

    // Fetch data for account energy
    api.post(
      `/device/energy`,
      {
        startDate: dayForEnergy,
        endDate: dayForEnergy,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      }
    )
      .then(res => {
        setEnergy(res.data.energyData.energy);
        setEnergyStartDate(startOfDayLocalTime);
        setEnergyEndDate(endOfDayLocalTime);
        setIsAccountEnergyLoading(false);
      })
      .catch(() => {
        Notify.warning('Failed to fetch account energy data', notifyOptions);
        setEnergyStartDate(startOfDayLocalTime);
        setEnergyEndDate(endOfDayLocalTime);
        setIsAccountEnergyLoading(false);
      });
  };

  // Date filter functionality for charts
  const dateFilter = (element, startDateParam, endDateParam) => {
    if (!element) return;

    let startDate = startDateParam instanceof Date ? startDateParam : new Date(startDateParam);
    let endDate = endDateParam instanceof Date ? endDateParam : new Date(endDateParam);

    if (!startDate || !endDate) return;

    if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
      console.error("Invalid Date objects");
      return;
    }

    const startOfDayLocalTime = startDate;
    startOfDayLocalTime.setHours(0, 0, 0, 0);

    const endOfDayLocalTime = endDate;
    endOfDayLocalTime.setHours(23, 59, 59, 999);

    const startToUTC = startOfDayLocalTime.toUTCString();
    const endToUTC = endOfDayLocalTime.toUTCString();

    // Extract day, month, and year in local time
    const localStartDay = startDate.getDate();
    const localStartMonth = startDate.getMonth();
    const localStartYear = startDate.getFullYear();

    const localEndDay = endDate.getDate();
    const localEndMonth = endDate.getMonth();
    const localEndYear = endDate.getFullYear();

    // Create new date objects using the extracted components for Account Energy
    const startOfDayUTC = new Date(Date.UTC(localStartYear, localStartMonth, localStartDay, 0, 0, 0, 0));
    const endOfDayUTC = new Date(Date.UTC(localEndYear, localEndMonth, localEndDay, 23, 59, 59, 999));

    handleScroll();

    if (element === 'power') {
      setIsAccountPowerLoading(true);
    }
    if (element === 'energy') {
      setIsAccountEnergyLoading(true);
    }

    api
      .post(
        `/device/${element}`,
        {
          startDate: element === 'power' ? startToUTC : startOfDayUTC,
          endDate: element === 'power' ? endToUTC : endOfDayUTC,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        }
      )
      .then(res => res.data)
      .then(data => {
        if (element === 'power') {
          setPower(data.powerData.power);
          setPowerStartDate(startDateParam);
          setPowerEndDate(endDateParam);
          setIsAccountPowerLoading(false);
        }
        if (element === 'energy') {
          setEnergy(data.energyData.energy);
          setEnergyStartDate(startDateParam);
          setEnergyEndDate(endDateParam);
          setIsAccountEnergyLoading(false);
        }
      })
      .catch(() => {
        if (element === 'power') {
          Notify.warning('Failed to fetch account power data', notifyOptions);
          setPowerStartDate(startDateParam);
          setPowerEndDate(endDateParam);
          setIsAccountPowerLoading(false);
        }
        if (element === 'energy') {
          Notify.warning('Failed to fetch account energy data', notifyOptions);
          setEnergyStartDate(startDateParam);
          setEnergyEndDate(endDateParam);
          setIsAccountEnergyLoading(false);
        }
      });
  };

  useEffect(() => {
    fetchInitialChartsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <main className="flex justify-center p-2 md:p-5 min-h-full">
      <section className="container">
        <HomeTopBar
          toggleModal={toggleAddWidget}
          toggleShowRemove={toggleShowRemove}
        />
        <ResponsiveGridLayout
          className="layout"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 6, xxs: 6 }}
          rowHeight={30}
          margin={[15, 15]}
          measureBeforeMount={true}
          useCSSTransforms={false}
          draggableHandle=".draggableHandle"
          draggableCancel=".dashboardNav--cancel"
          onLayoutChange={handleLayoutChange}
        >
          {layout?.map(element => {
            if (!element.hidden) {
              // Make a human-readable title
              const title = idToText(element.i);

              // Inject child components dynamically
              let componentSwitch;
              let filter;
              let widgetDateFilter;
              let widgetDateRange;
              let createEvent;

              switch (element.i) {
                case 'assetList':
                  filter = true;
                  componentSwitch = (
                    <AssetList
                      filteredAssets={assetList}
                      setTargetDetails={setTargetDetails}
                      toggleAssetRealTimeData={toggleAssetUpsRealTimeData}
                      toggleAssetDetails={toggleAssetDetails}
                    />
                  );
                  break;
                case 'accountPower':
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: powerStartDate,
                    end: powerEndDate,
                  };
                  componentSwitch = isAccountPowerLoading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>
                  ) : (
                    <Power
                      power={power}
                      powerStartDate={powerStartDate}
                      powerEndDate={powerEndDate}
                    />
                  )
                  break;
                case 'assetFaults':
                  filter = true;
                  componentSwitch = (
                    <AssetListFaults setTargetDetails={setTargetDetails} />
                  );
                  break;
                case 'fleetStatus':
                  componentSwitch = <FleetStatus />;
                  break;
                case 'accountEnergy':
                  widgetDateFilter = dateFilter;
                  widgetDateRange = {
                    start: energyStartDate,
                    end: energyEndDate,
                  };
                  componentSwitch = isAccountEnergyLoading ? (
                    <div className='w-full h-full flex justify-center items-center'>
                      <Loader />
                    </div>
                  ) : (
                    <Energy
                      energy={energy}
                      energyStartDate={energyStartDate}
                      energyEndDate={energyEndDate}
                    />
                  )
                  break;
                case 'treesSaved':
                  componentSwitch = (
                    <Trees treesCount={treesCount} />
                  );
                  break;
                case 'scheduledDR': //scheduledDREvents
                  createEvent = true;
                  componentSwitch = <ScheduledEvents />;
                  break;
                case 'assetLocations':
                  componentSwitch = <AssetLocations />;
                  break;
                case 'assetsUnassigned': //AssetListUnassigned
                  filter = true;
                  componentSwitch = (
                    <AssetListUnassigned setTargetDetails={setTargetDetails} />
                  );
                  break;
                default:
                  componentSwitch = null;
                  break;
              }

              return (
                <div
                  key={element.i}
                  className="container-border grid-component__container overflow-hidden"
                >
                  <Widget
                    title={title}
                    id={element.i}
                    showRemove={showRemove}
                    toggleHideWidget={toggleHideWidget}
                    hasFilter={filter}
                    dateFilter={widgetDateFilter}
                    dateRange={widgetDateRange}
                    child={componentSwitch}
                    hasCreateEvent={createEvent}
                    assets={assetList}
                    calendarEvents={events}
                  />
                </div>
              );
            }

            return null;
          })}
        </ResponsiveGridLayout>

        {!!addWidget && (
          <Modal
            toggleModal={toggleAddWidget}
            isSmall={false}
            child={
              <DashboardSelect
                data={dashboardData}
                toggleHideWidget={toggleHideWidget}
              />
            }
          />
        )}

        {!!showDetails && (
          <Modal
            toggleModal={toggleAssetDetails}
            isSmall={false}
            child={
              <AssetDetailsForm
                assetData={targetData}
                accounts={accountsData}
                toggleAssetDetails={toggleAssetDetails}
                getDashboardData={getDashboardData}
              />
            }
          />
        )}

        {!!showRealTimeDataModal && (
          <Modal
            toggleModal={toggleAssetRealTimeData}
            isSmall={false}
            child={
              <AssetRealTimeData
                assetData={targetData}
                toggleAssetDetails={toggleAssetRealTimeData}
                toggleAssetUpsDetails={toggleAssetUpsRealTimeData}
              />
            }
          />
        )}

        {!!showUpsRealTimeDataModal && (
          <Modal
            toggleModal={toggleAssetUpsRealTimeData}
            isSmall={false}
            child={
              <AssetUpsRealTimeData
                assetData={targetData}
                toggleAssetUpsDetails={toggleAssetUpsRealTimeData}
                toggleAssetDetails={toggleAssetRealTimeData}
              />
            }
          />
        )}
      </section>
    </main>
  );
};

export default HomeContainer;
