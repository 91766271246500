export const convertDateToUTC = (date, assetDifferenceFromUtc) => {
  if (!date || !(date instanceof Date)) {
    throw new Error('date must be an instance of Date');
  }

  const pad = (num, length = 2) => num.toString().padStart(length, '0');
  const padMilliseconds = num => num.toString().padStart(3, '0');

  // Adjust for the asset's timezone offset
  const adjustedDate = new Date(date.getTime() - assetDifferenceFromUtc * 60 * 1000);

  const year = adjustedDate.getFullYear();
  const month = pad(adjustedDate.getMonth() + 1);
  const day = pad(adjustedDate.getDate());
  const hours = pad(adjustedDate.getHours());
  const minutes = pad(adjustedDate.getMinutes());
  const seconds = pad(adjustedDate.getSeconds());
  const milliseconds = padMilliseconds(adjustedDate.getMilliseconds());

  const isoString = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

  return isoString;
};

export const validateAndConvertDatesToUTC = (startDateTime, endDateTime, assetDifferenceFromUtc) => {
  if (!startDateTime || !endDateTime) return null;

  let startDate = startDateTime instanceof Date ? startDateTime : new Date(startDateTime);
  let endDate = endDateTime instanceof Date ? endDateTime : new Date(endDateTime);

  if (!startDate || !endDate) return null;

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    console.error("Invalid Date objects");
    return null;
  }

  const formattedStartDateUTC = convertDateToUTC(startDate, assetDifferenceFromUtc);
  const formattedEndDateUTC = convertDateToUTC(endDate, assetDifferenceFromUtc);

  return {
    formattedStartDateUTC,
    formattedEndDateUTC,
  };
};