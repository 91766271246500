import React, { useEffect } from 'react';

// Assets
import { FiX } from 'react-icons/fi';

/*
 *
 * HOW TO USE:
 *
 * Parent should have a boolean state for hide/show of modal.
 *
 * Parent should have a function, passed to modal as a prop to change the hide/show state called, the prop name
 * should be toggleModal.
 *
 * Modal should be given a child component which contains content of the modal.
 *
 * Modal should have a prop called isSmall that is a boolean, if true then the modal is small, if false
 * or if it is not set then the modal will be fullsized.
 *
 */
const Modal = props => {
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <div
      className="modal-container modal-container-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none cursor-pointer" /*onClick={() => props.toggleModal()}*/
    >
      {props.isSmall ? (
        <div
          className="w-full text-sm md:text-base max-h-95vh md:max-h-85vh md:w-4/5 lg:w-1/2 overflow-y-auto my-6 border-0 p-6 rounded-lg shadow-lg relative flex flex-col justify-between bg-white outline-none focus:outline-none cursor-auto "
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <span
            className="absolute top-0 right-0 pt-4 pr-6"
            role="button"
            onClick={props.toggleModal}
          >
            <FiX size={28} />
          </span>
          {props.child}
        </div>
      ) : (
        <div
          className="container mx-auto border-0 p-6 rounded-lg shadow-lg relative flex flex-col justify-between w-full bg-white outline-none focus:outline-none cursor-auto max-h-95vh overflow-y-auto"
          onClick={event => {
            event.stopPropagation();
          }}
        >
          <span
            className="absolute top-0 right-0 pt-4 pr-6"
            role="button"
            onClick={props.toggleModal}
          >
            <FiX size={28} />
          </span>
          {props.child}
        </div>
      )}
    </div>
  );
};

export default Modal;
