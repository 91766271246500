// React
import React, { useState } from 'react';

// Assets
import Loader from '../../Loader';
import CustomDatePicker from '../../CustomDatePicker';

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import BatteryModuleFormSchema from './BatteryModuleFormSchema';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// api
import api from '../../../api/axios.config';

// Helpers
import transformDateForBatteryModules from '../../../helpers/formatDate/transformDateForBatteryModules';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

// Function to transform keys in a single object
function transformKeys(obj) {
  const transformedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const transformedKey = key.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
      transformedObj[transformedKey] = obj[key];
    }
  }
  return transformedObj;
}

const BatteryModuleForm = ({
  assetName,
  toggleModal,
  status,
  batteryModule: initialBatteryModule,
  fetchBatteryModules,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const transformedBatteryModules = transformKeys(initialBatteryModule);

  const initialValues = {
    replacementInstallationDate: '',
    serialNumber: '',
    modelNumber: '',
    softwareVersion: '',
    manufacturer: '',
  };

  // Create or update battery module request
  const upsertBatteryModule = (body, action) => {
    setIsLoading(true);
    api.post(
      `/device/battery-module/${action === 'create' ? 'create' : 'update'}`,
      {
        ...body
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(response => {
        setIsLoading(false);
        if (response.data.status === 'success') {
          Notify.success(
            `Battery module was ${action === 'create' ? 'created' : 'updated'} successfully`,
            notifyOptions,
          );
          fetchBatteryModules();
        }
      })
      .catch(error => {
        setIsLoading(false);
        Notify.failure(
          `There was an error ${action === 'create' ? 'creating' : 'updating'} the battery module`,
          notifyOptions,
        );
      });
  }

  const handleSubmitCreate = (values) => {
    const transformedDate = transformDateForBatteryModules(values.replacementInstallationDate);
    const batteryModuleData = {
      assetName,
      replacement_installation_date: transformedDate,
      serial_number: values.serialNumber,
      model_number: values.modelNumber,
      software_version: values.softwareVersion,
      manufacturer: values.manufacturer,
    }

    upsertBatteryModule(batteryModuleData, 'create');
    toggleModal();
  };

  const handleSubmitUpdate = (values) => {
    const transformedDate = transformDateForBatteryModules(values.replacementInstallationDate);
    const batteryModuleData = {
      assetName,
      replacement_installation_date: transformedDate,
      serial_number: values.serialNumber,
      model_number: values.modelNumber,
      software_version: values.softwareVersion,
      manufacturer: values.manufacturer,
      module_id: transformedBatteryModules.moduleId,
      is_replaced: Number(values.replaceModule),
    }

    upsertBatteryModule(batteryModuleData);
    toggleModal();
  };

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold text-center mb-6">
          {status === 'edit' ? 'Edit' : 'Create'} Battery Module
        </h1>
        <Formik
          initialValues={status === 'edit' ? transformedBatteryModules : initialValues}
          validationSchema={BatteryModuleFormSchema(status)}
          onSubmit={(values, formikBag) => {
            if (status === 'edit') {
              handleSubmitUpdate(values, formikBag);
            } else {
              handleSubmitCreate(values, formikBag);
            }
          }}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <div className="md:grid grid-cols-2 gap-8">
              </div>
              <div className="md:grid grid-cols-2 gap-8 my-10">
                <div>
                  <h3 className="mt-4 mb-2 font-bold">Serial Number</h3>
                  <Field
                    type="text"
                    name="serialNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Serial Number"
                  />
                  <ErrorMessage
                    name="serialNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Model Number</h3>
                  <Field
                    type="text"
                    name="modelNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Model Number"
                  />
                  <ErrorMessage
                    name="modelNumber"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Software Version</h3>
                  <Field
                    type="text"
                    name="softwareVersion"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Software Version"
                  />
                  <ErrorMessage
                    name="softwareVersion"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">Manufacturer</h3>
                  <Field
                    type="text"
                    name="manufacturer"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Manufacturer"
                  />
                  <ErrorMessage
                    name="manufacturer"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                <div>
                  <h3 className="mt-4 mb-2 font-bold">{status === 'edit' && values.replaceModule === '1' ? 'Replacement' : 'Installation'} Date</h3>
                  <CustomDatePicker name="replacementInstallationDate" />
                  <ErrorMessage
                    name="replacementInstallationDate"
                    component="div"
                    className="text-red-500"
                  />
                </div>

                {status === 'edit' && (
                  <div>
                    <h3 className="mt-4 mb-4 font-bold">Replace Module</h3>
                    <div role="group" aria-labelledby="replaceModuleLabel">
                      <label className="font-bold">
                        <Field type="radio" name="replaceModule" value="1" className="mb-1 mr-1" />
                        Yes
                      </label>

                      <label className="ml-4 font-bold">
                        <Field type="radio" name="replaceModule" value="0" className="mb-1 mr-1" />
                        No
                      </label>
                    </div>
                    <ErrorMessage
                      name="replaceModule"
                      component="div"
                      className="text-red-500"
                    />
                  </div>
                )}

              </div>
              <div className="flex justify-around">
                <button
                  className="rounded-md px-4 py-2 text-sm flex justify-center items-center"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isLoading ? (
                    <Loader classNames="w-5 h-5" />
                  ) : status === 'edit' ? (
                    'Save'
                  ) : (
                    'Create Battery Module'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BatteryModuleForm;
