const formatDateToGMT = dateString => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'];

  const date = new Date(dateString);
  return `${days[date.getUTCDay()]}, ${String(date.getUTCDate()).
    padStart(2,
      '0')} ${months[date.getUTCMonth()]} ${date.getUTCFullYear()} ${String(
        date.getUTCHours()).padStart(2, '0')}:${String(date.getUTCMinutes()).
          padStart(2, '0')}:${String(date.getUTCSeconds()).padStart(2, '0')} GMT`;
};

export default formatDateToGMT;
