import { createSelector } from '@reduxjs/toolkit';

// Charging events
const getChargingEvents = state => state.assetRealTimeData.data.chargingEvents.data;
const getFilterChargingEvent = state => state.assetRealTimeData.data.chargingEvents.filter;
const getFilteredChargingEvents = createSelector(
  [getChargingEvents, getFilterChargingEvent],
  (events, statusFilter) => {
    if (
      statusFilter === '' ||
      statusFilter === null ||
      statusFilter === undefined
    ) {
      return events;
    } else {
      return events.filter(event => {
        const source = event?.source;
        const created_by = event?.created_by;
        const event_number = event?.event_number;
        const target_soc = event?.target_soc;
        const power_level = event?.power_level;
        const event_type = event?.event_type;

        return (
          (source && source.toLowerCase().includes(statusFilter.toLowerCase())) ||
          (created_by && created_by.toLowerCase().includes(statusFilter.toLowerCase())) ||
          (event_number && event_number.toString().toLowerCase().includes(statusFilter.toLowerCase())) ||
          (target_soc && target_soc.toString().toLowerCase().includes(statusFilter.toLowerCase())) ||
          (power_level && power_level.toString().toLowerCase().includes(statusFilter.toLowerCase())) ||
          (event_type && event_type.toString().toLowerCase().includes(statusFilter.toLowerCase()))
        );
      });
    }
  }
);
const getAddChargingEventsLoading = state =>
  state.assetRealTimeData.data.chargingEvents.addEvent.loading;
const getDeleteChargingEventLoading = state =>
  state.assetRealTimeData.data.chargingEvents.deleteEvent.loading;
const getChargingEventsLoading = state =>
  state.assetRealTimeData.data.chargingEvents.loading

// Discharging events
const getDischargingEvents = state => state.assetRealTimeData.data.dischargingEvents.data;
const getFilterDischargingEvent = state => state.assetRealTimeData.data.dischargingEvents.filter;
const getFilteredDischargingEvents = createSelector(
  [getDischargingEvents, getFilterDischargingEvent],
  (events, statusFilter) => {
    if (
      statusFilter === '' ||
      statusFilter === null ||
      statusFilter === undefined
    ) {
      return events;
    } else {
      return events.filter(event => {
        const source = event?.source;
        const created_by = event?.created_by;
        const status = event?.state;
        const power_level = event?.power_level;
        const mode = event?.mode;

        return (
          (source && source.toLowerCase().includes(statusFilter.toLowerCase())) ||
          (created_by && created_by.toLowerCase().includes(statusFilter.toLowerCase())) ||
          (status && status.toString().toLowerCase().includes(statusFilter.toLowerCase())) ||
          (power_level && power_level.toString().toLowerCase().includes(statusFilter.toLowerCase())) ||
          (mode && mode.toString().toLowerCase().includes(statusFilter.toLowerCase()))
        );
      });
    }
  }
);
const getAddDischargingEventsLoading = state =>
  state.assetRealTimeData.data.dischargingEvents.addEvent.loading;
const getDeleteDischargingEventLoading = state =>
  state.assetRealTimeData.data.dischargingEvents.deleteEvent.loading;
const getDischargingEventsLoading = state =>
  state.assetRealTimeData.data.dischargingEvents.loading

export const assetRealTimeDataSelectors = {
  // Charging events
  getChargingEvents,
  getFilterChargingEvent,
  getFilteredChargingEvents,
  getAddChargingEventsLoading,
  getDeleteChargingEventLoading,
  getChargingEventsLoading,

  // Discharging events
  getDischargingEvents,
  getFilterDischargingEvent,
  getFilteredDischargingEvents,
  getAddDischargingEventsLoading,
  getDeleteDischargingEventLoading,
  getDischargingEventsLoading,
};

