// Redux
import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// Operations
import assetRealTimeDataOperations from './assetRealTimeDataOperations';

const initialState = {
  data: {
    dischargingEvents: {
      data: [],
      filter: null,
      loading: false,
      error: null,
      addEvent: {
        loading: false,
        error: null,
      },
      deleteEvent: {
        loading: false,
        error: null,
      },
    },
    chargingEvents: {
      data: [],
      filter: null,
      loading: false,
      error: null,
      addEvent: {
        loading: false,
        error: null,
      },
      deleteEvent: {
        loading: false,
        error: null,
      },
    },
  },
  loading: false,
  error: null,
};

export const assetRealTimeDataSlice = createSlice({
  name: 'assetRealTimeData',
  initialState,
  reducers: {
    setFilterChargingEvents: {
      reducer(state, { payload }) {
        state.data.chargingEvents.filter = payload;
      },
    },
    setFilterDischargingEvents: {
      reducer(state, { payload }) {
        state.data.dischargingEvents.filter = payload;
      },
    },
  },
  extraReducers: builder => {
    builder
      // GET CHARGING EVENTS
      .addCase(assetRealTimeDataOperations.getChargingEvents.pending, state => {
        state.data.chargingEvents.loading = true;
        state.data.chargingEvents.error = null;
      })
      .addCase(
        assetRealTimeDataOperations.getChargingEvents.rejected,
        (state, { payload }) => {
          state.data.chargingEvents.loading = false;
          state.data.chargingEvents.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.getChargingEvents.fulfilled,
        (state, { payload }) => {
          state.data.chargingEvents.data = payload;
          state.data.chargingEvents.loading = false;
        }
      )

      // ADD CHARGING EVENTS
      .addCase(assetRealTimeDataOperations.addChargingEvent.pending, state => {
        state.data.chargingEvents.addEvent.loading = true;
        state.data.chargingEvents.addEvent.error = null;
      })
      .addCase(
        assetRealTimeDataOperations.addChargingEvent.rejected,
        (state, { payload }) => {
          state.data.chargingEvents.addEvent.loading = false;
          state.data.chargingEvents.addEvent.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.addChargingEvent.fulfilled,
        (state, _) => {
          state.data.chargingEvents.addEvent.loading = false;
        }
      )

      // DELETE CHARGING EVENTS
      .addCase(
        assetRealTimeDataOperations.deleteChargingEvent.pending,
        state => {
          state.data.chargingEvents.deleteEvent.loading = true;
          state.data.chargingEvents.deleteEvent.error = null;
        }
      )
      .addCase(
        assetRealTimeDataOperations.deleteChargingEvent.rejected,
        (state, { payload }) => {
          state.data.chargingEvents.deleteEvent.loading = false;
          state.data.chargingEvents.deleteEvent.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.deleteChargingEvent.fulfilled,
        (state, _) => {
          state.data.chargingEvents.deleteEvent.loading = false;
        }
      )

      // GET DISCHARGING EVENTS
      .addCase(
        assetRealTimeDataOperations.getDischargingEvents.pending,
        state => {
          state.data.dischargingEvents.loading = true;
          state.data.dischargingEvents.error = null;
        }
      )
      .addCase(
        assetRealTimeDataOperations.getDischargingEvents.rejected,
        (state, { payload }) => {
          state.data.dischargingEvents.loading = false;
          state.data.dischargingEvents.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.getDischargingEvents.fulfilled,
        (state, { payload }) => {
          state.data.dischargingEvents.data = payload;
          state.data.dischargingEvents.loading = false;
        }
      )

      // ADD DISCHARGING EVENTS
      .addCase(
        assetRealTimeDataOperations.addDischargingEvent.pending,
        state => {
          state.data.dischargingEvents.addEvent.loading = true;
          state.data.dischargingEvents.addEvent.error = null;
        }
      )
      .addCase(
        assetRealTimeDataOperations.addDischargingEvent.rejected,
        (state, { payload }) => {
          state.data.dischargingEvents.addEvent.loading = false;
          state.data.dischargingEvents.addEvent.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.addDischargingEvent.fulfilled,
        (state, _) => {
          state.data.dischargingEvents.addEvent.loading = false;
        }
      )

      // DELETE DISCHARGING EVENTS
      .addCase(
        assetRealTimeDataOperations.deleteDischargingEvent.pending,
        state => {
          state.data.dischargingEvents.deleteEvent.loading = true;
          state.data.dischargingEvents.deleteEvent.error = null;
        }
      )
      .addCase(
        assetRealTimeDataOperations.deleteDischargingEvent.rejected,
        (state, { payload }) => {
          state.data.dischargingEvents.deleteEvent.loading = false;
          state.data.dischargingEvents.deleteEvent.error = payload;
        }
      )
      .addCase(
        assetRealTimeDataOperations.deleteDischargingEvent.fulfilled,
        (state, _) => {
          state.data.dischargingEvents.deleteEvent.loading = false;
        }
      )
  },
});

const persistConfig = {
  key: 'cadenza/assetRealTimeData',
  storage,
};

export const { setFilterChargingEvents, setFilterDischargingEvents } = assetRealTimeDataSlice.actions;

export const persistedAssetRealTimeDataReducer = persistReducer(
  persistConfig,
  assetRealTimeDataSlice.reducer
);
