import React, { useEffect, useState, useCallback } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Dot,
} from 'recharts';
import { IconContext } from 'react-icons';
import { PiFileCsv, PiFilePng } from "react-icons/pi";
import { getTicks, convertToDays, tickFormatterToAssetTime } from '../../helpers/charts';
import formatRangeForFileName from '../../helpers/formatDate/formatRangeForFileName';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import api from '../../api/axios.config';
import notifyOptions from '../../constants/notify.options';
import { Notify } from 'notiflix';
import { CSVLink } from "react-csv";
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

function VoltageCurrent(
  {
    assetName,
    batteryStartDate,
    batteryEndDate,
    batteryVoltageCurrent = [],
    assetDifferenceFromUtc,
    assetTimezone,
  },
) {
  const [batteryVoltageCurrentData, setBatteryVoltageCurrentData] = useState(batteryVoltageCurrent);

  const [getPng, { ref }] = useCurrentPng();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);

      const assetDate = new Date(date.getTime() - (- date.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

      const formattedDate = `${assetDate.getFullYear()}-`
        + `${String(assetDate.getMonth() + 1).padStart(2, '0')}-`
        + `${String(assetDate.getDate()).padStart(2, '0')} `
        + `${String(assetDate.getHours()).padStart(2, '0')}:`
        + `${String(assetDate.getMinutes()).padStart(2, '0')} `;

      const formatNumber = (value) => parseFloat(value).toFixed(2);

      const voltagePayload = payload.find((entry) => entry.dataKey === 'batteryVoltage');
      const currentPayload = payload.find((entry) => entry.dataKey === 'batteryCurrent');

      return (
        <div className='custom-tooltip' style={{ backgroundColor: '#f4f4f4', padding: '10px', border: '1px solid #ccc' }}>
          <p className='label'>{`Timezone: ${assetTimezone}`}</p>
          <p className='label'>{`Time: ${formattedDate}`}</p>
          {voltagePayload && (
            <p className='voltage'>{`Voltage: ${formatNumber(voltagePayload.value)} V`}</p>
          )}
          {currentPayload && (
            <p className='current'>{`Current: ${formatNumber(currentPayload.value)} A`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const startDate = batteryStartDate.toISOString();
  const endDate = batteryEndDate.toISOString();

  const dateDifference = batteryEndDate - batteryStartDate;
  const dateDifferenceInDays = convertToDays(dateDifference);
  const xTicks = getTicks(batteryVoltageCurrent, dateDifferenceInDays);
  const username = localStorage.getItem('cdnzUser');

  useEffect(() => {
    const fetchNewVoltageCurrentData = () => {
      const token = localStorage.getItem('cdnzAccessToken');
      api.post(
        `/device/asset-data/${assetName}/historical`,
        {
          startDate,
          endDate,
          propName: 'batteryVoltage',
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        },
      )
        .then((res) => {
          setBatteryVoltageCurrentData(res.data.assetHistoricalData.batteryVoltage);
        })
        .catch(() => {
          Notify.warning('Failed to fetch battery voltage/current data', notifyOptions);
        });
    };
    const intervalId = setInterval(fetchNewVoltageCurrentData, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dummyData = [];

  if (
    !batteryVoltageCurrentData || !Array.isArray(batteryVoltageCurrentData) || batteryVoltageCurrentData.length === 0
  ) {
    let endTime;
    const currentDate = new Date();
    const currentDay = currentDate.toDateString();

    const endDate = new Date(batteryEndDate.getTime() - (- batteryEndDate.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

    const batteryEndDateString = new Date(endDate).toDateString();

    if (batteryEndDateString === currentDay) {
      endTime = new Date();
    } else {
      endTime = new Date(endDate);
      endTime.setHours(23, 59, 59, 999);
    }

    // Calculate the number of half-hour segments between batteryStartDate and endTime
    const startTime = new Date(batteryStartDate);
    const segmentCount = Math.ceil((endTime - startTime) / (30 * 60 * 1000));

    // Create an array of objects with timestamps for each half-hour segment
    for (let i = 0; i < segmentCount; i++) {
      const timestamp = new Date(startTime.getTime() + i * 30 * 60 * 1000);
      dummyData.push({
        timestamp,
        batteryVoltage: 0,
        batteryCurrent: 0,
      });
    }
  }

  const showData = (
    !batteryVoltageCurrentData ||
    !Array.isArray(batteryVoltageCurrentData) ||
    batteryVoltageCurrentData.length === 0) ? dummyData : batteryVoltageCurrentData;

  const rangeForFileName = formatRangeForFileName(batteryStartDate, batteryEndDate, assetDifferenceFromUtc);

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, `${assetName} Battery voltage & current ${rangeForFileName}.png`);
    }
  }, [getPng, assetName, rangeForFileName]);

  const transformedData = transformDataForCSV(showData, assetDifferenceFromUtc, ['batteryVoltage', 'batteryCurrent']);

  const divisor = Math.ceil(showData.length / 20);

  return (
    <>
      {(
        <ResponsiveContainer>
          <LineChart
            ref={ref}
            width={500}
            height={300}
            data={showData}
            margin={{
              top: 5,
              right: 20,
              left: 10,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='timestamp'
              ticks={xTicks}
              tickFormatter={(timestamp) => tickFormatterToAssetTime(timestamp, dateDifference, assetDifferenceFromUtc)}
            />
            <YAxis yAxisId='left' orientation='left' domain={[46, 58]}>
              <Label
                value='V'
                angle={0}
                position='insideLeft'
                offset={0}
                dx={20}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <YAxis yAxisId='right' orientation='right'>
              <Label
                value='A'
                angle={0}
                position='insideRight'
                offset={0}
                dx={-10}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              content={(props) => {
                const { payload } = props;
                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingRight: '1rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {payload.map((entry, index) => (
                        <div key={`item-${index}`} style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
                          <svg height={20} width={20}>
                            <line
                              strokeWidth={2}
                              stroke={entry.color}
                              x1={0}
                              y1={10}
                              x2={40}
                              y2={10}
                            />
                            <circle cx={10} cy={10} r={3} fill={"#f4f4f4"} stroke={entry.color} />
                          </svg>
                          <div style={{ marginLeft: '5px' }}>{entry.value}</div>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <CSVLink
                        data={transformedData}
                        filename={`${assetName} Battery voltage & current ${rangeForFileName}.csv`}
                        className='csv-link'
                      >
                        <button
                          type="button"
                          className='save-as-csv rounded-md px-1 py-1 text-xs'
                          title="Save as CSV"
                        >
                          <IconContext.Provider value={{ color: '#00000' }}>
                            <PiFileCsv size={24} />
                          </IconContext.Provider>
                        </button>
                      </CSVLink>
                      <button
                        type="button"
                        className='save-as-png rounded-md px-1 py-1 text-xs'
                        onClick={handleDownload}
                        title="Save as PNG"
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFilePng size={24} />
                        </IconContext.Provider>
                      </button>
                    </div>
                  </div>
                );
              }}
            />
            <Line
              type='monotone'
              dataKey='batteryVoltage'
              stroke='#0f3052'
              name='Voltage'
              yAxisId='left'
              isAnimationActive={false}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
            <Line
              type='monotone'
              dataKey='batteryCurrent'
              stroke='#82ca9d'
              name='Current'
              yAxisId='right'
              isAnimationActive={false}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default VoltageCurrent;

