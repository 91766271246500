import React, { useEffect, useState } from 'react';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';
import Loader from '../../Loader';

export default function ProgramDeactivate ({ toggleModal, activeProgram, onProgramListUpdates }) {
  const [isAssetEnrolled, setIsAssetEnrolled] = useState(null);
  const [isLoadingDeactivate, setIsLoadingDeactivate] = useState(true);
  const [isLoadingDeactivateButton, setIsLoadingDeactivateButton] = useState(
    false);

  useEffect(() => {
    async function getAssetList (programKey) {
      const username = localStorage.getItem('cdnzUser');
      const token = localStorage.getItem('cdnzAccessToken');
      try {
        const { data } = await api.get(`/programs/asset-list/${programKey}`, {
          headers: {
            username: username,
            Authorization: `Bearer ${token}`,
          },
        });
        if (data.result.length > 0) {
          setIsAssetEnrolled(true);
        } else {
          setIsAssetEnrolled(false);
        }
      } catch (err) {
        console.log(err);
        Notify.failure(
          `There was an error getting the list of assets for the program ${activeProgram.programName}.`,
          notifyOptions,
        );
        setIsAssetEnrolled(false);
      } finally {
        setIsLoadingDeactivate(false);
      }
    }

    getAssetList(activeProgram.programKey);
  }, [activeProgram]);

  const handleDeactivateConfirm = async () => {
    setIsLoadingDeactivateButton(true);
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');
    const programKey = activeProgram.programKey;

    try {
      const { data } = await api.delete(`/programs/${programKey}`, {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });
      Notify.success(
        `The program ${activeProgram.programName} was successfully deleted.`,
        notifyOptions,
      );
      setTimeout(() => {
        onProgramListUpdates();
      }, 1000);  
    } catch (err) {
      console.log(err);
      Notify.failure(
        `There was an error deleting the program ${activeProgram.programName}.`,
        notifyOptions,
      );
    } finally {
      setIsLoadingDeactivateButton(false);
    }

    toggleModal();
  };

  const handleDeactivateReject = () => {
    toggleModal();
  };

  return (
    <div className='min-h-80vh flex flex-col items-center gap-6'>
      {isLoadingDeactivate && (
        <div className='flex flex-col items-center gap-6'>
          <h1 className='text-3xl font-bold text-center py-28'>
            <Loader classNames='w-25 h-25' />
          </h1>
        </div>
      )}

      {!isLoadingDeactivate && isAssetEnrolled && (
        <div className='flex flex-col items-center gap-6'>
          <h1 className='text-3xl font-bold text-center py-28'>
            Please unenroll all assets enrolled in this program before deleting
            the Demand Response program.
          </h1>
          <div className='flex flex-col justify-center gap-4 md:flex-row'>
            <button
              className='rounded-md py-2 text-sm'
              onClick={handleDeactivateReject}
            >
              Ok
            </button>
          </div>
        </div>
      )}

      {!isLoadingDeactivate && !isAssetEnrolled && (
        <div className='flex flex-col items-center gap-6'>
          <h1 className='text-3xl font-bold text-center py-28'>
            {`Are you sure you want to unenroll "${activeProgram.programName}"?`}
          </h1>
          <div className='flex flex-col justify-center gap-4 md:flex-row'>
            <button
              className='rounded-md py-2 text-sm flex justify-center items-center'
              onClick={isLoadingDeactivate ? null : handleDeactivateConfirm}
            >
              {isLoadingDeactivateButton ? (
                <Loader classNames='w-5 h-5' />
              ) : (
                'Yes'
              )}
            </button>
            <button
              className='rounded-md py-2 text-sm'
              onClick={handleDeactivateReject}
            >
              No
            </button>
          </div>
        </div>
      )}
    </div>
  );

}