// Function to format timestamp to date, hour, and minute
export const formatTimestamp = (timestamp, offset) => {
  const date = new Date(timestamp);
  date.setUTCMinutes(date.getUTCMinutes() + offset);
  const formattedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '').substring(0, 16);
  return formattedDate;
};

// Function to transform and format the data
export const transformDataForCSVTables = (data, assetDifferenceFromUtc, type) => {
  if (type === 'charge') {
    return data.map(item => {
      const transformedItem = {
        'Event number': item.event_number,
        'Event type': item.event_type,
        'UTC time start': formatTimestamp(item.start_datetime, 0),
        'UTC time end': formatTimestamp(item.end_datetime, 0),
        'Asset time start': formatTimestamp(item.start_datetime, assetDifferenceFromUtc),
        'Asset time end': formatTimestamp(item.end_datetime, assetDifferenceFromUtc),
        'Power level': item.power_level,
        'Target SOC': item.target_soc,
        'Event source': item.source,
        'Created by': item.created_by,
        // 'Batch number': item.batch_number,
        // 'Asset id': item.asset_id,
        // 'Creation date (asset time)': formatTimestamp(item.creation_date, assetDifferenceFromUtc),
        // 'Event id': item.event_id,
        // 'State': item.state,
        // 'Is deleted': item.isDeleted,
      };

      return transformedItem;
    });
  }
  if (type === 'discharge') {
    return data.map(item => {
      const transformedItem = {
        'Mode': item.mode,
        'UTC time start': formatTimestamp(item.start_datetime, 0),
        'UTC time end': formatTimestamp(item.end_datetime, 0),
        'Asset time start': formatTimestamp(item.start_datetime, assetDifferenceFromUtc),
        'Asset time end': formatTimestamp(item.end_datetime, assetDifferenceFromUtc),
        'Power level': item.power_level,
        'Created by': item.created_by,
        'State': item.state,
        // 'Event source': item.source,
        // 'Batch number': item.batch_number,
        // 'Asset id': item.asset_id,
        // 'Creation date (asset time)': formatTimestamp(item.creation_date, assetDifferenceFromUtc),
        // 'Event type': item.event_type,
        // 'Event id': item.event_id,
        // 'Opted out': item.opted_out,
        // 'Optout able': item.optout_able,
        // 'Is deleted': item.isDeleted,
      };

      return transformedItem;
    });
  }
};