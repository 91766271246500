// React
import React from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user/userSelectors';
import userOperations from '../../../redux/user/userOperations';

// Components
import Loader from '../../Loader';

export default function UserDeactivate({
  toggleModal,
  activeUser,
  refetchData,
}) {
  const isLoadingDeactivate = useSelector(
    userSelectors.getUserDeactivateLoading
  );

  // dispatch
  const dispatch = useDispatch();

  // Handlers
  const handleDeactivateConfirm = () => {
    dispatch(
      userOperations.deactivateUser({
        toggleModal,
        userName: activeUser.userName,
        id: activeUser.accountID,
      })
    );

    const fetchNewData = () => {
      refetchData();
    };
    setTimeout(fetchNewData, 1000);
  };

  const handleDeactivateReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to deactivate "${activeUser.userName}"?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeactivateConfirm}
        >
          {isLoadingDeactivate ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeactivateReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
