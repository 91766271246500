import React, { useState } from 'react';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

export default function OptOutAssetEnbala({
  toggleModal,
  assetName,
  optOut,
  fetchAssetList,
}) {
  const [isLoading, setIsLoading] = useState(false);

  // Handlers
  const handleEnrollConfirm = async () => {
    setIsLoading(true);
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');
    try {
      const { data } = await api.post('/programs/opt-out-enbala', {
        assetName,
        optOut,
      }, {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.status === 'success') {
        setIsLoading(false);
        toggleModal();
        fetchAssetList();
        Notify.success(
          `The asset ${assetName} was successfully ${optOut ? "opted out" : "opted in"} to Enbala.`,
          notifyOptions,
        );
      }
    } catch (err) {
      Notify.failure(
        `The asset ${assetName} was not ${optOut ? "opted out" : "opted in"} to Enbala.`,
        notifyOptions,
      );
    }
    setIsLoading(false);
    toggleModal();
  };

  const handleEnrollReject = () => {
    toggleModal();
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-28'>
        {`Are you sure you want to ${optOut ? "opt out" : "opt in"} asset "${assetName}" to "Enbala"?`}
      </h1>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleEnrollConfirm}
        >
          {isLoading ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={handleEnrollReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
