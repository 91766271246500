// Turn widget IDs into human-readable titles
export const idToText = (str) => {
  if (str === 'treesSaved') {
    return 'Virtual Trees';
  }

  let result = str[0].toUpperCase();

  for (let i = 1; i < str.length; i++) {
    if (str[i].match(/[A-Z]/) && str[i - 1].match(/[a-z]/)) {
      result += ` ${str[i]}`;
    } else if (
      str[i].match(/[A-Z]/) &&
      str[i - 1].match(/[A-Z]/) &&
      str[i + 1] &&
      str[i + 1].match(/[a-z]/)
    ) {
      result += ` ${str[i]}`;
    } else {
      result += str[i];
    }
  }

  return result;
}