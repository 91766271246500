// React
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Switch from "react-switch";

// Redux
import { useDispatch, useSelector } from 'react-redux';
import dashboardDataOperations
  from '../../redux/dashboardData/dashboardDataOperation';
import {
  dashboardDataSelectors,
} from '../../redux/dashboardData/dashboardDataSelectors';

// Components
import AssetDetailsInputField from '../assetDetails/AssetDetailsInputField';
import Loader from '../Loader';

// Helpers
import getUtcOffsetDifference from '../../helpers/formatDate/convertCoordinatesToUtcOffset';

// Access
import accessControl from '../../helpers/accessControl';

// Assets
import { FiX } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { BsInfoCircle } from "react-icons/bs";
import Modal from './Modal';
import formatDateToGMT from '../../helpers/formatDate/formatDateToGMT';

import api from '../../api/axios.config';
import EnrollAssetToProgram from './programs/EnrollAssetToProgram';
import UnenrollAssetFromProgram from './programs/UnenrollAssetFromProgram';
import BessInformation from './bessInfo/BessInformation';

const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

function convertDate(inputDate) {
  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const AssetDetailsForm = ({
  assetData = {},
  accounts = [],
  toggleAssetDetails,
  getDashboardData,
}) => {
  const { assetDetails, addressData } = useSelector(
    dashboardDataSelectors.getCurrentAsset,
  );

  const currentAssetLoading = useSelector(
    dashboardDataSelectors.getCurrentAssetLoading,
  );

  const getProgramList = async () => {
    const { data } = await api.get('/programs', {
      headers: {
        username: username,
        Authorization: `Bearer ${token}`,
      },
    });

    setPrograms(data.result);
  };

  useEffect(() => {
    getProgramList();
  }, []);

  // Asset difference from UTC in minutes
  const assetDifferenceFromUtc = getUtcOffsetDifference(assetDetails?.gpsCoordinates?.latitude, assetDetails?.gpsCoordinates?.longitude);

  const [formData, setFormData] = useState({ ...assetDetails, ...addressData });
  const [programs, setPrograms] = useState([]);
  const [showEnrollProgramModal, setShowEnrollProgramModal] = useState(false);
  const [showUnenrollProgramModal, setShowUnenrollProgramModal] = useState(false);
  const [activeProgram, setActiveProgram] = useState(null);
  const [lowSOCCutOffVoltageError, setLowSOCCutOffVoltageError] = useState('');
  const [isShowBessInfo, setIsShowBessInfo] = useState(false);

  const toggleEnrollModal = () => {
    setShowEnrollProgramModal(!showEnrollProgramModal);
  };

  const toggleUnenrollModal = () => {
    setShowUnenrollProgramModal(!showUnenrollProgramModal);
  };

  const toggleBessInfoModal = () => {
    setIsShowBessInfo(!isShowBessInfo);
  };

  const dispatch = useDispatch();
  useLayoutEffect(() => {
    dispatch(
      dashboardDataOperations.getCurrentAsset({ assetName: assetData?.name }),
    );
  }, [dispatch, assetData.name]);

  useEffect(() => {
    setFormData({ ...assetDetails, ...addressData });
  }, [addressData, assetDetails]);

  const handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'installDate') {
      const date = new Date(Date.parse(event.target.valueAsDate));
      const formattedDate = date.toUTCString();

      setFormData({
        ...formData,
        [name]: formattedDate,
      });
    }

    if (name === 'latitude' || name === 'longitude') {
      setFormData(prev => ({
        ...prev,
        gpsCoordinates: {
          ...prev.gpsCoordinates,
          [name]: value,
        },
      }));

      return;
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleDPS = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      enableDPS: !prevFormData.enableDPS,
    }));
  };

  const toggleBESS = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      enableBESS: !prevFormData.enableBESS,
    }));
  };

  const toggleAlwaysCharge = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      alwaysCharge: !prevFormData.alwaysCharge,
    }));
  };

  const enrollProgram = (key) => {
    // Enroll program logic
    const program = programs.find(p => p.programKey === key);
    setActiveProgram(program);
    setShowEnrollProgramModal(true);
  };

  const unenrollProgram = (key) => {
    const program = programs.find(p => p.programKey === key);
    setActiveProgram(program);
    setShowUnenrollProgramModal(true);
  };

  const submitForm = async () => {
    const dateForTWX = formatDateToGMT(formData.installDate);
    const newFormData = {
      siteDescription: formData.siteDescription,
      streetAddressEH: formData.streetAddressEH,
      locationIdentifier: formData.locationIdentifier,
      cityEH: formData.cityEH,
      stateEH: formData.stateEH,
      postalCodeEH: formData.postalCodeEH,
      countryEH: formData.countryEH,
      gpsCoordinates: {
        latitude: formData?.gpsCoordinates?.latitude,
        longitude: formData?.gpsCoordinates?.longitude,
      },
      installDate: dateForTWX,
      poNumber: formData.poNumber,
      assetContactFirstName: formData.assetContactFirstName,
      assetContactLastName: formData.assetContactLastName,
      assetContactPhone: formData.assetContactPhone,
      assetContactEmail: formData.assetContactEmail,
      assetContactRole: formData.assetContactRole,
      programTags: formData.programTags,
      fleetTags: formData.fleetTags,
      cadenzaTags: formData.cadenzaTags,
      notes: formData.notes,
      accountID: formData.accountID,
      modelNumber: formData.modelNumber,
      bessName: formData.bessName,
      assetName: formData.name,
      asset: formData.name,
      gatewaySerialNumber: formData.gatewaySerialNumber,
      maximumCapacity: formData.MaxCapacityKWh,
      dischargeCapacity: formData.dischargeCapacity,
      chargeCapacity: formData.chargeCapacity,
      utilityAccountNumberEH: formData.utilityAccountNumberEH,
      lowSOCCutOffVoltage: formData.lowSOCCutOffVoltage,
      deviceType: formData.DeviceType,
      manufacturer: formData.Manufacturer,
      model: formData.model,
      sourceAccountNumber: formData.accountOwningID,
      enableDPS: formData.enableDPS,
      timeIntervalForCyclicDPS: formData.timeIntervalForCyclicDPS,
      timeIntervalForMeterDPS: formData.timeIntervalForMeterDPS,
      idealPower: formData.idealPower,
      offSet: assetDifferenceFromUtc,
      enableBESS: formData.enableBESS,
      alwaysCharge: formData.alwaysCharge,
      alwaysChargePower: formData.alwaysChargePower,
    };

    // Check if lowSOCCutOffVoltage is empty
    if (!!!newFormData.lowSOCCutOffVoltage) {
      setLowSOCCutOffVoltageError('"Low state of charge cutoff voltage (V)" is required');
      return;
    } else if (newFormData.lowSOCCutOffVoltage < 40 || newFormData.lowSOCCutOffVoltage > 55) {
      setLowSOCCutOffVoltageError('"Low state of charge cutoff voltage (V)" should be in the range of 40-55');
      return;
    } else {
      setLowSOCCutOffVoltageError('');
    }

    dispatch(
      dashboardDataOperations.updateCurrentAsset({ formData: newFormData }),
    );

    setTimeout(getDashboardData, 1000);
  };

  const filteredPrograms = programs.filter(program => program.state === formData.stateEH);

  return (
    <div
      className='modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer'>
      <div
        className='container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto'
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <header className='py-7 sticky top-0 bg-white z-50 drop-shadow'>
          <span
            className='absolute top-6 right-4'
            type='button'
            role='button'
            onClick={() => toggleAssetDetails()}
          >
            <FiX size={28} />
          </span>
          <h1 className='text-2xl font-bold text-center'>Edit {assetData.name ? assetData.name : "Asset"} Details</h1>
        </header>
        {currentAssetLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <div className='p-6 flex flex-col justify-between'>
            <div className='block md:grid md:grid-cols-2 lg:grid-cols-3 gap-8'>
              <div className='edit-container mb-4 md:mb-0 md:row-span-2'>
                <h2 className='text-lg font-bold mb-4'>Asset Properties</h2>
                <div>
                  <AssetDetailsInputField
                    title='Site Description'
                    access={accessControl.isAdministrator()}
                    type='text'
                    name='siteDescription'
                    placeholder='Site Description'
                    inputValue={formData.siteDescription}
                    onChange={handleChange}
                    inputClassName='mt-3 mb-2 rounded-md w-full py-1 px-2'
                  />

                  <h3 className='mt-6 mb-4'>Address</h3>
                  <div className='pl-4'>
                    <AssetDetailsInputField
                      title='Street Address'
                      type='text'
                      name='streetAddressEH'
                      inputValue={`${formData.streetAddressEH}`}
                      placeholder='Address'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='Location Identifier'
                      type='text'
                      name='locationIdentifier'
                      inputValue={`${formData.locationIdentifier}`}
                      placeholder='Room/Apartment/Section/Floor...'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='City'
                      type='text'
                      name='cityEH'
                      inputValue={`${formData.cityEH}`}
                      placeholder='City'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='State'
                      type='text'
                      name='stateEH'
                      inputValue={`${formData.stateEH}`}
                      placeholder='State'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='Postal Code'
                      type='text'
                      name='postalCodeEH'
                      inputValue={`${formData.postalCodeEH}`}
                      placeholder='Postal Code'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='Country'
                      type='text'
                      name='countryEH'
                      inputValue={`${formData.countryEH}`}
                      placeholder='Country'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                  </div>

                  <h3 className='mt-4 mb-4'>GPS Coordinates</h3>
                  <div className='pl-4'>
                    <AssetDetailsInputField
                      title='Latitude'
                      type='text'
                      name='latitude'
                      inputValue={`${formData?.gpsCoordinates?.latitude}`}
                      placeholder='0'
                      access={accessControl.isAdministrator()}
                      inputClassName='mt-3 mb-4 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                    <AssetDetailsInputField
                      title='Longitude'
                      type='text'
                      name='longitude'
                      inputValue={`${formData?.gpsCoordinates?.longitude}`}
                      placeholder='0'
                      access={accessControl.isAdministrator()}
                      inputClassName='my-3 rounded-md w-full py-1 px-2'
                      titleClassName=' '
                      onChange={handleChange}
                    />
                  </div>
                  <AssetDetailsInputField
                    title='Installation Date'
                    type='date'
                    name='installDate'
                    inputValue={convertDate(formData.installDate)}
                    access={accessControl.isAdministrator()}
                    onChange={handleChange}
                    inputClassName='mt-2 mb-3 rounded-md w-full py-1 px-2'
                  />
                  <AssetDetailsInputField
                    title='P.O. Number'
                    type='text'
                    name='poNumber'
                    inputValue={formData.poNumber}
                    placeholder='000000000'
                    access={accessControl.isAdministrator()}
                    onChange={handleChange}
                    inputClassName='mt-2 mb-3 rounded-md w-full py-1 px-2'
                  />
                </div>
              </div>
              <div className='md:col-span-2'>
                <div className='md:grid md:grid-cols-2 gap-8'>
                  <div className='edit-container mb-4 md:mb-0'>
                    <h2 className='text-lg font-bold'
                    >Asset Contact</h2>
                    <div>
                      <AssetDetailsInputField
                        title='First Name'
                        type='text'
                        name='assetContactFirstName'
                        inputValue={formData.assetContactFirstName}
                        placeholder='First Name'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Last Name'
                        type='text'
                        name='assetContactLastName'
                        inputValue={formData.assetContactLastName}
                        placeholder='Last Name'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Phone'
                        type='tel'
                        name='assetContactPhone'
                        inputValue={formData.assetContactPhone}
                        placeholder='1-555-555-5555'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Email'
                        type='email'
                        name='assetContactEmail'
                        inputValue={formData.assetContactEmail}
                        placeholder='hello@email.com'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Title'
                        type='text'
                        name='assetContactRole'
                        inputValue={formData.assetContactRole}
                        placeholder='Title'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className='edit-container mb-4 md:mb-0'>
                    <h2 className='text-lg font-bold mb-4'>Filtering Tags</h2>
                    <div>
                      <AssetDetailsInputField
                        title='Programs'
                        type='text'
                        name='programTags'
                        inputValue={formData.programTags}
                        placeholder='Programs'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Fleets'
                        type='text'
                        name='fleetTags'
                        inputValue={formData.fleetTags}
                        placeholder='Fleets'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Tags'
                        type='text'
                        name='cadenzaTags'
                        inputValue={formData.cadenzaTags}
                        placeholder='Tags'
                        access={accessControl.isAdministrator()}
                        onChange={handleChange}
                      />

                      {accessControl.isResident() ||
                        accessControl.isClientUser() ? (
                        <>
                          <span className='asset-title-data block mt-4 mb-1'>
                            Notes
                          </span>
                          <p className='asset-data'>
                            {formData.notes || 'No Data Available'}
                          </p>
                        </>
                      ) : (
                        <>
                          <h3 className='mt-4 mb-2'>Notes</h3>
                          <textarea
                            type='text'
                            rows='4'
                            name='notes'
                            className='rounded-md w-full py-1 px-2'
                            placeholder='Notes'
                            value={`${formData.notes || ''}`}
                            onChange={handleChange}
                          ></textarea>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className='edit-container mt-6'>
                  <div className='flex items-center gap-1'>
                    <h2 className='text-lg font-bold'>
                      BESS Information
                    </h2>
                    {accessControl.isPlatformAdministrator() &&
                      <IconContext.Provider value={{ color: '#00000' }}>
                        <BsInfoCircle
                          size={22}
                          onClick={toggleBessInfoModal}
                          title='Show BESS information'
                          cursor='pointer'
                        />
                      </IconContext.Provider>
                    }
                  </div>
                  <div className='md:grid md:grid-cols-2 gap-6'>
                    <div>
                      <h3 className='mt-2 mb-2 '>Account Name</h3>

                      {accessControl.isResident() ||
                        accessControl.isClientUser() ? (
                        <p>{formData.accountName || ''}</p>
                      ) : (
                        <select
                          name='accountID'
                          className='rounded-md w-full py-1 px-2'
                          onChange={handleChange}
                          defaultValue={formData.accountOwningID}
                        >
                          <option value=''>Unassigned asset</option>
                          {accounts.map(account => {
                            return (
                              <option
                                key={account.accountID}
                                value={account.accountID}
                              >
                                {account.accountName}
                              </option>
                            );
                          })}
                        </select>
                      )}

                      <h3 className='mt-4 mb-2 '>BESS Serial Number</h3>
                      <p style={
                        { color: 'black' }
                      }>{assetData.name || 'No Data Available'}</p>

                      <h3 className='mt-4 mb-2'>
                        Cadenza Model Number
                      </h3>
                      {accessControl.isPlatformAdministrator() ? (
                        <div className='relative'>
                          <input
                            type='text'
                            name='modelNumber'
                            className='rounded-md w-full py-1 px-2'
                            placeholder='Cadenza Model Number'
                            value={`${formData.modelNumber || ''}`}
                            onChange={handleChange}
                          />
                          {assetData.modelNumber !== formData.modelNumber && (
                            <div
                              className='color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs'>
                              Warning! Changing this field is dangerous.
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>{formData.modelNumber || ''}</p>
                      )}

                      <div className='mt-4'>
                        <AssetDetailsInputField
                          title='Low state of charge cutoff voltage (V)'
                          access={accessControl.isPlatformAdministrator()}
                          type='text'
                          name='lowSOCCutOffVoltage'
                          inputValue={formData.lowSOCCutOffVoltage}
                          titleClassName='block mb-1 text-gray-600'
                          inputClassName={`rounded-md w-full py-1 px-2 ${accessControl.isPlatformAdministrator() ? '' : 'bg-gray-200'}`}
                          onChange={handleChange}
                        />
                        {lowSOCCutOffVoltageError && (
                          <div className="color-warning text-xs">
                            {lowSOCCutOffVoltageError}
                          </div>
                        )}
                      </div>
                      <div className='edit-container flex gap-8 mt-6'>
                        <div className='flex flex-col'>
                          <h2 className='text-lg font-bold mb-2'>Always charge</h2>
                          <Switch
                            checked={formData.alwaysCharge}
                            onChange={toggleAlwaysCharge}
                            disabled={!accessControl.isAdministrator()}
                            onColor="#a2c7ee"
                            offColor='#f1f0ea'
                            onHandleColor="#0f3052"
                            offHandleColor='#f1f0ea'
                            handleDiameter={25}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={25}
                            width={60}
                            id="toggleAlwaysCharge"
                            uncheckedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 16,
                                  color: "#0f3052",
                                  fontWeight: 800,
                                }}
                              >
                                Off
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 15,
                                  color: "#0f3052",
                                  fontWeight: 800,
                                }}
                              >
                                On
                              </div>
                            }
                          />
                        </div>
                        <div className=''>
                          <AssetDetailsInputField
                            title='Charge power (kW)'
                            access={(accessControl.isAdministrator())}
                            type='text'
                            name='alwaysChargePower'
                            inputValue={formData.alwaysChargePower}
                            titleClassName='block mb-1 text-gray-600'
                            inputClassName={`rounded-md w-full py-1 px-2 ${accessControl.isAdministrator()
                              ? '' : 'bg-gray-200'}`}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                    </div>
                    <div>
                      <h3 className='mt-2 mb-2'>Asset Name</h3>
                      {accessControl.isPlatformAdministrator() ? (
                        <div className='relative'>
                          <input
                            type='text'
                            name='bessName'
                            className='rounded-md w-full py-1 px-2'
                            placeholder='Asset Name'
                            value={`${formData.bessName || ''}`}
                            onChange={handleChange}
                          />
                          {assetData.bessName !== formData.bessName && (
                            <div
                              className='color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs'>
                              Warning! Changing this field is dangerous.
                            </div>
                          )}
                        </div>
                      ) : (
                        <p>{formData.bessName || ''}</p>
                      )}

                      <h3 className='mt-4 mb-2 '>Asset Status</h3>
                      <p style={
                        { color: 'black' }
                      }>{assetData.status || ''}</p>

                      <h3 className='mt-4 mb-2 '>Gateway SN</h3>
                      {accessControl.isPlatformAdministrator() ? (
                        <div className='relative'>
                          <input
                            type='text'
                            name='gatewaySerialNumber'
                            className='rounded-md w-full py-1 px-2'
                            placeholder='Gateway SN'
                            value={`${formData.gatewaySerialNumber || ''}`}
                            onChange={handleChange}
                          />
                          {assetData.gatewaySerialNumber !==
                            formData.gatewaySerialNumber && (
                              <div
                                className='color-warning absolute left-0 bottom-[-18px] text-xs lg:text-[10px] xl:text-xs'>
                                Warning! Changing this field is dangerous.
                              </div>
                            )}
                        </div>
                      ) : (
                        <p>{formData.gatewaySerialNumber || ''}</p>
                      )}

                      {accessControl.isPlatformAdministrator() &&
                        <div className='flex items-center mt-12'>
                          <h2 className='text-lg font-bold mr-4'>Enable BESS Dashboard</h2>
                          <Switch
                            checked={formData.enableBESS}
                            onChange={toggleBESS}
                            disabled={!accessControl.isPlatformAdministrator()}
                            onColor="#a2c7ee"
                            offColor='#f1f0ea'
                            onHandleColor="#0f3052"
                            offHandleColor='#f1f0ea'
                            handleDiameter={25}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={25}
                            width={60}
                            id="toggleBESS"
                            uncheckedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 16,
                                  color: "#0f3052",
                                  fontWeight: 800,
                                }}
                              >
                                Off
                              </div>
                            }
                            checkedIcon={
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "100%",
                                  fontSize: 15,
                                  color: "#0f3052",
                                  fontWeight: 800,
                                }}
                              >
                                On
                              </div>
                            }
                          />
                        </div>
                      }
                    </div>
                  </div>

                  <div className='edit-container mt-6'>
                    <h2 className='text-lg font-bold'>Demand Response</h2>
                    <div className='flex flex-wrap md:flex-nowrap justify-between gap-6 mt-2'>
                      <AssetDetailsInputField
                        title='Service Account Number'
                        access={accessControl.isPlatformAdministrator()}
                        type='text'
                        name='utilityAccountNumberEH'
                        inputValue={formData.utilityAccountNumberEH}
                        titleClassName='block mb-1 text-gray-600'
                        inputClassName={`rounded-md w-9/10 py-1 px-2 ${accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Storage Capacity (kWh)'
                        access={accessControl.isPlatformAdministrator()}
                        type='text'
                        name='MaxCapacityKWh'
                        inputValue={formData.MaxCapacityKWh}
                        titleClassName='block mb-1 text-gray-600'
                        inputClassName={`rounded-md w-9/10 py-1 px-2 ${accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Charge Capacity (kW)'
                        access={accessControl.isPlatformAdministrator()}
                        type='text'
                        name='chargeCapacity'
                        inputValue={formData.chargeCapacity}
                        titleClassName='block mb-1 text-gray-600'
                        inputClassName={`rounded-md w-9/10 py-1 px-2 ${accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Discharge Capacity (kW)'
                        access={accessControl.isPlatformAdministrator()}
                        type='text'
                        name='dischargeCapacity'
                        inputValue={formData.dischargeCapacity}
                        titleClassName='block mb-1 text-gray-600'
                        inputClassName={`rounded-md w-9/10 py-1 px-2 ${accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className='edit-container mt-6'>
                    <div className='flex items-center'>
                      <h2 className='text-lg font-bold mr-4'>Dynamic Peak Shaving</h2>
                      <Switch
                        checked={formData.enableDPS}
                        onChange={toggleDPS}
                        disabled={!accessControl.isAdministrator()}
                        onColor="#a2c7ee"
                        offColor='#f1f0ea'
                        onHandleColor="#0f3052"
                        offHandleColor='#f1f0ea'
                        handleDiameter={25}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={25}
                        width={60}
                        id="toggleDPS"
                        uncheckedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 16,
                              color: "#0f3052",
                              fontWeight: 800,
                            }}
                          >
                            Off
                          </div>
                        }
                        checkedIcon={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 15,
                              color: "#0f3052",
                              fontWeight: 800,
                            }}
                          >
                            On
                          </div>
                        }
                      />
                    </div>
                    <div className='flex flex-wrap md:flex-nowrap start gap-6 mt-2'>
                      <AssetDetailsInputField
                        title='DPS Scan time (min)'
                        access={(accessControl.isPlatformAdministrator() && formData.enableDPS)}
                        type='text'
                        name='timeIntervalForCyclicDPS'
                        inputValue={formData.timeIntervalForCyclicDPS}
                        titleClassName='block mb-1 text-gray-600'
                        className={`rounded-md w-9/10 py-1 px-2 ${formData.enableDPS && accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                      <AssetDetailsInputField
                        title='Power meter scan time (min)'
                        access={(accessControl.isPlatformAdministrator() && formData.enableDPS)}
                        type='text'
                        name='timeIntervalForMeterDPS'
                        inputValue={formData.timeIntervalForMeterDPS}
                        titleClassName='block mb-1 ml-10 text-gray-600'
                        inputClassName={`rounded-md ml-10 w-9/10 py-1 px-2 ${formData.enableDPS && accessControl.isPlatformAdministrator()
                          ? '' : 'bg-gray-200'}`}
                        onChange={handleChange}
                      />
                      <div>
                        <AssetDetailsInputField
                          title='Ideal Power (kW)'
                          access={(accessControl.isAdministrator() && formData.enableDPS)}
                          type='text'
                          name='idealPower'
                          inputValue={formData.idealPower}
                          titleClassName='block mb-1 ml-8 text-gray-600'
                          inputClassName={`rounded-md ml-8 w-9/10 py-1 px-2 ${formData.enableDPS && accessControl.isAdministrator()
                            ? '' : 'bg-gray-200'}`}
                          onChange={handleChange}
                        />
                        {Number(assetData.idealPower) !==
                          Number(formData.idealPower) && (
                            <div
                              className='color-warning ml-8 text-xs lg:text-[10px] xl:text-xs'>
                              Warning! Ideal power change will affect the demand charges
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <div className='flex justify-end flex-col md:flex-row'>
              <div className='hidden  flex-col w-ful md:flex-row md:w-auto'>
                <button
                  className='rounded-md px-4 py-2 md:mr-4 text-sm transition-all mb-4 md:mb-0'>
                  DR Events
                </button>
                <button
                  className='rounded-md px-4 py-2 text-sm transition-all mb-4 md:mb-0'>
                  DR Enrollment
                </button>
              </div>
              {accessControl.isAdministrator() && (
                <button
                  className='rounded-md px-4 py-2 text-sm mb-4 md:mb-0'
                  onClick={submitForm}
                >
                  Update Asset Details
                </button>
              )}
            </div>

            <div className='flex flex-col justify-end mt-6'>
              <h2 className='text-lg font-bold mb-4'>Demand Response Programs:</h2>
              <div
                className='table-container'
                style={programs.length > 4 ? {
                  maxHeight: 'calc(5 * 35px)',
                  overflowY: 'auto',
                } : {}}
              >
                <table
                  className='min-w-full bg-white text-center border border-black'>
                  <thead>
                    <tr>
                      <th className='py-2'>ID</th>
                      <th className='py-2'>Program Name</th>
                      <th className='py-2'>Utility Name</th>
                      <th className='py-2'>State</th>
                      <th className='py-2'>Program Type</th>
                      <th className='py-2'>Enrollment Status</th>
                      {accessControl.isPlatformAdministrator() &&
                        <th className='py-2'></th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {filteredPrograms.map((program) => (
                      <tr key={program.programKey}>
                        <td>{program.programKey}</td>
                        <td>{program.programName}</td>
                        <td>{program.utilityName}</td>
                        <td>{program.state}</td>
                        <td>{program.programType}</td>
                        <td>{program.status}</td>
                        {accessControl.isPlatformAdministrator() &&
                          <td>
                            <button
                              style={{ minWidth: '6vw', minHeight: '2rem' }}
                              className='rounded-md px-2 py-1 text-xs mr-10'
                              onClick={() => enrollProgram(program.programKey)}
                            >
                              Enroll
                            </button>
                            <button
                              style={{ minWidth: '6vw', minHeight: '2rem' }}
                              className='rounded-md px-2 py-1 text-xs mr-0'
                              onClick={() => unenrollProgram(program.programKey)}
                            >
                              Unenroll
                            </button>
                          </td>
                        }
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      {showEnrollProgramModal && activeProgram && (
        <Modal
          toggleModal={toggleEnrollModal}
          isSmall={true}
          child={
            <EnrollAssetToProgram
              activeAssets={[formData.assetName]}
              activeProgram={activeProgram}
              toggleModal={toggleEnrollModal}
            />
          }
        />
      )}

      {showUnenrollProgramModal && activeProgram && (
        <Modal
          toggleModal={toggleUnenrollModal}
          isSmall={true}
          child={
            <UnenrollAssetFromProgram
              activeAssets={[formData.assetName]}
              activeProgram={activeProgram}
              toggleModal={toggleUnenrollModal}
            />
          }
        />
      )}

      {isShowBessInfo && (
        <Modal
          toggleModal={toggleBessInfoModal}
          isSmall={true}
          child={
            <BessInformation
              assetName={formData.assetName}
              assetDetails={assetDetails}
            />
          }
        />
      )}

    </div>
  );
};

export default AssetDetailsForm;
