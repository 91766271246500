const getAssetStatus = (errorState) => {
  if (errorState === 'ok') {
    return 'Healthy';
  } else if (errorState === 'warning') {
    return 'Warning';
  } else if (errorState === 'faults' || errorState === "4 - Not all modbus devices are communicating") {
    return 'Fault';
  }
};

export default getAssetStatus;
