// React
import React from 'react';

// Components
import Loader from '../../Loader';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// api
import api from '../../../api/axios.config';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

export default function DeleteBatteryModule({ 
  toggleModal, 
  selectedBatteryModule,
  assetName,
  fetchBatteryModules
}) {

  // Create battery module
  const deleteBatteryModule = () => {
    api.post(
      `/device/battery-module/delete`,
      {
        assetName,
        module_id: selectedBatteryModule.module_id,
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(response => {
        if (response.data.status === 'success') {
          Notify.success(
            `Battery module was deleted successfully`,
            notifyOptions,
          );
          fetchBatteryModules();
        }
      })
      .catch(error => {
        console.error('Error deleting battery module:', error);
        Notify.failure(
          `There was an error deleting the battery module`,
          notifyOptions,
        );
      });
  }

  // Handlers
  const handleDeleteConfirm = () => {
    deleteBatteryModule();
    toggleModal();
  };

  const handleDeactivateReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to delete "${selectedBatteryModule.serial_number}"?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeleteConfirm}
        >
          {false ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeactivateReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
