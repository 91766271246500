import * as Yup from 'yup';

const UserFormValidationSchema = Yup.object().shape({
  accountType: Yup.string().required('Account Type is required'),
  accountID: Yup.string().required('Account Name is required'),
  userSubtype: Yup.string().required('User Type is required'),
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]*$/, 'Invalid phone number')
    .required('Phone Number is required'),
  title: Yup.string().required('Title/Role is required'),
  userName: Yup.string().required('Username is required'),
});

export default UserFormValidationSchema;
