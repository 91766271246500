import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { FaMinus } from 'react-icons/fa';

function ProgramRow({
  program,
  toggleModal,
  assignActiveProgram,
  toggleEditModal,
  toggleDeactivateModal,
  toggleEnrollmentModal,
  toggleEnrollmentModalEnbala,
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <tr
      className='border-b'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td
        className='pl-2 md:pl-4 cursor-pointer flex items-center justify-center ali gap-4 h-12 program-row-actions' // Centered icons within the column
        onClick={() => {
          assignActiveProgram(program.programKey);
        }}
      >
        <div>
          <FiEdit
            className={`inline-flex self-center mr-2 ${isHovered
              ? null
              : 'md:invisible'}`}
            size={20}
            onClick={() => {
              toggleEditModal();
            }}
          />
          <FaMinus
            className={`inline-flex self-center ${isHovered
              ? null
              : 'md:invisible'}`}
            size={20}
            onClick={() => {
              toggleDeactivateModal();
            }}
          />
        </div>
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.programKey}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.programName}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.programID}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.utilityName}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.state}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.programType}
      </td>
      <td
        className='pr-2 py-2 cursor-pointer'
        onClick={() => {
          assignActiveProgram(program.programKey);
          toggleModal();
        }}
      >
        {program.aggregator}
      </td>
      <td>
        <button
          style={{ minWidth: '7vw', minHeight: '2rem' }}
          className='rounded-md px-2 py-2 text-sm table-button'
          onClick={() => {
            assignActiveProgram(program.programKey);
            if(program.aggregator === 'Enbala'){
              toggleEnrollmentModalEnbala()
            } else {
              toggleEnrollmentModal();
            }
          }}
        >
          Enrollments
        </button>
      </td>
    </tr>
  );
}

export default ProgramRow;