import React, { useState, useRef, useEffect } from 'react';

function SearchFilter ({ params, initialData, updateData }) {
  const query = useRef(null);

  // format param text to not have hyphens
  const formattedParams = params.map(param => {
    return param.split('-').join('');
  });

  // set initial params for checkboxes
  let initialParams = {};
  params.forEach(param => {
    initialParams[param] = true;
  });

  // Set initial state
  const [state, setState] = useState({
    data: initialData,
    params: formattedParams,
    query: '',
    checked: initialParams,
    results: [],
  });
  const [newData, setNewData] = useState(initialData);

  // handle when user checks or unchecks a param to search by
  const handleCheckedChange = param => {
    const newState = state;
    newState.checked[param] = !newState.checked[param];
    setState({
      ...newState,
    });
  };

  // handle the search
  const search = () => {
    if (state.data.length < 1) return;
    const queryText = query.current.value;

    if (queryText === '') {
      updateData(state.data);
      return;
    }

    const formattedQuery = queryText.toLowerCase();

    // Get an array of checked params
    const checkedParams = [];
    Object.keys(state.checked).forEach(key => {
      if (state.checked[key] === true) {
        checkedParams.push(key.split('-').join(''));
      }
    });

    const filteredData = [];

    state.data.filter(item => {
      return checkedParams.some(param => {
        if (param === 'programID') {
          if (item['programKey'] &&
            item['programKey'].toString().includes(formattedQuery)) {
            if (!filteredData.includes(item)) filteredData.push(item);
            return true;
          }
        } else if (item[param] && typeof item[param] === 'string') {
          if (item[param].toLowerCase().includes(formattedQuery)) {
            if (!filteredData.includes(item)) filteredData.push(item);
            return true;
          }
        }
        return false;
      });
    });

    setNewData(filteredData);
  };

  useEffect(() => {
    if (state.query === '') {
      updateData(state.data);
    }

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    updateData(newData);

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newData]);

  return (
    <form
      className='flex flex-col gap-2 search-filter-form'
      onSubmit={e => {
        e.preventDefault();
      }}
    >
      <label htmlFor='value' className='color-primary font-medium mt-1'>
        Value
      </label>
      <input
        type='text'
        name='value'
        className='rounded-md w-full py-1 px-2'
        placeholder='Value'
        ref={query}
        onChange={e => {
          e.preventDefault();
          search();
        }}
      />
      <fieldset className='flex flex-col mt-1'>
        <legend className='color-primary font-medium'>Filter by</legend>
        {params.map((param) => {
          return (
            <div className='flex items-center' key={param.split('-').join('')}>
              <input
                type='checkbox'
                className='mr-3 my-2 w-5 h-5 text-orange-400 bg-white-100 rounded border-gray-500 focus:ring-orange-400'
                id={param.split('-').join('')}
                name={param.split('-').join('')}
                checked={state.checked[param]}
                onChange={() => handleCheckedChange(param)}
              />
              <label htmlFor={param.split('-').join('')} className='capitalize'>
                {param.replace(/-/g, ' ')}
              </label>
            </div>
          );
        })}
      </fieldset>
    </form>
  );
}

export default SearchFilter;
