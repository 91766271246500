import React from 'react';

export default function ProfileInputField({ title, ...props }) {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="mt-5 font-bold">{title}</h3>
      <input {...props} className="rounded-md w-full py-1 px-2 max-w-md" />
    </div>
  );
}
