// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';

// Notify
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';

// Helpers
import { axiosBaseUrl } from '../tokenSettingsAxios';

// Charging events
const addChargingEvent = createAsyncThunk(
  'assetRealTimeData/addChargingEvent',
  async ({ bodyRequest, toggleModal }, { rejectWithValue }) => {
    try {
      // add charging event
      const result = await axiosBaseUrl.post('device/charging-events/create', bodyRequest);

      toggleModal();

      if (result.status === 200) {
        Notify.success('Charging event successfully added!', notifyOptions);
      }
      if (result.status === 500) {
        Notify.failure('Failed to create Charging Event', notifyOptions);
      }
    } catch (e) {
      Notify.failure('Failed to create Charging Event. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const deleteChargingEvent = createAsyncThunk(
  'assetRealTimeData/deleteChargingEvent',
  async ({ toggleModal, bodyRequest }, { rejectWithValue }) => {
    try {
      // delete charging event
      await axiosBaseUrl.post(`device/charging-events/delete`, bodyRequest);

      toggleModal();
      Notify.success('Charging event successfully deleted!', notifyOptions);
    } catch (e) {
      Notify.failure('Failed to delete Charging Event. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const getChargingEvents = createAsyncThunk(
  'assetRealTimeData/getChargingEvents',
  async ({ bodyRequest }, { rejectWithValue }) => {
    try {
      // get all charging events
      const { data } = await axiosBaseUrl.post('device/charging-events/get', bodyRequest);

      return data.eventListData.eventList;
    } catch (e) {
      Notify.failure('Failed to fetch Charging Events data. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

// Discharging events
const addDischargingEvent = createAsyncThunk(
  'assetRealTimeData/addDischargingEvent',
  async ({ bodyRequest, toggleModal }, { rejectWithValue }) => {
    try {
      // add discharging event
      const result = await axiosBaseUrl.post('device/discharging-events/create', bodyRequest);

      toggleModal();

      if (result.status === 200) {
        Notify.success('Discharging event successfully added!', notifyOptions);
      }
      if (result.status === 500) {
        Notify.failure('Failed to create Discharging Event', notifyOptions);
      }
    } catch (e) {
      Notify.failure('Failed to create Discharging Event. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const deleteDischargingEvent = createAsyncThunk(
  'assetRealTimeData/deleteDischargingEvent',
  async ({ toggleModal, bodyRequest }, { rejectWithValue }) => {
    try {
      // delete discharging event
      await axiosBaseUrl.post(`device/discharging-events/delete`, bodyRequest);

      toggleModal();
      Notify.success('Discharging event successfully deleted!', notifyOptions);
    } catch (e) {
      Notify.failure('Failed to delete Discharging Event. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const getDischargingEvents = createAsyncThunk(
  'assetRealTimeData/getDischargingEvents',
  async ({ bodyRequest }, { rejectWithValue }) => {
    try {
      // get all discharging events
      const { data } = await axiosBaseUrl.post('device/discharging-events/get', bodyRequest);

      return data.eventListData.eventList;
    } catch (e) {
      Notify.failure('Failed to fetch Discharging Events data. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const assetRealTimeDataOperations = {
  // charging events
  addChargingEvent,
  deleteChargingEvent,
  getChargingEvents,

  // discharging events
  addDischargingEvent,
  deleteDischargingEvent,
  getDischargingEvents,
};

export default assetRealTimeDataOperations;