import React, { useEffect, useState, useCallback } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Dot,
  ReferenceArea,
} from 'recharts';
import { IconContext } from 'react-icons';
import { FiZoomOut } from "react-icons/fi";
import { PiFileCsv, PiFilePng } from "react-icons/pi";
import formatRangeForFileName from '../../helpers/formatDate/formatRangeForFileName';
import { transformDataForCSV } from '../../helpers/formatDate/transformDataForCSV';
import { roundToNearestTwoDecimal } from '../../helpers/charts/roundToDecimal';
import { getTicks, convertToDays, tickFormatterToAssetTime } from '../../helpers/charts';
import api from '../../api/axios.config';
import notifyOptions from '../../constants/notify.options';
import { Notify } from 'notiflix';
import { CSVLink } from "react-csv";
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';

function PoweSoc(
  {
    assetName,
    powerSocStartDate,
    powerSocEndDate,
    powerSoc = [],
    assetTimezone,
    assetDifferenceFromUtc,
  },
) {
  const [powerSocData, setPowerSocData] = useState(powerSoc);

  const [getPng, { ref }] = useCurrentPng();

  const [zoomedData, setZoomedData] = useState([]);
  const [zoomArea, setZoomArea] = useState({});
  const [xTicks, setXTicks] = useState([]);
  const [dateDifference, setDateDifference] = useState(null);

  // State variables to manage line visibility
  const [showPowerBess, setShowPowerBess] = useState(true);
  const [showPowerActual, setShowPowerActual] = useState(true);
  const [showPowerGoal, setShowPowerGoal] = useState(true);
  const [showPowerMeter, setShowPowerMeter] = useState(true);
  const [showPowerBuilding, setShowPowerBuilding] = useState(true);
  const [showSOC, setShowSOC] = useState(true);

  // State to keep track of clicked legend items
  const [clickedLegendItems, setClickedLegendItems] = useState([]);


  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const date = new Date(label);

      const assetDate = new Date(date.getTime() - (- date.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

      const formattedDate = `${assetDate.getFullYear()}-` +
        `${String(assetDate.getMonth() + 1).padStart(2, '0')}-` +
        `${String(assetDate.getDate()).padStart(2, '0')} ` +
        `${String(assetDate.getHours()).padStart(2, '0')}:` +
        `${String(assetDate.getMinutes()).padStart(2, '0')} `;

      const formatNumber = (value) => parseFloat(value).toFixed(2);

      const socPayload = payload.find(entry => entry.dataKey === 'batterySOC');
      const powerBessPayload = payload.find(entry => entry.dataKey === 'pBess');
      const powerActualPayload = payload.find(entry => entry.dataKey === 'pActual');
      const powerGoalPayload = payload.find(entry => entry.dataKey === 'pGoal');
      const powerMeterPayload = payload.find(entry => entry.dataKey === 'pMeter');
      const powerBuildingPayload = payload.find(entry => entry.dataKey === 'pBuilding');

      return (
        <div className='custom-tooltip' style={{ backgroundColor: '#f4f4f4', padding: '10px', border: '1px solid #ccc' }}>
          <p className='label'>{`Timezone: ${assetTimezone}`}</p>
          <p className='label'>{`Time: ${formattedDate}`}</p>
          {powerBessPayload && (
            <p className='soc'>{`Power BESS: ${formatNumber(powerBessPayload.value)} kW`}</p>
          )}
          {powerActualPayload && (
            <p className='soc'>{`Power Actual: ${formatNumber(powerActualPayload.value)} kW`}</p>
          )}
          {powerGoalPayload && (
            <p className='soc'>{`Power Goal: ${formatNumber(powerGoalPayload.value)} kW`}</p>
          )}
          {powerMeterPayload && (
            <p className='soc'>{`Power Meter: ${formatNumber(powerMeterPayload.value)} kW`}</p>
          )}
          {powerBuildingPayload && (
            <p className='soc'>{`Power Building: ${formatNumber(powerBuildingPayload.value)} kW`}</p>
          )}
          {socPayload && (
            <p className='soc'>{`State of Charge: ${socPayload.value} %`}</p>
          )}
        </div>
      );
    }
    return null;
  };

  const startDateDifference = powerSocEndDate - powerSocStartDate;
  const startDateDifferenceInDays = convertToDays(startDateDifference);
  const startXTicks = getTicks(powerSoc, startDateDifferenceInDays);

  useEffect(() => {
    setDateDifference(startDateDifference);
    setXTicks(startXTicks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Format the start and end dates as ISO 8601 strings
  const startDate = powerSocStartDate.toISOString();
  const endDate = powerSocEndDate.toISOString();

  const username = localStorage.getItem('cdnzUser');

  // Request for fetching new data that sends every minute
  useEffect(() => {
    const fetchNewPowerSocData = () => {
      const token = localStorage.getItem('cdnzAccessToken');
      api.post(
        `/device/dps-performance`,
        {
          startDate,
          endDate,
          assetName,
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        },
      )
        .then((res) => {
          setPowerSocData(res.data.dpsPerformanceData);
        })
        .catch(() => {
          Notify.warning('Failed to fetch dps performance data', notifyOptions);
        });
    };
    const intervalId = setInterval(fetchNewPowerSocData, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Creation of dummyData if data is missing
  let dummyData = [];

  if (
    !powerSocData || !Array.isArray(powerSocData) || powerSocData.length === 0
  ) {
    let endTime;
    const currentDate = new Date();
    const currentDay = currentDate.toDateString();

    const endDate = new Date(powerSocEndDate.getTime() - (- powerSocEndDate.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);

    const powerSocEndDateString = new Date(endDate).toDateString();

    if (powerSocEndDateString === currentDay) {
      endTime = new Date();
    } else {
      endTime = new Date(endDate);
      endTime.setHours(23, 59, 59, 999);
    }

    // Calculate the number of half-hour segments between powerSocStartDate and endTime
    const startTime = new Date(powerSocStartDate);
    const segmentCount = Math.ceil((endTime - startTime) / (30 * 60 * 1000));

    // Create an array of objects with timestamps for each half-hour segment
    for (let i = 0; i < segmentCount; i++) {
      const timestamp = new Date(startTime.getTime() + i * 30 * 60 * 1000);
      dummyData.push({
        timestamp,
        pBess: 0,
        pActual: 0,
        pGoal: 0,
        pMeter: 0,
        pBuilding: 0,
        batterySOC: 0
      });
    }
  }


  // Zoom handlers
  const handleZoom = () => {
    if (zoomArea.left === undefined || zoomArea.right === undefined) {
      return;
    }

    const [left, right] = [zoomArea.left, zoomArea.right].sort((a, b) => a - b);

    // Filter the data based on the selected zoom area
    const zoomedData = powerSocData.filter((entry) => entry.timestamp >= left && entry.timestamp <= right);

    const dateDifference = Math.abs(left - right);
    setDateDifference(dateDifference);

    const dateDifferenceInDays = convertToDays(dateDifference);
    const newXTicks = getTicks(zoomedData, dateDifferenceInDays);
    setXTicks(newXTicks);

    setZoomedData(zoomedData);
    setZoomArea({});
  };

  const handleZoomOut = () => {
    setDateDifference(startDateDifference);
    setXTicks(startXTicks);
    setZoomedData([]);
    setZoomArea({});
  };


  // What data will be passed for chart creation
  const showData = () => {
    if (zoomedData && Array.isArray(zoomedData) && zoomedData.length > 0) {
      return zoomedData;
    } else if (!powerSocData || !Array.isArray(powerSocData) || powerSocData.length === 0) {
      return dummyData;
    } else {
      return powerSocData;
    }
  };


  const rangeForFileName = formatRangeForFileName(powerSocStartDate, powerSocEndDate, assetDifferenceFromUtc);

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, `${assetName} DPS Performance ${rangeForFileName}.png`);
    }
  }, [getPng, assetName, rangeForFileName]);

  const transformedData = transformDataForCSV(showData(), assetDifferenceFromUtc, ['pBess', 'pActual', 'pGoal', 'pMeter', 'pBuilding', 'batterySOC']);


  const handleLegendClick = (entry) => {
    const { dataKey } = entry;

    // Toggle visibility based on legend item clicked
    switch (dataKey) {
      case 'pBess':
        setShowPowerBess(!showPowerBess);
        break;
      case 'pActual':
        setShowPowerActual(!showPowerActual);
        break;
      case 'pGoal':
        setShowPowerGoal(!showPowerGoal);
        break;
      case 'pMeter':
        setShowPowerMeter(!showPowerMeter);
        break;
      case 'pBuilding':
        setShowPowerBuilding(!showPowerBuilding);
        break;
      case 'batterySOC':
        setShowSOC(!showSOC);
        break;
      default:
        break;
    }

    if (clickedLegendItems.includes(dataKey)) {
      setClickedLegendItems(clickedLegendItems.filter(item => item !== dataKey));
    } else {
      setClickedLegendItems([...clickedLegendItems, dataKey]);
    }
  };

  const renderLegendIcon = (entry) => {
    const { color, dataKey } = entry;
    const isClicked = clickedLegendItems.includes(dataKey);

    if (dataKey === 'batterySOC') {
      return (
        <line
          strokeWidth={2}
          stroke={isClicked ? '#888888' : color}
          x1={0}
          y1={10}
          x2={100}
          y2={10}
        />
      );
    } else if (dataKey === 'pGoal') {
      return (
        <line
          strokeWidth={2}
          stroke={isClicked ? '#888888' : color}
          strokeDasharray="4 4"
          x1={0}
          y1={10}
          x2={40}
          y2={10}
        />
      );
    } else {
      return (
        <>
          <line
            strokeWidth={2}
            stroke={isClicked ? '#888888' : color}
            x1={0}
            y1={10}
            x2={40}
            y2={10}
          />
          <circle cx={10} cy={10} r={3} fill={"#f4f4f4"} stroke={isClicked ? '#888888' : color} />
        </>
      );
    }
  };

  const divisor = Math.ceil(showData().length / 20);

  return (
    <>
      {(
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={300}
            data={showData()}
            ref={ref}
            margin={{
              top: 0,
              right: 20,
              left: 30,
              bottom: 0,
            }}
            cursor={zoomArea.left ? 'col-resize' : 'zoom-in'}
            onMouseDown={(e) => e && e.activeLabel && setZoomArea({ ...zoomArea, left: e.activeLabel })}
            onMouseMove={(e) => zoomArea.left && setZoomArea({ ...zoomArea, right: e.activeLabel })}
            onMouseUp={handleZoom}
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='timestamp'
              ticks={xTicks}
              tickFormatter={(timestamp) => tickFormatterToAssetTime(timestamp, dateDifference, assetDifferenceFromUtc)}
              cursor="default"
            />
            <YAxis
              yAxisId='left'
              orientation='left'
              cursor="default"
              tickFormatter={(value) => roundToNearestTwoDecimal(value)}
            >
              <Label
                value='Power (kW)'
                angle={-90}
                position='insideLeft'
                offset={-17}
                dx={10}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <YAxis yAxisId='right' orientation='right' domain={[0, 100]} cursor="default">
              <Label
                value='State of Charge (%)'
                angle={-90}
                position='insideRight'
                offset={0}
                dx={-10}
                style={{ textAnchor: 'middle' }}
              />
            </YAxis>
            <Tooltip content={<CustomTooltip />} />
            <Legend
              onClick={handleLegendClick}
              wrapperStyle={{ textAlign: 'center' }}
              layout="horizontal"
              content={(props) => {
                const { payload } = props;

                return (
                  <ul
                    className="custom-legend"
                    style={{ display: 'flex', listStyle: 'none', padding: 0, justifyContent: 'center' }}
                  >
                    {payload.map((entry, index) => {
                      const isClicked = clickedLegendItems.includes(entry.dataKey);

                      return (
                        <li
                          key={`item-${index}`}
                          style={{ marginRight: '20px', display: 'flex', cursor: 'pointer', alignItems: 'center' }}
                          onClick={() => handleLegendClick(entry)}
                        >
                          <svg width={20} height={20}>
                            {renderLegendIcon(entry, isClicked)}
                          </svg>
                          <span
                            style={{
                              marginLeft: '5px',
                              color: isClicked ? '#888888' : entry.color,
                              textDecoration: entry.dataKey === 'pGoal' ? 'dashed' : 'none',
                            }}
                          >
                            {entry.value}
                          </span>
                        </li>
                      );
                    })}
                    <button
                      type="button"
                      onClick={handleZoomOut}
                      title="Zoom Out"
                      className='zoom-out rounded-md px-1'
                    >
                      <IconContext.Provider value={{ color: '#00000' }}>
                        <FiZoomOut size={24} />
                      </IconContext.Provider>
                    </button>
                    <CSVLink
                      data={transformedData}
                      filename={`${assetName} DPS Performance ${rangeForFileName}.csv`}
                      className='csv-link'
                    >
                      <button
                        type="button"
                        className='save-as-csv rounded-md px-1 py-1 text-xs'
                        title="Save as CSV"
                      >
                        <IconContext.Provider value={{ color: '#00000' }}>
                          <PiFileCsv size={24} />
                        </IconContext.Provider>
                      </button>
                    </CSVLink>
                    <button
                      type="button"
                      className='save-as-png rounded-md px-1 py-1 text-xs'
                      onClick={handleDownload}
                      title="Save as PNG"
                    >
                      <IconContext.Provider value={{ color: '#00000' }}>
                        <PiFilePng size={24} />
                      </IconContext.Provider>
                    </button>
                  </ul>
                );
              }}
            />
            <Line
              type='monotone'
              dataKey='pBess'
              stroke='#0f3052'
              name='Bess'
              yAxisId='left'
              isAnimationActive={false}
              hide={!showPowerBess}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
            <Line
              type='monotone'
              dataKey='pActual'
              stroke='#75eb75'
              name='Actual'
              yAxisId='left'
              isAnimationActive={false}
              hide={!showPowerActual}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
            <Line
              type='monotone'
              dataKey='pGoal'
              stroke='#ff7300'
              name='Goal'
              yAxisId='left'
              isAnimationActive={false}
              hide={!showPowerGoal}
              dot={false}
              strokeDasharray="20 15"
            />
            <Line
              type='monotone'
              dataKey='pMeter'
              stroke='#ff0000'
              name='Meter'
              yAxisId='left'
              isAnimationActive={false}
              hide={!showPowerMeter}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
            <Line
              type='monotone'
              dataKey='pBuilding'
              stroke='#8e44ad'
              name='Building'
              yAxisId='left'
              isAnimationActive={false}
              hide={!showPowerBuilding}
              dot={(props) => {
                if (props.index % divisor === 0) {
                  return <Dot
                    {...props}
                    r={3}
                  />;
                }
                return null;
              }}
            />
            <Line
              type='monotone'
              dataKey='batterySOC'
              stroke='#0074cc'
              name='SOC'
              yAxisId='right'
              isAnimationActive={false}
              hide={!showSOC}
              dot={false}
            />
            {zoomArea.left && zoomArea.right && (
              <ReferenceArea
                x1={zoomArea.left}
                x2={zoomArea.right}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}
    </>
  );
}

export default PoweSoc;