import React, { useState } from 'react';

// Assets
import { FiEdit } from 'react-icons/fi';

function AccountRow(props) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <tr
      className="border-b"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <td
        className="cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleEditModal();
        }}
      >
        <FiEdit
          className={`inline-flex self-center ${
            !isHovered && 'md:invisible'
          }`}
          size={20}
        />
      </td>
      {/* Comment or remove this part about Account ID */}
      {/* <td
      className="pr-2 py-2 cursor-pointer"
      onClick={() => {
        props.assignActiveAccount(props.accountID);
        props.toggleModal();
      }}
    >
      {props.accountID}
    </td> */}
      {/* This part should remain to display Account Name */}
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleModal();
        }}
      >
        {props.accountName}
      </td>
      <td className="pr-2 py-2 font-bold hover:underline cursor-pointer">
        {/*NOTE: Check why was != */}
        {props.salesforceLink !== '' && (
          <a href={props.salesforceLink} target="_blank" rel="noreferrer">
            View
          </a>
        )}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleModal();
        }}
      >
        {props.accountOwnerName}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleModal();
        }}
      >
        {props.accountOwnerPhoneOwner}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleModal();
        }}
      >
        {props.accountOwnerEmail}
      </td>
      <td
        className="pr-2 py-2 cursor-pointer"
        onClick={() => {
          props.assignActiveAccount(props.accountID);
          props.toggleModal();
        }}
      >
        {props.accountOwnerRole}
      </td>
    </tr>
  );
}

export default AccountRow;
