import React from 'react';

const AccountDetails = ({ account }) => {
  if (!account) return <div>No account details available</div>;
  
  return (
    <div className="flex flex-col items-center my-6">
      <h1 className="text-2xl font-bold text-center mb-6">View Account</h1>

      <div className="md:grid grid-cols-2 gap-8 w-4/5">
        <div className="account-container static">
          <h3 className="mt-4 mb-2 font-bold">Account Name</h3>
          <p className="font-medium">{account.accountName}</p>

          <h3 className="mt-4 mb-2 font-bold">Account Contact</h3>
          <p className="font-medium">{account.accountOwnerName}</p>

          <h3 className="mt-4 mb-2 font-bold">Title/Role</h3>
          <p className="font-medium">{account.accountOwnerRole}</p>
        </div>

        <div className="account-container static">
          <h3 className="mt-4 mb-2 font-bold">Salesforce Link</h3>
          <p className="font-medium hover:underline">
            <a href={account.salesforceLink}>
              {account.salesforceLink}
            </a>
          </p>

          <h3 className="mt-4 mb-2 font-bold">Phone</h3>
          <p className="font-medium">
            {account.accountOwnerPhoneOwner}
          </p>

          <h3 className="mt-4 mb-2 font-bold">Email</h3>
          <p className="font-medium">{account.accountOwnerEmail}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountDetails;
