export function getGridPowerStatusColors(upsGridPowerStatus) {
  let circle1Color, circle2Color, circle3Color;
  switch (upsGridPowerStatus) {
    case 'Yellow':
      circle1Color = '#f68c1e';
      circle2Color = '#ccc';
      circle3Color = '#ccc';
      break;
    case 'Red':
      circle1Color = '#ccc';
      circle2Color = '#ccc';
      circle3Color = '#c93545';
      break;
    case 'Green':
      circle1Color = '#ccc';
      circle2Color = '#88c971';
      circle3Color = '#ccc';
      break;
    default:
      circle1Color = '#ccc';
      circle2Color = '#ccc';
      circle3Color = '#ccc';
  }
  return { circle1Color, circle2Color, circle3Color };
}