function About() {
  return (
    <main className="flex justify-center p-5 grow min-h-full">
      <section className="container container-border bg-white p-6 md:px-96 overflow-auto relative">
        <div className="about-container flex flex-col items-right my-6">
          <h1 className="text-2xl font-bold mb-6 self-center">Change Log</h1>
          <p className="mb-4 self-center">
            All notable changes to this project will be documented on this page.
          </p>

          <div className="border-t border-gray-300 py-4">
            <h2 className="text-xl font-semibold mb-2">[3.0.0] - 2024-05-01</h2>
            <p>Added UPS dashboard</p>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Added</h3>
              <ul className="list-disc pl-4">
                <li>UPS dashboard for "Asset Real-Time Data" modal</li>
                <li>Switcher "Enable BESS Dashboard" on "Edit Asset Details" modal</li>
                <li>"Always charge" section on "Edit Asset Details" modal</li>
                <li>"Location Identifier" field on "Edit Asset Details" modal</li>
                <li>For BESS dashboard on the "Asset Real-Time Data" modal "Switch to UPS dashboard" button</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Changed</h3>
              <ul className="list-disc pl-4">
                <li>Information displayed in the “Account View” modal</li>
                <li>Changed "Create Account" and "Updated Account" forms</li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-300 py-4">
            <h2 className="text-xl font-semibold mb-2">[2.0.0] - 2024-04-09</h2>
            <p>The third major phase of work</p>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Added</h3>
              <ul className="list-disc pl-4">
                <li>"Hardware Details" modal on "Edit Asset Details" page</li>
                <li>"Export asset historical data" section on "Asset Real-Time Data" page</li>
                <li>Ability to export data from charts and tables for "Asset Real-Time Data" and "Home" pages</li>
                <li>Ability to save widgets position on "Asset Real-Time Data" page</li>
                <li>Ability to zoom for "DPS Performance" chart on "Asset Real-Time Data" page</li>
                <li>Ability to save map position for "Asset Locations" widget on "Home" page</li>
                <li>Confirmation modal for opting out discharging event</li>
                <li>Loader for charts and tables for "Asset Real-Time Data" and "Home" pages</li>
                <li>Save position functionality after performing filtering for charts and tables</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Changed</h3>
              <ul className="list-disc pl-4">
                <li>Switched to asset time "Asset Real-Time Data" page</li>
                <li>Functionality for closing menu in "Header"</li>
                <li>Data display for "Account energy" chart on "Home" page</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Fixed</h3>
              <ul className="list-disc pl-4">
                <li>Problem with login (incorrect user role)</li>
                <li>Values on x-axis for charts</li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-300 py-4">
            <h2 className="text-xl font-semibold mb-2">[1.2.0] - 2024-02-02</h2>
            <p>The second major phase of work</p>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Added</h3>
              <ul className="list-disc pl-4">
                <li>"DPS performance" and "Max power" charts to "Asset Real-Time Data" page</li>
                <li>"Battery throughput" and "Target Power" values to "Asset Real-Time Data" page</li>
                <li>"Dynamic Peak Shaving" section to "Edit Asset Details" page</li>
                <li>"Enrollment List" modal for Enbala on "Demand Response Programs" page</li>
                <li>"About" page</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Changed</h3>
              <ul className="list-disc pl-4">
                <li>Creation of new charging events</li>
              </ul>
            </div>
          </div>

          <div className="border-t border-gray-300 py-4">
            <h2 className="text-xl font-semibold mb-2">[1.1.0] - 2023-11-01</h2>
            <p>The first major phase of work (new repository)</p>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Added</h3>
              <ul className="list-disc pl-4">
                <li>"Asset Real-Time Data" page</li>
                <li>"Demand Response Programs" page</li>
                <li>"Enrolment List" page</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Changed</h3>
              <ul className="list-disc pl-4">
                <li>"Edit Asset Details" page</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Fixed</h3>
              <ul className="list-disc pl-4">
                <li>Scheduled DR table</li>
              </ul>
            </div>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Removed</h3>
              <ul className="list-disc pl-4">
                <li>Icons with statuses from "Edit Asset Details" page</li>
              </ul>
            </div>
          </div>


          <div className="border-t border-gray-300 py-4">
            <h2 className="text-xl font-semibold mb-2">[1.0.0] - 2023-08-15</h2>
            <p>Start of the project (old repository)</p>

            <div className="mt-4">
              <h3 className="text-lg font-semibold mb-2">Added</h3>
              <ul className="list-disc pl-4">
                <li>"Home" page</li>
                <li>"Edit Asset Details" page</li>
                <li>"Customer Acounts" page</li>
                <li>"Profile Details" page</li>
                <li>"Users" page</li>
              </ul>
            </div>

          </div>
        </div>
      </section>
    </main>
  );
}

export default About;
