const getArrOfStartReadyEvent = events => {
  const dates = [];

  events.forEach(event => {
    // if (new Date(event.endTime) > new Date() && event.eventStatus === 'Ready') {
      dates.push(event.startTime);
    // }
  });

  return dates;
};

export default getArrOfStartReadyEvent;
