// React
import React, { useState } from 'react';

// Libraries
import { Notify } from 'notiflix';

// Hooks
import useValidateToken from '../hooks/useValidateToken';
import api from '../api/axios.config';
import notifyOptions from '../constants/notify.options';
import Loader from '../components/Loader';

const Login = () => {
  useValidateToken();
  const [userName, setUserName] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await api.post('/authentication/login', {
        userName,
      });
      if (response.status === 200) {
        Notify.success('Check your email.', notifyOptions);
        // window.addEventListener('storage', event => {
        //   if (event.key === 'cdnzAccessTokens') {
        //     Notify.success('Welcome to Cadenza');
        //   }
        // });
      } else {
        Notify.failure(response.data.message, notifyOptions);
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 404) {
          Notify.failure(error.response.data.message, notifyOptions);
        } else {
          Notify.failure('Something went wrong. Please try again.',
            notifyOptions);
        }
      } else if (error.request) {
        Notify.failure('No server response. Please try again.', notifyOptions);
      } else {
        Notify.failure('Something went wrong. Please try again.',
          notifyOptions);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className='w-full md:w-1/2 lg:w-1/4 mx-auto my-8 login-container'>
      {/* {error && <p className='text-center'>{error}</p>} */}

      <h1 className='text-center text-2xl color-primary font-medium mb-6'>
        Sign In
      </h1>
      <p className='text-center color-primary mb-6'>
        A link will be sent to your email for passwordless sign in.
      </p>
      <form onSubmit={handleSubmit} className='flex flex-col '>
        <label
          htmlFor='username'
          className='color-primary font-medium my-2 text-lg'
        >
          Username
        </label>
        <input
          type='text'
          id='email'
          autoComplete='off'
          onChange={e => setUserName(e.target.value)}
          value={userName}
          required
        />
        <button
          type='submit'
          disabled={isLoading}
          className='rounded-md px-4 py-2 text-sm mt-6 flex justify-center items-center'
        >
          {isLoading ? <Loader classNames='w-5 h-5' /> : 'Login'}
        </button>
      </form>
    </section>
  );
};

export default Login;
