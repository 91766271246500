import React from 'react';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

export default function EnrollAssetToProgram({
  toggleModal,
  activeAssets,
  activeProgram,
  checkboxState,
  toggleCheckbox,
  setActiveAsset,
}) {
  const assetNames = activeAssets.join(', ');
  const isLoadingEnroll = false;

  // Handlers
  const handleEnrollConfirm = async () => {
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');
    try {
      const { data } = await api.post('/programs/enroll', {
        data: {
          programKey: activeProgram.programKey,
          assetName: activeAssets,
        }
      }, {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.status === 'success') {
        Notify.success(
          `The assets: ${assetNames} was successfully enrolled to program ${activeProgram.programName}.`,
          notifyOptions,
        );
      }
    } catch (err) {
      Notify.failure(
        `The assets: ${assetNames} was not enrolled to program ${activeProgram.programName}.`,
        notifyOptions,
      );
    }
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  const handleEnrollReject = () => {
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-28'>
        {`Are you sure you want to enroll assets: "${assetNames}" to program "${activeProgram.programName}"?`}
      </h1>
      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleEnrollConfirm}
        >
          {isLoadingEnroll ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={handleEnrollReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
