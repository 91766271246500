// React
import React, { useEffect, useState } from 'react';

// Components
import Loader from '../../Loader';

// Access
import accessControl from '../../../helpers/accessControl';

// Assets
import Modal from '../Modal';
import { FiX } from 'react-icons/fi';

import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';
import EnrollAssetToEnbala from './EnrollAssetToEnbala';
import OptOutAssetEnbala from './OptOutAssetEnbala';

const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

const EnrollmentListEnbala = (
  {
    program,
    toggleEnrollmentModalEnbala
  }
) => {

  const [assetList, setAssetList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [activeAssets, setActiveAsset] = useState(null);
  const [optOut, setOptOut] = useState(null);
  const [showEnrollEnbalaModal, setShowEnrollEnbalaModal] = useState(false);
  const [showOptOutModal, setSetshowOptOutModal] = useState(false);

  const toggleEnrollModal = () => {
    setShowEnrollEnbalaModal(!showEnrollEnbalaModal);
  };

  const toggleOptOutModal = () => {
    setSetshowOptOutModal(!showOptOutModal);
  };

  const enrollProgram = (name) => {
    setActiveAsset(name);
    setShowEnrollEnbalaModal(true);
  };

  const optOutProgram = (name) => {
    setActiveAsset(name);
    setSetshowOptOutModal(true);
  };

  const fetchAssetList = async () => {
    try {
      const result = await api.get('/device/assets',
        {
          headers: {
            username: username,
            Authorization: `Bearer ${token}`,
          },
        });

      const { data: { assetList } } = result;

      setAssetList(assetList);
      setIsLoading(false);
    } catch (error) {
      console.error('An error occurred while fetching asset list:', error);
      Notify.failure('Failed to fetch asset list data', notifyOptions);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchAssetList();
  }, []);

  const enrolledAssets = assetList.filter(asset => asset.Enbala === true);
  const availableAssets = assetList.filter(asset => asset.Enbala === false);

  return (
    <div
      className='modal-container modal-container-alt-full flex justify-center items-center px-5 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-sm md:text-base cursor-pointer'>
      <div
        className='container container-border mx-auto border-0 pb-6 relative flex flex-col justify-start w-full bg-white outline-none focus:outline-none cursor-auto'
        onClick={event => {
          event.stopPropagation();
        }}
      >
        <header className='py-7 sticky top-0 bg-white z-50 drop-shadow'>
          <span
            className='absolute top-6 right-4'
            type='button'
            role='button'
            onClick={() => toggleEnrollmentModalEnbala()}
          >
            <FiX size={28} />
          </span>
          <h1 className='text-2xl font-bold text-center'>Enrollment List</h1>
        </header>
        {isLoading ? (
          <div className='w-full h-full flex justify-center items-center'>
            <Loader classNames='w-32 h-32' />
          </div>
        ) : (
          <div className='p-6 pt-0 flex flex-col justify-between'>
            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-4'>
                <h2 className='text-lg font-bold mb-2'>Enrollments For Program</h2>
                <div
                  className='table-container'
                >
                  <table
                    className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item">ID</th>
                        <th className="table-head-item">Program Name</th>
                        <th className="table-head-item">Utility Name</th>
                        <th className="table-head-item">State</th>
                        <th className="table-head-item">Program Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={program.programKey}>
                        <td className="pr-2 py-2">{program.programKey}</td>
                        <td className="pr-2 py-2">{program.programName}</td>
                        <td className="pr-2 py-2">{program.utilityName}</td>
                        <td className="pr-2 py-2">{program.state}</td>
                        <td className="pr-2 py-2">{program.programType}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>) : '')}

            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-6'>
                <h2 className='text-lg font-bold mb-2'>Enrolled Asset List</h2>
                <div
                  className='table-container'
                  style={enrolledAssets.length > 4 ? {
                    maxHeight: 'calc(11 * 40px)',
                    overflowY: 'auto',
                  } : {}}
                >
                  <table className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item">Asset Name</th>
                        <th className="table-head-item">BESS Serial Number</th>
                        <th className="table-head-item">Site Description</th>
                        <th className="table-head-item">Model Number</th>
                        <th className="table-head-item"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrolledAssets.map(item => {
                        return (
                          <tr
                            key={item.assetId}
                          >
                            <td className="pr-2 py-2">{item.bessName}</td>
                            <td className="pr-2 py-2">{item.name}</td>
                            <td className="pr-2 py-2">{item.siteDescription}</td>
                            <td className="pr-2 py-2">{item.modelNumber}</td>
                            <td className="pr-2 py-2">
                              {item.optOutEnbala !== undefined && (
                                <button
                                  style={{ minWidth: '6vw', minHeight: '2rem' }}
                                  className='rounded-md px-2 py-1 text-xs'
                                  onClick={() => {
                                    if (item.optOutEnbala === true) {
                                      setOptOut(false);
                                      optOutProgram(item.name)
                                    } else {
                                      setOptOut(true);
                                      optOutProgram(item.name)
                                    }
                                  }}
                                >
                                  {item.optOutEnbala ? "Opt in" : "Opt out"}
                                </button>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>) : '')}

            {(accessControl.isPlatformAdministrator() ? (
              <div className='flex flex-col justify-end mt-6'>
                <h2 className='text-lg font-bold mb-2'>Available Asset List</h2>
                <div
                  className='table-container'
                  style={assetList.length > 5 ? {
                    maxHeight: 'calc(5 * 40px)',
                    overflowY: 'auto',
                  } : {}}
                >
                  <table className="table-auto w-full border rounded-lg">
                    <thead className="table-head">
                      <tr>
                        <th className="table-head-item">Asset Name</th>
                        <th className="table-head-item">BESS Serial Number</th>
                        <th className="table-head-item">Site Description</th>
                        <th className="table-head-item">Model Number</th>
                        <th className="table-head-item"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {availableAssets.map(item => {
                        return (
                          <tr
                            key={item.assetId}
                          >
                            <td className="pr-2 py-2">{item.bessName}</td>
                            <td className="pr-2 py-2">{item.name}</td>
                            <td className="pr-2 py-2">{item.siteDescription}</td>
                            <td className="pr-2 py-2">{item.modelNumber}</td>
                            <td className="pr-2 py-2">
                              <button
                                style={{ minWidth: '6vw', minHeight: '2rem' }}
                                className='rounded-md px-2 py-1 text-xs'
                                onClick={() => enrollProgram(item.name)}
                              >
                                Enroll
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

              </div>) : '')}
          </div>
        )}
      </div>

      {showEnrollEnbalaModal && (
        <Modal
          toggleModal={toggleEnrollModal}
          isSmall={true}
          child={
            <EnrollAssetToEnbala
              toggleModal={toggleEnrollModal}
              assetName={activeAssets}
              fetchAssetList={fetchAssetList}
            />
          }
        />
      )}

      {showOptOutModal && (
        <Modal
          toggleModal={toggleOptOutModal}
          isSmall={true}
          child={
            <OptOutAssetEnbala
              toggleModal={toggleOptOutModal}
              assetName={activeAssets}
              optOut={optOut}
              fetchAssetList={fetchAssetList}
            />
          }
        />
      )}
    </div>
  );
};

export default EnrollmentListEnbala;
