const formatTime = (timeString) => {
  const date = new Date(timeString);
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

  const hours = date.getUTCHours();
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = String(hours % 12 || 12);

  return `${formattedHours}:${minutes} ${ampm}`;
};

export default formatTime;