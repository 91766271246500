import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

export default function PrivateRoute({ redirectTo, children }) {
  const location = useLocation();
  const { auth } = useAuth();

  return auth ? (
    children
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
}
