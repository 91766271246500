import React from 'react';

const ProgramDetails = props => {
  if (!props.program) return <div>No program details available</div>;
  return (
    <div className='flex flex-col items-center my-6'>
      <h1 className='text-2xl font-bold text-center mb-6'>View Program</h1>
      <div className='md:grid grid-cols-2 gap-8 w-4/5'>
        <div className='program-container static'>
          <h3 className='mt-4 mb-2 font-bold'>Program Key</h3>
          <p className='font-medium'>{props.program.programKey}</p>
          <h3 className='mt-4 mb-2 font-bold'>Program Name</h3>
          <p className='font-medium'>{props.program.programName}</p>
        </div>
        <div className='program-container static'>
          <h3 className='mt-4 mb-2 font-bold'>Program Type</h3>
          <p className='font-medium'>{props.program.programType}</p>

          <h3 className='mt-4 mb-2 font-bold'>Source Account Number</h3>
          <p className='font-medium'>{props.program.sourceAccountNumber}</p>
        </div>
      </div>
      <div className='md:grid grid-cols-2 gap-8 my-10 w-4/5'>
        <div className='static'>
          <h3 className='mt-4 mb-2 font-bold'>Status</h3>
          <p className='font-medium'>{props.program.status}</p>

          <h3 className='mt-4 mb-2 font-bold'>Source Account Number</h3>
          <p className='font-medium'>{props.program.sourceAccountNumber}</p>
        </div>
        <div className='static'>
          <h3 className='mt-4 mb-2 font-bold'>State</h3>
          <p className='font-medium'>{props.program.state}</p>

          <h3 className='mt-4 mb-2 font-bold'>Description</h3>
          <p className='font-medium'>{props.program.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgramDetails;
