export const roundToNearestThirdDecimal = (value) => {
  if(Number.isInteger(value)) {
    return value;
  }
  const roundedValue = Math.round(value * 1000) / 1000;
  return roundedValue.toFixed(3);
};

export const roundToNearestTwoDecimal = (value) => {
  if(Number.isInteger(value)) {
    return value;
  }
  const roundedValue = Math.round(value * 100) / 100;
  return roundedValue.toFixed(2);
};
