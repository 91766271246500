import tzlookup from 'tz-lookup';
import { DateTime } from 'luxon';

function getUtcOffsetDifference(latitude, longitude) {
  try {
    // If there are no coordinates, we use Cadenza's coordinates, ziro (0) is allowed.
    if (
      latitude === null || latitude === '' || latitude === undefined ||
      longitude === undefined || longitude === null || longitude === ''
    ) {
      latitude = '41.38365047646681';
      longitude = '-73.53166918281711';
    }

    // Get timezone using tz-lookup
    const timeZone = tzlookup(latitude, longitude);

    // Use luxon to calculate the UTC offset difference
    const now = DateTime.now().setZone(timeZone);

    // Get the timezone offset in minutes directly from luxon
    const offsetInMinutes = now.offset;

    return offsetInMinutes;
  } catch (error) {
    console.error('Error getting UTC offset difference', error);

    // If there was the error we need to use Cadenza's coordinates
    const timeZone = tzlookup('41.38365047646681', '-73.53166918281711');
    const now = DateTime.now().setZone(timeZone);
    const offsetInMinutes = now.offset;
    return offsetInMinutes;
  }
}

export default getUtcOffsetDifference;
