import * as Yup from 'yup';

const AccountFormSchema = Yup.object().shape({
  accountName: Yup.string().required('Account Name is required'),
  accountOwnerName: Yup.string(),
  accountOwnerRole: Yup.string(),
  accountOwnerPhoneOwner: Yup.string().matches(/^[0-9]*$/, 'Invalid phone number'),
  accountOwnerEmail: Yup.string().email('Invalid email address'),
  emergencyContactName: Yup.string().required('Emergency Contact is required'),
  emergencyContactRole: Yup.string().required('Emergency Contact Role is required'),
  emergencyContactPhoneNumber: Yup.string().matches(/^[0-9]*$/, 'Invalid phone number').required('Emergency Phone is required'),
  emergencyContactEmail: Yup.string().email('Invalid email address').required('Emergency Email is required'),
  salesforceLink: Yup.string(),
  clientNotificationEmail: Yup.string().email('Invalid email address'),
});

export default AccountFormSchema;