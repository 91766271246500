import tzlookup from 'tz-lookup';

function getTimezoneFromCoordinates(latitude, longitude) {
  try {
    // If there are no coordinates, we use Cadenza's coordinates.
    if (
      latitude === null || latitude === '' || latitude === undefined ||
      longitude === undefined || longitude === null || longitude === ''
    ) {
      latitude = '41.38365047646681';
      longitude = '-73.53166918281711';
    }

    // Get timezone using tz-lookup
    const timeZone = tzlookup(latitude, longitude);

    return timeZone;
  } catch (error) {
    console.error('Error getting timezone name', error);
    return null;
  }
}

export default getTimezoneFromCoordinates;