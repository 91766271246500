// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';

// Notify
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';

// Helpers
import { axiosBaseUrl } from '../tokenSettingsAxios';

const getUserDetails = createAsyncThunk(
  'user/getDetails',
  async (_, { rejectWithValue }) => {
    const userName = localStorage.getItem('cdnzUser');
    try {
      // Get user details
      const { data } = await axiosBaseUrl.get(`users/${userName}`);
      return data.user;
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const updateUserDetails = createAsyncThunk(
  'user/updateDetails',
  async ({ userData, toggleModal }, { rejectWithValue }) => {
    try {
      // Update user details
      await axiosBaseUrl.patch('users', { ...userData });

      Notify.success(
        `The user "${userData.username}" was successfully updated.`,
        notifyOptions
      );

      toggleModal && toggleModal();
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const deactivateUser = createAsyncThunk(
  'user/deactivateUser',
  async ({ toggleModal, userName, id }, { rejectWithValue }) => {
    try {
      // Deactivate user
      await axiosBaseUrl.delete(`users/${userName}`);

      Notify.success(
        `The user "${userName}" was successfully deactivated.`,
        notifyOptions
      );
      toggleModal();
    } catch (e) {
      Notify.failure(e.response.data.message, notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const createUser = createAsyncThunk(
  'user/createUser',
  async ({ userData, toggleModal }, { rejectWithValue }) => {
    try {
      // Create user
      await axiosBaseUrl.post('users', { ...userData });

      Notify.success(
        `The user "${userData.userName}" was successfully created.`,
        notifyOptions
      );
      toggleModal();
    } catch (e) {
      Notify.failure(e.response.data.message, notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const userOperations = {
  getUserDetails,
  updateUserDetails,
  deactivateUser,
  createUser,
};

export default userOperations;
