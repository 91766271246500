import { useContext } from 'react';
import AuthContext from '../context/AuthProvider';
import { token } from '../redux/tokenSettingsAxios';

const useAuth = () => {
  // Set token
  const currentToken = localStorage.getItem('cdnzAccessToken');
  token.set(currentToken);

  return useContext(AuthContext);
};

export default useAuth;
