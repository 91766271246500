// Redux
import { useDispatch, useSelector } from 'react-redux';
import { dashboardDataSelectors } from '../../redux/dashboardData/dashboardDataSelectors';
import { setFilterEvents } from '../../redux/dashboardData/dashboardDataSlice';

// Calendar
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';

// Icons
import { BsCalendarEvent } from 'react-icons/bs';

// Helpers
import getArrOfStartReadyEvent from '../../helpers/formatDate/getArrOfStartReadyEvent';

export default function CalendarEvents() {
  const calendarEvents = useSelector(dashboardDataSelectors.getScheduledEvents);
  const dispatch = useDispatch();

  const datesEventReady = getArrOfStartReadyEvent(calendarEvents);

  // Handlers
  const onChangeCalendar = ([date]) => {
    dispatch(setFilterEvents(date.toString()));
  };

  const onResetFilter = () => {
    dispatch(setFilterEvents(null));
  };

  return (
    <>
      <div className="dashboardNav--cancel flex items-center gap-1 md:gap-2 text-base md:mr-3 ">
        <p className=" text-xs md:text-base">View event dates:</p>
        <div className="calendarEvents-container flex items-center justify-center w-8 h-8 relative">
          <Flatpickr
            className="w-full h-full opacity-0 absolute bg-black z-50"
            onChange={onChangeCalendar}
            options={{
              enableTime: false,
              enable: [...datesEventReady],
              dateFormat: 'Y-m-d',
            }}
          />
          <BsCalendarEvent className="custom-icon " />
        </div>
        <button
          className="rounded-md px-2 py-2 md:px-4 md:py-2 text-xs md:text-sm text-white bg-[#0f3052]"
          onClick={onResetFilter}
        >
          Clear filters
        </button>
      </div>
    </>
  );
}
