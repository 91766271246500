const DefaultAssetUpsDataLayout = [
  {
      i: "backupLoad",
      maxW: 100,
      maxH: 100,
      minH: 6,
      minW: 2,
      w: 12,
      h: 10,
      x: 0,
      y: 0,
  },
];

module.exports = DefaultAssetUpsDataLayout;
