import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '../api/axios.config';
import useAuth from '../hooks/useAuth';

const Logout = () => {
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  useEffect(() => {
    const token = localStorage.getItem('cdnzAccessToken');
    api
      .post(
        `${window.$baseURL}/authentication/logout`,
        {},
        { headers: { authorization: `Bearer ${token}` } }
      )
      .then(res => {
        if (res.status === 200) {
          localStorage.clear();
          setAuth(() => {
            return { user: null, jwt: null, role: null };
          });
          return navigate('/login');
        } else {
          return alert('Logout failed');
        }
      })
      .catch(() => alert('Logout failed'));

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Logout;
