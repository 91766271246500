import { useState, createContext, useContext } from 'react';

export const FilterContext = createContext(null);

export const FilterProvider = ({ children }) => {
  const [widgetAssets, setWidgetAssets] = useState([]);
  const [isFilterActive, setIsFilterActive] = useState(false);

  // NOTE: check
  if (null) {
    setWidgetAssets();
  }
  const toggleFilter = () => {
    setIsFilterActive(!isFilterActive);
  };

  return (
    <FilterContext.Provider
      value={{ widgetAssets, isFilterActive, toggleFilter }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};

export default useFilterContext;
