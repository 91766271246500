const getUserDetailsData = state => state.user.userDetails.data;
const getUserDetailsLoading = state => state.user.userDetails.loading;
const getUserDeactivateLoading = state => state.user.userDeactivate.loading;
const getUserCreateLoading = state => state.user.userCreate.loading;
const getUserUpdateLoading = state => state.user.userUpdate.loading;

export const userSelectors = {
  getUserDetailsData,
  getUserDetailsLoading,
  getUserDeactivateLoading,
  getUserCreateLoading,
  getUserUpdateLoading,
};
