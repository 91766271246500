// Function to format timestamp to date, hour, and minute
export const formatTimestamp = (timestamp, offset) => {
  const date = new Date(timestamp);
  date.setUTCMinutes(date.getUTCMinutes() + offset);
  const formattedDate = date.toISOString().replace(/T/, ' ').replace(/\..+/, '').substring(0, 16);
  return formattedDate;
};

// Function to transform and format the data
export const transformDataForCSV = (data, assetDifferenceFromUtc, columnsToInclude = []) => {
  return data.map(item => {
    const transformedItem = {
      'UTC Time': formatTimestamp(item.timestamp, 0), // UTC time
      'Asset Time': formatTimestamp(item.timestamp, assetDifferenceFromUtc) // Asset time
    };

    // Add specified columns to the transformed data
    columnsToInclude.forEach(column => {
      transformedItem[column] = item[column];
    });

    return transformedItem;
  });
};