// React
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import assetRealTimeDataOperations from '../../../redux/assetRealTimeData/assetRealTimeDataOperations';
import { assetRealTimeDataSelectors } from '../../../redux/assetRealTimeData/assetRealTimeDataSelectors';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// api
import api from '../../../api/axios.config';

// Formik
import { Formik, Field, ErrorMessage, Form } from 'formik';

// Yup
import CreateDischargeEventSchema from './CreateDischargeEventSchema';

// Components
import Loader from '../../Loader';

// CustomDatePicker
import CustomDatePicker from '../../CustomDatePicker';

const CreateDischargeEvent = (
  {
    assetName,
    toggleModal,
    dateFilterEvents,
    dischargeStartDateTime,
    dischargeEndDateTime,
    userDetails,
    assetDifferenceFromUtc,
  }
) => {
  const initialValues = {
    powerLevel: '',
    mode: '',
    startDate: '',
    endDate: '',
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    days: [],
  };

  const isModeDisplayed = userDetails?.accountType === 'Cadenza Account';

  const [modeOptions, setModeOptions] = useState([]);

  const isDischargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getAddDischargingEventsLoading
  );

  // dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    fetchModesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // Get username
  const username = localStorage.getItem('cdnzUser');

  // Get token
  const token = localStorage.getItem('cdnzAccessToken');

  // Fetch options for the "Mode" select
  const fetchModesData = () => {
    if (isModeDisplayed) {
      api.post(
        `/device/discharging-events/modes`,
        {
        },
        {
          headers: {
            authorization: `Bearer ${token}`,
            username: username,
          },
        },
      )
        .then(res => {
          setModeOptions(res.data.modes.modes);
        })
        .catch(() => {
          Notify.warning('Discharging modes are not loaded. Please try later.', notifyOptions)
        });
    }
  }

  function hourArray() {
    let array = [];
    for (let i = 0; i < 24; i++) {
      array.push(i.toString().padStart(2, 0));
    }
    return array;
  }

  function minutesArray() {
    let array = [];
    for (let i = 0; i < 60; i++) {
      array.push(i.toString().padStart(2, 0));
    }
    return array;
  }

  // Array of days
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const startTime = `${values.startHour}:${values.startMinute}:00`;
    const endTime = `${values.endHour}:${values.endMinute}:00`;

    // Use padStart to ensure day part has two digits
    const startDateParts = values.startDate.split('-');
    const endDateParts = values.endDate.split('-');

    const startDateStr = `${startDateParts[0]}-${startDateParts[1].padStart(2, '0')}-${startDateParts[2].padStart(2, '0')}T${startTime}Z`;
    const endDateStr = `${endDateParts[0]}-${endDateParts[1].padStart(2, '0')}-${endDateParts[2].padStart(2, '0')}T${endTime}Z`;

    const startDateObj = new Date(startDateStr);
    const endDateObj = new Date(endDateStr);

    const now = new Date();
    const startDateObjAssetTime = new Date(startDateObj.getTime() + now.getTimezoneOffset() * 60000);
    const nowObjAssetTime = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    // Check if startDate is greater than now
    if (nowObjAssetTime >= startDateObjAssetTime) {
      setErrors({
        startDate: 'You cannot create an event in the past',
      });
      setSubmitting(false);
      return;
    }

    // Adjust for asset time zone offset
    startDateObj.setMinutes(startDateObj.getMinutes() - assetDifferenceFromUtc);
    endDateObj.setMinutes(endDateObj.getMinutes() - assetDifferenceFromUtc);

    const startDateUTC = startDateObj.toUTCString();
    const endDateUTC = endDateObj.toUTCString();

    // Check if endDate is greater than startDate
    if (endDateObj <= startDateObj) {
      setErrors({
        endDate: 'End date must be after the start date',
      });
      setSubmitting(false);
      return;
    }

    const daysValues = {};
    daysOfWeek.forEach(day => {
      daysValues[day] = values.days.includes(day) ? 1 : 0;
    });

    const bodyRequest = {
      eventType: "discharge",
      mode: values.mode,
      startDatetime: startDateUTC,
      endDatetime: endDateUTC,
      assetName: assetName,
      powerLevel: values.powerLevel,
      offSet: assetDifferenceFromUtc,
      ...daysValues,
    };

    dispatch(
      assetRealTimeDataOperations.addDischargingEvent({
        bodyRequest,
        toggleModal: toggleModal,
      })
    );

    const performDateFiltering = () => {
      dateFilterEvents('dischargingEvents', dischargeStartDateTime, dischargeEndDateTime);
    };

    setTimeout(performDateFiltering, 1000);

    setSubmitting(false);
  };

  const [hours] = useState(hourArray);
  const [minutes] = useState(minutesArray);

  return (
    <>
      <div>
        <h1 className="text-2xl font-bold text-center mb-6">
          Create a New Discharge Event
        </h1>

        {!!assetName && (
          <Formik
            initialValues={initialValues}
            validationSchema={() => CreateDischargeEventSchema(isModeDisplayed)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-6">
                <div className="md:grid grid-cols-2 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Power Level (kW)</h3>
                    <Field
                      type="text"
                      name="powerLevel"
                      className="rounded-md w-full py-1 px-2"
                      placeholder="Power Level"
                    />
                    <ErrorMessage
                      name="powerLevel"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                  {isModeDisplayed && (
                    <div className="dr-event-container">
                      <h3 className="mt-4 mb-2 font-bold">Mode</h3>
                      <Field
                        as="select"
                        name="mode"
                        className="rounded-md w-full py-1 px-2"
                      >
                        <option value="" disabled>
                          Select..
                        </option>
                        {modeOptions.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </Field>
                      <ErrorMessage
                        name="mode"
                        component="div"
                        className="text-red-500 mt-1"
                      />
                    </div>
                  )}
                </div>
                <div className="md:grid grid-cols-2 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Start Time</h3>
                    <div className="flex">
                      <div className="w-1/2 mr-2">
                        <Field
                          as="select"
                          name="startHour"
                          className="rounded-md w-full py-1 px-2"
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {hours.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="w-1/2 ml-2">
                        <Field
                          as="select"
                          name="startMinute"
                          className="rounded-md w-full py-1 px-2"
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage
                      name="startHour"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                    <ErrorMessage
                      name="startMinute"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">End Time</h3>
                    <div className="flex">
                      <div className="w-1/2 mr-2">
                        <Field
                          as="select"
                          name="endHour"
                          className="rounded-md w-full py-1 px-2"
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {hours.map((hour) => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="w-1/2 ml-2">
                        <Field
                          as="select"
                          name="endMinute"
                          className="rounded-md w-full py-1 px-2"
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {minutes.map((minute) => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage
                      name="endHour"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                    <ErrorMessage
                      name="endMinute"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="md:grid grid-cols-2 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Start Date</h3>
                    <CustomDatePicker name="startDate" />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">End Date</h3>
                    <CustomDatePicker name="endDate" />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="md:grid grid-cols-1 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Days</h3>
                    <div className="flex flex-wrap gap-4">
                      {daysOfWeek.map(day => (
                        <label key={day} className="flex items-center gap-2">
                          <Field
                            type="checkbox"
                            name="days"
                            value={day}
                            className="mr-2"
                            style={{ margin: '0', padding: '0' }}
                          />
                          <span style={{ margin: '0', padding: '0' }}>{day}</span>
                        </label>
                      ))}
                    </div>
                    <ErrorMessage
                      name="days"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6 flex-col md:flex-row">
                  <button
                    className="rounded-md px-4 py-2 text-sm mb-4 md:mb-0 flex justify-center items-center "
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isDischargingEventsLoading ? (
                      <Loader classNames="w-5 h-5" />
                    ) : (
                      'Save Event'
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default CreateDischargeEvent;
