// React
import { useEffect, useState } from 'react';

// Components
import AccountContainer from '../components/containers/AccountsContainer';
import api from '../api/axios.config';
import Loader from '../components/Loader';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../constants/notify.options';

const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

function Accounts() {
  const [accounts, setAccounts] = useState();
  // Fetch data
  const getData = async () => {
    try {
      const { data } = await api.get(`${window.$baseURL}/accounts`, {
        headers: {
          username: username,
          authorization: `Bearer ${token}`,
        },
      });

      setAccounts(data?.account?.accountList);
    } catch (error) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // const { data, error } = useSWR(`${window.$baseURL}/accounts`, fetcher);

  if (!accounts)
    return (
      <div className="loader-global-container">
        <Loader classNames="w-32 h-32" />
      </div>
    );
  return <AccountContainer accounts={accounts} getAccounts={getData} />;
}

export default Accounts;
