// React
import React, { useState, useEffect } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from '../../../redux/user/userSelectors';
import userOperations from '../../../redux/user/userOperations';

// Assets
import Loader from '../../Loader';
import accessControl from '../../../helpers/accessControl';
import isPlatformAdministrator from "../../../helpers/accessControl/isPlatformAdministrator";

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import UserFormValidationSchema from './UserFormSchema';

const UserForm = ({
  status,
  user,
  accounts: initialAccounts,
  toggleModal,
  refetchData,
}) => {
  const isCreateLoading = useSelector(userSelectors.getUserCreateLoading);

  const [accounts, setAccounts] = useState([]);

  // Dispatch
  const dispatch = useDispatch();

  const initialValues = {
    accountType: '',
    accountID: '',
    userSubtype: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    title: '',
    userName: '',
  };

  useEffect(() => {
    let accountList = [];
    initialAccounts.forEach(item => {
      accountList.push({
        accountName: item.accountName,
        accountNumber: item.accountID,
      });
    });

    let filteredAccounts
    if (!isPlatformAdministrator()) {
      filteredAccounts = accountList.filter(item => item.accountNumber === user.accountID)
      setAccounts(filteredAccounts);
    } else {
      filteredAccounts = accountList.filter(
        (item, index) => accountList.indexOf(item) === index
      );
      setAccounts(filteredAccounts);
    }

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitUpdate = (values) => {
    const userData = {
      username: values.userName,
      userSubtype: values.userSubtype,
      phoneNumber: values.phoneNumber,
      accountType: values.accountType,
      fullName: values.fullName,
      title: values.title,
      email: values.email,
      accountNumber: values.accountID,
    }

    dispatch(
      userOperations.updateUserDetails({
        userData,
        toggleModal,
      })
    )

    const fetchNewData = () => {
      refetchData();
    };
    setTimeout(fetchNewData, 1000);
  };

  const handleSubmitCreate = (values) => {
    const userData = {
      accountNumber: values.accountID,
      userName: values.userName,
      userSubtype: values.userSubtype,
      phoneNumber: values.phoneNumber,
      accountType: values.accountType,
      fullName: values.fullName,
      title: values.title,
      email: values.email,
    }

    dispatch(
      userOperations.createUser({
        userData,
        toggleModal
      })
    );
    
    const fetchNewData = () => {
      refetchData();
    };
    setTimeout(fetchNewData, 1000);
  };

  return (
    <>
      <div className="flex flex-col">
        <h1 className="text-2xl font-bold text-center mb-6">
          {status === 'edit' ? 'Edit' : 'Create'} User
        </h1>
        <Formik
          initialValues={status === 'edit' ? user : initialValues}
          validationSchema={UserFormValidationSchema}
          onSubmit={(values, formikBag) => {
            if (status === 'edit') {
              handleSubmitUpdate(values, formikBag);
            } else {
              handleSubmitCreate(values, formikBag);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <div className="md:grid grid-cols-2 gap-8">
                <div className="account-container">
                  <h3 className="mt-4 mb-2 font-bold">Account Type</h3>
                  <Field
                    as="select"
                    name="accountType"
                    className="rounded-md w-full py-1 px-2"
                  >
                    <option value="" disabled>
                      Select..
                    </option>
                    {accessControl.isPlatformAdministrator() && (
                      <option value="Cadenza Account">Cadenza Account</option>
                    )}
                    <option value="Customer Account">Customer Account</option>
                    {accessControl.isPlatformAdministrator() && (
                      <option value="Resident">Resident</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="accountType"
                    component="div"
                    className="text-red-500 mt-1"
                  />

                  <h3 className="mt-4 mb-2 font-bold">Account Name</h3>
                  <Field
                    as="select"
                    name="accountID"
                    className="rounded-md w-full py-1 px-2"
                  >
                    <option value="" disabled>
                      Select..
                    </option>
                    {accounts.map(item => {
                      return (
                        <option key={item.accountNumber} value={item.accountNumber}>
                          {item.accountName}
                        </option>
                      );
                    })}
                  </Field>
                  <ErrorMessage
                    name="accountID"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>
                <div className="account-container">
                  <h3 className="mt-4 mb-2 font-bold">User Type</h3>
                  <Field
                    as="select"
                    name="userSubtype"
                    className="rounded-md w-full py-1 px-2"
                  >
                    <option value="" disabled>
                      Select..
                    </option>
                    {accessControl.isPlatformAdministrator() && (
                      <option value="Platform Administrator">
                        Platform Administrator
                      </option>
                    )}
                    <option value="Client Administrator">Client Administrator</option>
                    <option value="Client User">Client User</option>
                    {accessControl.isPlatformAdministrator() && (
                      <option value="Resident">Resident</option>
                    )}
                  </Field>
                  <ErrorMessage
                    name="userSubtype"
                    component="div"
                    className="text-red-500 mt-1"
                  />
                </div>
              </div>
              <div className="md:grid grid-cols-2 gap-8 my-10">
                <div>
                  <h3 className="mt-4 mb-2 font-bold">Name</h3>
                  <Field
                    type="text"
                    name="fullName"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Full Name"
                  />
                  <ErrorMessage
                    name="fullName"
                    component="div"
                    className="text-red-500"
                  />

                  <h3 className="mt-4 mb-2 font-bold">Email</h3>
                  <Field
                    type="text"
                    name="email"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="email@company.com"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-red-500"
                  />

                  {status === 'create' && (
                    <>
                      <h3 className="mt-4 mb-2 font-bold">Username</h3>
                      <Field
                        type="text"
                        name="userName"
                        className="rounded-md w-full py-1 px-2"
                        placeholder="Username"
                      />
                      <ErrorMessage
                        name="userName"
                        component="div"
                        className="text-red-500"
                      />
                    </>
                  )}
                </div>
                <div>
                  <h3 className="mt-4 mb-2 font-bold">Phone</h3>
                  <Field
                    type="text"
                    name="phoneNumber"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="(xxx)xxx-xxxx"
                  />
                  <ErrorMessage
                    name="phoneNumber"
                    component="div"
                    className="text-red-500"
                  />

                  <h3 className="mt-4 mb-2 font-bold">Title/Role</h3>
                  <Field
                    type="text"
                    name="title"
                    className="rounded-md w-full py-1 px-2"
                    placeholder="Title/Role"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="text-red-500"
                  />
                </div>
              </div>
              <div className="flex justify-around">
                <button
                  className="rounded-md px-4 py-2 text-sm flex justify-center items-center"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isCreateLoading ? (
                    <Loader classNames="w-5 h-5" />
                  ) : status === 'edit' ? (
                    'Save'
                  ) : (
                    'Create User'
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default UserForm;
