import { BsTreeFill } from 'react-icons/bs';

function Trees({ treesCount }) {
  return (
    <div className="flex flex-col justify-between items-center h-full text-center">
      <BsTreeFill className="grow treeIcon" size={120} />
      <h2 className="text-4xl font-medium color-primary">{treesCount}</h2>
    </div>
  );
}

export default Trees;
