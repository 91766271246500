// React
import React, { useState } from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import assetRealTimeDataOperations from '../../../redux/assetRealTimeData/assetRealTimeDataOperations';
import { assetRealTimeDataSelectors } from '../../../redux/assetRealTimeData/assetRealTimeDataSelectors';

// Formik
import {
  Formik,
  Field,
  ErrorMessage,
  Form
} from 'formik';

// Validation schema
import CreateChargeEventSchema from './CreateChargeEventSchema';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// api
import api from '../../../api/axios.config';

// Components
import Loader from '../../Loader';
import ChargingEvents from '../../widgets/ChargingEvents';

// CustomDatePicker
import CustomDatePicker from '../../CustomDatePicker';

const CreateChargeEvent = (
  {
    assetName,
    toggleModal,
    userDetails,
    dateFilterEvents,
    chargeStartDateTime,
    chargeEndDateTime,
    toggleDeleteChargingEventModal,
    setActiveChargingEventID,
    enableDPS,
    assetDifferenceFromUtc,
  }
) => {
  const [events, setEvents] = useState('');
  const [isValidateLoading, setIsValidateLoading] = useState(false);

  const initialValues = {
    powerLevel: '',
    targetSOC: '100',
    startDate: '',
    endDate: '',
    startHour: '',
    startMinute: '',
    endHour: '',
    endMinute: '',
    days: [],
  };

  const isChargingEventsLoading = useSelector(
    assetRealTimeDataSelectors.getAddChargingEventsLoading
  );

  // dispatch
  const dispatch = useDispatch();

  function hourArray() {
    let array = [];
    for (let i = 0; i < 24; i++) {
      array.push(i.toString().padStart(2, 0));
    }
    return array;
  }

  function minutesArray() {
    let array = [];
    for (let i = 0; i < 60; i++) {
      array.push(i.toString().padStart(2, 0));
    }
    return array;
  }

  // Array of days
  const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

  // Get username
  const username = localStorage.getItem('cdnzUser');

  // Get token
  const token = localStorage.getItem('cdnzAccessToken');

  const fetchBlockedChargingEvents = (body) => {
    setIsValidateLoading(true);
    api.post(
      `/device/charging-events/blocked`,
      body,
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setEvents(res.data.blockedEventsListData);
        setIsValidateLoading(false);
      })
      .catch(() => {
        setIsValidateLoading(false);
        Notify.failure('Failed to get Blocked Charging Events. Please try later.', notifyOptions);
      });
  }

  const handleSubmit = async (values, { setSubmitting, setErrors }) => {
    const startTime = `${values.startHour}:${values.startMinute}:00`;
    const endTime = `${values.endHour}:${values.endMinute}:00`;

    // Use padStart to ensure day part has two digits
    const startDateParts = values.startDate.split('-');
    const endDateParts = values.endDate.split('-');

    const startDateStr = `${startDateParts[0]}-${startDateParts[1].padStart(2, '0')}-${startDateParts[2].padStart(2, '0')}T${startTime}Z`;
    const endDateStr = `${endDateParts[0]}-${endDateParts[1].padStart(2, '0')}-${endDateParts[2].padStart(2, '0')}T${endTime}Z`;

    const startDateObj = new Date(startDateStr);
    const endDateObj = new Date(endDateStr);

    const now = new Date();
    const startDateObjAssetTime = new Date(startDateObj.getTime() + now.getTimezoneOffset() * 60000);
    const nowObjAssetTime = new Date(now.getTime() - (- now.getTimezoneOffset() - assetDifferenceFromUtc) * 60000);
    // Check if startDate is greater than now
    if (nowObjAssetTime >= startDateObjAssetTime) {
      setErrors({
        startDate: 'You cannot create an event in the past',
      });
      setSubmitting(false);
      return;
    }

    // Adjust for asset time zone offset
    startDateObj.setMinutes(startDateObj.getMinutes() - assetDifferenceFromUtc);
    endDateObj.setMinutes(endDateObj.getMinutes() - assetDifferenceFromUtc);

    const startDateUTC = startDateObj.toUTCString();
    const endDateUTC = endDateObj.toUTCString();

    // Check if endDate is greater than startDate
    if (endDateObj <= startDateObj) {
      setErrors({
        endDate: 'End date must be after the start date',
      });
      setSubmitting(false);
      return;
    }

    const daysValues = {};
    daysOfWeek.forEach(day => {
      daysValues[day] = values.days.includes(day) ? 1 : 0;
    });

    let bodyRequest = {
      eventType: enableDPS ? 'timeblock' : 'charge',
      startDatetime: startDateUTC,
      endDatetime: endDateUTC,
      assetName: assetName,
      userSubType: userDetails.userSubtype,
      offSet: assetDifferenceFromUtc,
      ...daysValues,
    };

    if (!enableDPS) {
      bodyRequest = {
        ...bodyRequest,
        targetSoc: values.targetSOC,
        powerLevel: values.powerLevel,
      };
    }

    fetchBlockedChargingEvents(bodyRequest);

    if (events && events.length === 0) {
      dispatch(
        assetRealTimeDataOperations.addChargingEvent({
          bodyRequest,
          toggleModal: toggleModal,
        })
      );

      const performDateFiltering = () => {
        dateFilterEvents('chargingEvents', chargeStartDateTime, chargeEndDateTime);
      };

      setTimeout(performDateFiltering, 1000);

      setSubmitting(false);
    }
  };

  const handleBlur = () => {
    setEvents('');
  }

  const [hours] = useState(hourArray);
  const [minutes] = useState(minutesArray);

  return (
    <>
      <div>
        <h1 className="text-2xl font-bold text-center mb-6">
          {enableDPS ? "Create a New Timeblock" : "Create a New Charge Event"}
        </h1>

        {!!assetName && (
          <Formik
            initialValues={initialValues}
            validationSchema={CreateChargeEventSchema(enableDPS)}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting }) => (
              <Form className="flex flex-col mb-6">
                {!enableDPS && (
                  <div className="md:grid grid-cols-2 gap-8">
                    <div className="dr-event-container">
                      <h3 className="mt-4 mb-2 font-bold">Power Level (kW)</h3>
                      <Field
                        type="text"
                        name="powerLevel"
                        className="rounded-md w-full py-1 px-2"
                        placeholder="Power Level"
                      />
                      <ErrorMessage
                        name="powerLevel"
                        component="div"
                        className="text-red-500 mt-1"
                      />
                    </div>
                    <div className="dr-event-container">
                      <h3 className="mt-4 mb-2 font-bold">Target SOC (%)</h3>
                      <Field
                        type="text"
                        name="targetSOC"
                        className="rounded-md w-full py-1 px-2"
                        placeholder="Target SOC"
                        readOnly
                      />
                      <ErrorMessage
                        name="targetSOC"
                        component="div"
                        className="text-red-500 mt-1"
                      />
                    </div>
                  </div>
                )}
                <div className="md:grid grid-cols-2 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Start Time</h3>
                    <div className="flex">
                      <div className="w-1/2 mr-2">
                        <Field
                          as="select"
                          name="startHour"
                          className="rounded-md w-full py-1 px-2"
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="w-1/2 ml-2">
                        <Field
                          as="select"
                          name="startMinute"
                          className="rounded-md w-full py-1 px-2"
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage
                      name="startHour"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                    <ErrorMessage
                      name="startMinute"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">End Time</h3>
                    <div className="flex">
                      <div className="w-1/2 mr-2">
                        <Field
                          as="select"
                          name="endHour"
                          className="rounded-md w-full py-1 px-2"
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {hours.map(hour => (
                            <option key={hour} value={hour}>
                              {hour}
                            </option>
                          ))}
                        </Field>
                      </div>
                      <div className="w-1/2 ml-2">
                        <Field
                          as="select"
                          name="endMinute"
                          className="rounded-md w-full py-1 px-2"
                          onBlur={handleBlur}
                        >
                          <option value="" disabled>
                            Select..
                          </option>
                          {minutes.map(minute => (
                            <option key={minute} value={minute}>
                              {minute}
                            </option>
                          ))}
                        </Field>
                      </div>
                    </div>
                    <ErrorMessage
                      name="endHour"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                    <ErrorMessage
                      name="endMinute"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="md:grid grid-cols-2 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Start Date</h3>
                    <CustomDatePicker name="startDate" handleBlur={handleBlur} />
                    <ErrorMessage
                      name="startDate"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">End Date</h3>
                    <CustomDatePicker name="endDate" handleBlur={handleBlur} />
                    <ErrorMessage
                      name="endDate"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>
                <div className="md:grid grid-cols-1 gap-8">
                  <div className="dr-event-container">
                    <h3 className="mt-4 mb-2 font-bold">Days</h3>
                    <div className="flex flex-wrap gap-4">
                      {daysOfWeek.map(day => (
                        <label key={day} className="flex items-center gap-2">
                          <Field
                            type="checkbox"
                            name="days"
                            value={day}
                            className="mr-2"
                            style={{ margin: '0', padding: '0' }}
                            onBlur={handleBlur}
                          />
                          <span style={{ margin: '0', padding: '0' }}>{day}</span>
                        </label>
                      ))}
                      <div className="flex justify-end ml-auto flex-col md:flex-row">
                        <button
                          type="submit"
                          className="rounded-md px-4 py-2 text-sm mb-4 md:mb-0 flex justify-center items-center"
                          disabled={isSubmitting}
                        >
                          {isChargingEventsLoading || isValidateLoading ? (
                            <Loader classNames="w-5 h-5" />
                          ) : (
                            events && events.length === 0 ? 'Save' : 'Validate'
                          )}
                        </button>
                      </div>
                    </div>
                    <ErrorMessage
                      name="days"
                      component="div"
                      className="text-red-500 mt-1"
                    />
                  </div>
                </div>

                {events && events.length !== 0 && (
                  <ChargingEvents
                    events={events}
                    toggleDeleteChargingEventModal={toggleDeleteChargingEventModal}
                    setActiveChargingEventID={setActiveChargingEventID}
                    assetDifferenceFromUtc={assetDifferenceFromUtc}
                  />
                )}

              </Form>
            )}
          </Formik>
        )}
      </div>
    </>
  );
};

export default CreateChargeEvent;