import React, { useEffect, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

// Assets
// import { FiX } from 'react-icons/fi';

// Widget Components
import Widget  from '../widgets/Widget';
import AssetList from '../widgets/AssetList';
import Power from '../widgets/Power';
import FleetStatus from '../widgets/FleetStatus';
import Energy from '../widgets/Energy';
import Trees from '../widgets/TreesSaved';
import ScheduledEvents from '../widgets/ScheduledEvents';
import AssetLocations from '../widgets/AssetLocations';
import AssetListFaults from '../widgets/AssetListFaults';
import AssetListUnassigned from '../widgets/AssetListUnassigned';

// Components

const DashboardSelect = props => {
  const ResponsiveGridLayout = WidthProvider(Responsive);

  const [layout, setLayout] = useState(props.data.dashboardLayout);
  const [dashboardData, setDashboardData] = useState(props.data);
  // const [power, setPower] = useState(props.data.power);
  // const [energy, setEnergy] = useState(props.data.energy);
  const [events, setEvents] = useState(props.data.events);
  const [assets, setAssets] = useState(props.data.assetList);
  const [hasHidden, setHasHidden] = useState(true);

  if (null) {
    setLayout();
    setEvents();
    setAssets();
    setDashboardData();
  }

  // Turn widget IDs into human-readable titles
  function idToText(str) {
    if (str === 'treesSaved') {
      return 'Virtual Trees';
    }

    let result = str[0].toUpperCase();

    for (let i = 1; i < str.length; i++) {
      if (str[i].match(/[A-Z]/) && str[i - 1].match(/[a-z]/)) {
        result += ` ${str[i]}`;
      } else if (
        str[i].match(/[A-Z]/) &&
        str[i - 1].match(/[A-Z]/) &&
        str[i + 1] &&
        str[i + 1].match(/[a-z]/)
      ) {
        result += ` ${str[i]}`;
      } else {
        result += str[i];
      }
    }

    return result;
  }

  useEffect(() => {
    setHasHidden(layout.some(item => item.hidden === true));
  }, [layout]);

  //   const dateFilter = (element, startDate /*endDate*/) => {
  //     if (!element || !startDate /*|| !endDate*/) return;

  //     // let response;

  //     const convertDate = date => {
  //       if (!date || typeof date !== 'string')
  //         throw new Error('date must be a string');
  //       let newDate = new Date(date);
  //       return newDate.toISOString();
  //     };

  //     const formattedStartDate = convertDate(startDate);
  //     const formattedEndDate = convertDate(startDate).replace(
  //       /\d{2}:\d{2}:\d{2}\.\d{3}Z/,
  //       '23:59:59.999Z'
  //     );
  //     //const formattedEndDate = convertDate(endDate);

  //     fetch(`${window.$baseURL}/dashboard/${element}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //       },
  //       body: JSON.stringify({
  //         startDate: formattedStartDate,
  //         endDate: formattedEndDate,
  //       }),
  //     })
  //       .then(res => res.json())
  //       .then(data => {
  //         if (element === 'power') {
  //           setDashboardData({
  //             ...dashboardData,
  //             power: data.power,
  //             powerStartDate: startDate,
  //             //powerEndDate: endDate
  //           });

  //         }
  //         if (element === 'energy') {
  //           setDashboardData({
  //             ...dashboardData,
  //             energy: data.energy,
  //             energyStartDate: startDate,
  //             //energyEndDate: endDate
  //           });
  //         }
  //       });
  //   };

  if (hasHidden) {
    return (
      // <div className="h-[1500px]" />
      <div className="flex flex-col items-center my-6 max-h-full">
        <ResponsiveGridLayout
          style={{ minWidth: '100%', maxHeight: '100%' }}
          className="layout2 max-h-full"
          layouts={{ lg: layout }}
          breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
          cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
          measureBeforeMount={true}
          rowHeight={30}
          margin={[15, 15]}
          useCSSTransforms={false}
        >
          {layout?.map(element => {
            if (!!element.hidden) {
              // Make a human readable title
              const title = idToText(element.i);

              // Inject child compenents dynamically

              let componentSwitch;
              //   let filter;
              //   let widgetDateFilter;
              //   let widgetDateRange;
              //   let createEvent;

              switch (element.i) {
                case 'assetList':
                  componentSwitch = <AssetList />;
                  break;
                case 'accountPower':
                  //   widgetDateFilter = dateFilter;
                  componentSwitch = <Power power={dashboardData.power} />;
                  break;
                case 'assetFaults':
                  //   filter = true;
                  componentSwitch = <AssetListFaults />;
                  break;
                case 'fleetStatus':
                  componentSwitch = <FleetStatus />;
                  break;
                case 'accountEnergy':
                  //   widgetDateFilter = dateFilter;
                  componentSwitch = <Energy energy={dashboardData.energy} />;
                  break;
                case 'treesSaved':
                  componentSwitch = <Trees />;
                  break;
                case 'scheduledDREvents':
                  //   createEvent = true;
                  componentSwitch = <ScheduledEvents events={events} />;
                  break;
                case 'assetLocations':
                  componentSwitch = <AssetLocations />;
                  break;
                case 'AssetListUnassigned':
                  //   filter = true;
                  componentSwitch = <AssetListUnassigned />;
                  break;
                default:
                  componentSwitch = null;
                  break;
              }

              return (
                <div
                  key={element.i}
                  className="container-border grid-component__container overflow-hidden"
                >
                  <Widget
                    title={title}
                    id={element.i}
                    addButton={true}
                    toggleHideWidget={props.toggleHideWidget}
                    child={componentSwitch}
                    assets={assets}
                  />
                </div>
              );
            }

            return null;
          })}
        </ResponsiveGridLayout>
      </div>
    );
  }
  return <div>There are no hidden widgets.</div>;
};

export default DashboardSelect;
