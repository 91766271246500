import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { FilterProvider } from '../context/FilterContext';

// Components
import HomeContainer from '../components/containers/HomeContainer';
import Loader from '../components/Loader';

// Api
import api from '../api/axios.config';

const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

const fetcher = async url => {
  const { data } = await api.get(url, {
    headers: {
      username: username,
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

function Home() {
  const [dashboardData, setDashboardData] = useState(null);
  const [dashboardError, setDashboardError] = useState(null);

  const getData = async () => {
    const { data } = await api.get('/device/dashboard', {
      headers: {
        username: username,
        Authorization: `Bearer ${token}`,
      },
    });

    setDashboardData(data);
  };

  useEffect(() => {
    try {
      getData();
    } catch (error) {
      setDashboardError(error);
    }
  }, []);

  const { data: accountsData, error: accountsError } = useSWR(
    '/accounts',
    fetcher
  );

  return (
    <div>
      {dashboardError || accountsError ? (
        <p>Error fetching data</p>
      ) : !dashboardData || !accountsData ? (
        <div className="loader-global-container">
          <Loader classNames="w-32 h-32" />
        </div>
      ) : (
        <FilterProvider>
          <HomeContainer
            dashboardData={dashboardData}
            accountsData={accountsData.account.accountList}
            getDashboardData={getData}
          />
        </FilterProvider>
      )}
    </div>
  );
}

export default Home;
