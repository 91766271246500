// Redux
import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

// Operations
import dashboardDataOperations from './dashboardDataOperation';

const initialState = {
  data: {
    assetList: {
      data: [],
      loading: false,
      error: null,
      currentAsset: {
        data: {},
        loading: false,
        error: null,
      },
    },
    dashboardLayout: {
      data: [],
      loading: false,
      error: null,
    },
    events: {
      data: [],
      filter: null,
      loading: false,
      error: null,
      addEvent: {
        loading: false,
        error: null,
      },
    },
    treesCount: {
      data: 0,
      loading: false,
      error: null,
    },
  },
  loading: false,
  error: null,
};

export const dashboardDataSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    setFilterEvents: {
      reducer(state, { payload }) {
        state.data.events.filter = payload;
      },
    },
  },
  extraReducers: builder => {
    builder
      // GET ALL DASHBOARD DATA (except charts)
      .addCase(dashboardDataOperations.getAllDashboardData.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        dashboardDataOperations.getAllDashboardData.rejected,
        (state, { payload }) => {
          state.loading = false;
          state.error = payload;
        }
      )
      .addCase(
        dashboardDataOperations.getAllDashboardData.fulfilled,
        (state, { payload }) => {
          state.data.assetList.data = payload.assetList;
          state.data.dashboardLayout.data = payload.dashboardLayout;
          state.data.events.data = payload.events;
          state.data.treesCount.data = payload.treesCount;

          state.loading = false;
        }
      )

      // GET SCHEDULE EVENTS
      .addCase(dashboardDataOperations.getScheduledEvent.pending, state => {
        state.data.events.loading = true;
        state.data.events.error = null;
      })
      .addCase(
        dashboardDataOperations.getScheduledEvent.rejected,
        (state, { payload }) => {
          state.data.events.loading = false;
          state.data.events.error = payload;
        }
      )
      .addCase(
        dashboardDataOperations.getScheduledEvent.fulfilled,
        (state, { payload }) => {
          state.data.events.data = payload;

          state.data.events.loading = false;
        }
      )

      // ADD SCHEDULE EVENTS
      .addCase(dashboardDataOperations.addScheduledEvent.pending, state => {
        state.data.events.addEvent.loading = true;
        state.data.events.addEvent.error = null;
      })
      .addCase(
        dashboardDataOperations.addScheduledEvent.rejected,
        (state, { payload }) => {
          state.data.events.addEvent.loading = false;
          state.data.events.addEvent.error = payload;
        }
      )
      .addCase(
        dashboardDataOperations.addScheduledEvent.fulfilled,
        (state, _) => {
          state.data.events.addEvent.loading = false;
        }
      )

      // GET CURRENT ASSET
      .addCase(dashboardDataOperations.getCurrentAsset.pending, state => {
        state.data.assetList.currentAsset.loading = true;
        state.data.assetList.currentAsset.error = null;
      })
      .addCase(
        dashboardDataOperations.getCurrentAsset.rejected,
        (state, { payload }) => {
          state.data.assetList.currentAsset.loading = false;
          state.data.assetList.currentAsset.error = payload;
        }
      )
      .addCase(
        dashboardDataOperations.getCurrentAsset.fulfilled,
        (state, { payload }) => {
          state.data.assetList.currentAsset.data = payload;

          state.data.assetList.currentAsset.loading = false;
        }
      );
  },
});

const persistConfig = {
  key: 'cadenza/dashboardData',
  storage,
};

export const { setFilterEvents } = dashboardDataSlice.actions;

export const persistedDashboardDataReducer = persistReducer(
  persistConfig,
  dashboardDataSlice.reducer
);
