// React
import React from 'react';

// Redux
import { useDispatch, useSelector } from 'react-redux';
import { assetRealTimeDataSelectors } from '../../../redux/assetRealTimeData/assetRealTimeDataSelectors';
import assetRealTimeDataOperations from '../../../redux/assetRealTimeData/assetRealTimeDataOperations';

// Components
import Loader from '../../Loader';

export default function DeleteChargingEvent(
  {
    toggleModal,
    activeChargingEventID,
    dateFilterEvents,
    chargeStartDateTime,
    chargeEndDateTime,
    assetName
  }
) {
  const isLoadingDeleteChargingEvent = useSelector(
    assetRealTimeDataSelectors.getDeleteChargingEventLoading
  );

  const dispatch = useDispatch();

  // Handlers
  const handleDeleteChargingEventConfirm = () => {
    const bodyRequest = {
      eventId: activeChargingEventID,
      remarks: "deleted",
      assetName
    }

    dispatch(
      assetRealTimeDataOperations.deleteChargingEvent({
        toggleModal,
        bodyRequest
      })
    );

    const performDateFiltering = () => {
      dateFilterEvents('chargingEvents', chargeStartDateTime, chargeEndDateTime);
    };

    setTimeout(performDateFiltering, 1000);
  };

  const handleDeleteChargingEventReject = () => {
    toggleModal();
  };

  return (
    <div className="flex flex-col items-center gap-6">
      <h1 className="text-3xl font-bold text-center py-28">
        {`Are you sure you want to delete charging event?`}
      </h1>
      <div className="flex flex-col justify-center gap-4 md:flex-row">
        <button
          className="rounded-md py-2 text-sm flex justify-center"
          onClick={handleDeleteChargingEventConfirm}
        >
          {isLoadingDeleteChargingEvent ? <Loader classNames="w-5 h-5" /> : 'Yes'}
        </button>
        <button
          className="rounded-md py-2 text-sm"
          onClick={handleDeleteChargingEventReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
