import { createSlice } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import userOperations from './userOperations';

const initialState = {
  userDetails: {
    data: {},
    loading: false,
    error: null,
  },
  userCreate: {
    loading: false,
    error: null,
  },
  userUpdate: {
    loading: false,
    error: null,
  },
  userDeactivate: {
    loading: false,
    error: null,
  },
  loading: false,
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: builder => {
    builder
      // GET USER DETAILS
      .addCase(userOperations.getUserDetails.pending, state => {
        state.userDetails.loading = true;
        state.userDetails.error = null;
      })
      .addCase(userOperations.getUserDetails.rejected, (state, { payload }) => {
        state.userDetails.loading = false;
        state.userDetails.error = payload;
      })
      .addCase(
        userOperations.getUserDetails.fulfilled,
        (state, { payload }) => {
          state.userDetails.data = payload;
          state.userDetails.loading = false;
        }
      )

      // CREATE USER
      .addCase(userOperations.createUser.pending, state => {
        state.userCreate.loading = true;
        state.userCreate.error = null;
      })
      .addCase(userOperations.createUser.rejected, (state, { payload }) => {
        state.userCreate.loading = false;
        state.userCreate.error = payload;
      })
      .addCase(userOperations.createUser.fulfilled, state => {
        state.userCreate.loading = false;
      })

      // UPDATE USER
      .addCase(userOperations.updateUserDetails.pending, state => {
        state.userUpdate.loading = true;
        state.userUpdate.error = null;
      })
      .addCase(
        userOperations.updateUserDetails.rejected,
        (state, { payload }) => {
          state.userUpdate.loading = false;
          state.userUpdate.error = payload;
        }
      )
      .addCase(userOperations.updateUserDetails.fulfilled, state => {
        state.userUpdate.loading = false;
      })

      // DEACTIVATE USER
      .addCase(userOperations.deactivateUser.pending, state => {
        state.userDeactivate.loading = true;
        state.userDeactivate.error = null;
      })
      .addCase(userOperations.deactivateUser.rejected, (state, { payload }) => {
        state.userDeactivate.loading = false;
        state.userDeactivate.error = payload;
      })
      .addCase(userOperations.deactivateUser.fulfilled, state => {
        state.userDeactivate.loading = false;
      });
  },
});

const persistConfig = {
  key: 'cadenza/user',
  storage,
};

export const persistedUserReducer = persistReducer(
  persistConfig,
  userSlice.reducer
);
