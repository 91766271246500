import isPlatformAdministrator from './isPlatformAdministrator';
import isClientAdministrator from './isClientAdministrator';
import isClientUser from './isClientUser';
import isResident from './isResident';
import isAdministrator from './isAdministrator';

const accessControl = {
  isPlatformAdministrator,
  isClientAdministrator,
  isClientUser,
  isResident,
  isAdministrator,
};

export default accessControl;
