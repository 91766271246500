// Redux
import { createAsyncThunk } from '@reduxjs/toolkit';

// Notify
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';

// Helpers
import { axiosBaseUrl } from '../tokenSettingsAxios';

const getAllDashboardData = createAsyncThunk(
  'dashboardData/getAll',
  async (_, { rejectWithValue }) => {
    try {
      // Get all dashboard data
      const { data } = await axiosBaseUrl.get('device/dashboard');
      return data;
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

// Events
const addScheduledEvent = createAsyncThunk(
  'dashboardData/addScheduledEvent',
  async ({ bodyRequest, toggleModal }, { rejectWithValue, dispatch }) => {
    try {
      // add scheduled event
      await axiosBaseUrl.post('device/dr-events', bodyRequest);

      // get all events
      await dispatch(getScheduledEvent());

      toggleModal();
      Notify.success('Scheduled event successfully added!', notifyOptions);
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const getScheduledEvent = createAsyncThunk(
  'dashboardData/getScheduledEvent',
  async (_, { rejectWithValue }) => {
    try {
      // get all events
      const { data } = await axiosBaseUrl.get('device/dr-events');

      return data.eventListData.eventList;
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

// Assets
const getCurrentAsset = createAsyncThunk(
  'dashboardData/getCurrentAsset',
  async ({ assetName }, { rejectWithValue }) => {
    try {
      // get current Asset
      const { data } = await axiosBaseUrl.get(`device/asset-data/${assetName}`);

      const payload = {
        assetDetails: data?.assetData?.assetDetail[0],
        addressData: data?.assetData?.metaData,
      };
      return payload;
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const updateCurrentAsset = createAsyncThunk(
  'dashboardData/updateCurrentAsset',
  async ({ formData }, { rejectWithValue, dispatch }) => {
    try {
      // update current Asset
      await axiosBaseUrl.put(`device/asset-data`, formData);

      dispatch(getCurrentAsset({ assetName: formData.assetName }));
      Notify.success('Asset details updated successfully!', notifyOptions);
    } catch (e) {
      Notify.failure('Something went wrong. Please try later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const getAssetsList = createAsyncThunk(
  'dashboardData/getAssetsList',
  async (_, { rejectWithValue }) => {
    try {
      // get Assets List data
      const { data } = await axiosBaseUrl.get(`device/assets`);

      return data;
    } catch (e) {
      Notify.failure('Something went wrong. Please try get asset list later.', notifyOptions);
      return rejectWithValue(e.message);
    }
  }
);

const dashboardDataOperations = {
  getAllDashboardData,

  // events
  addScheduledEvent,
  getScheduledEvent,

  // assets
  getCurrentAsset,
  updateCurrentAsset,
  getAssetsList,
};

export default dashboardDataOperations;
