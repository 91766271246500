import React, { useState } from 'react';
import api from '../../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

export default function UnenrollAssetFromProgram ({
  toggleModal,
  activeAssets,
  activeProgram,
  checkboxState,
  toggleCheckbox,
  setActiveAsset,
}) {
  const assetNames = activeAssets.join(', ');
  const [reason, setReason] = useState('');
  const isLoadingUnenroll = false;

  // Handlers
  const handleUnenrollConfirm = async () => {
    const username = localStorage.getItem('cdnzUser');
    const token = localStorage.getItem('cdnzAccessToken');

    const { data } = await api.post('/programs/unenroll', {
      data: {
        programKey: activeProgram.programKey,
        assetName: activeAssets,
        reason,
      }
    }, {
      headers: {
        username: username,
        Authorization: `Bearer ${token}`,
      },
    });

    if (data.status === 'success') {
      Notify.success(
        `The assets: ${assetNames} was successfully unenrolled from program ${activeProgram.programName}.`,
        notifyOptions,
      );
    }
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };

  const handleUnenrollReject = () => {
    if (checkboxState) {
      // Clear checkboxes and activeAssets
      Object.keys(checkboxState).forEach((bessName) => {
        toggleCheckbox(bessName, false); // Uncheck checkboxes
      });
      setActiveAsset([]); // Clear activeAssets
    }
    toggleModal();
  };
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  return (
    <div className='flex flex-col items-center gap-6'>
      <h1 className='text-3xl font-bold text-center py-10'>
        {`Are you sure you want to unenroll assets: "${assetNames}" from program "${activeProgram.programName}"?`}
      </h1>

      <div className='w-full'>
        <label htmlFor='reason'
               className='block text-gray-700 text-sm font-bold mb-2'>
          Reason:
        </label>
        <textarea
          id='reason'
          name='reason'
          className='resize-none rounded-md border w-full p-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
          rows='5'
          value={reason}
          onChange={handleReasonChange}
          required
        />
      </div>

      <div className='flex flex-col justify-center gap-4 md:flex-row'>
        <button
          className='rounded-md py-2 text-sm flex justify-center'
          onClick={handleUnenrollConfirm}
        >
          {isLoadingUnenroll ? 'Loading...' : 'Yes'}
        </button>
        <button
          className='rounded-md py-2 text-sm'
          onClick={handleUnenrollReject}
        >
          No
        </button>
      </div>
    </div>
  );
}
