import api from '../api/axios.config';
import ProgramContainer from '../components/containers/DR-ProgramsContainer';
import Loader from '../components/Loader';
import { useEffect, useState } from 'react';

const DRPrograms = () => {
  const [programs, setPrograms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const username = localStorage.getItem('cdnzUser');
      const token = localStorage.getItem('cdnzAccessToken');
      const { data } = await api.get('/programs', {
        headers: {
          username: username,
          Authorization: `Bearer ${token}`,
        },
      });

      setPrograms(data?.result || []);
      setIsLoading(false);

    } catch (error) {
      console.log('error', error);
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  const handleProgramListUpdates = () => {
    fetchData();
  };

  if (isLoading) {
    return (
      <div className='loader-global-container'>
        <Loader classNames='w-32 h-32' />
      </div>
    );
  }

  return <ProgramContainer programs={programs} onProgramListUpdates={handleProgramListUpdates} />;
};

export default DRPrograms;