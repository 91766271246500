import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { IconContext } from 'react-icons';
import { PiFileCsv, PiFilePng } from "react-icons/pi";
import { CSVLink } from "react-csv";
import { useCurrentPng } from 'recharts-to-png';
import FileSaver from 'file-saver';
import { memo, useEffect, useState, useCallback } from 'react';
import { getTicks, accountPowerTickFormatter, convertToDays } from '../../helpers/charts';
import formatRangeForFileNameLocalTime from '../../helpers/formatDate/formatRangeForFileNameLocalTime';
import { transformDataForCSVLocalTime } from '../../helpers/formatDate/transformDataForCSVLocalTime';
import { roundToNearestTwoDecimal } from '../../helpers/charts/roundToDecimal';
import api from '../../api/axios.config';
import { Notify } from 'notiflix';
import notifyOptions from '../../constants/notify.options';
import { getTimeZoneAbbreviation } from '../../helpers/formatDate/timeZone';

const localTimeZoneAbbreviation = getTimeZoneAbbreviation();

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const utcDate = new Date(label);
    const localDate = new Date(utcDate.getTime() - utcDate.getTimezoneOffset() * 60000);
    const formattedDate = `${localDate.getFullYear()}-`
      + `${String(localDate.getMonth() + 1).padStart(2, '0')}-`
      + `${String(localDate.getDate()).padStart(2, '0')} `
      + `${String(localDate.getHours()).padStart(2, '0')}:`
      + `${String(localDate.getMinutes()).padStart(2, '0')}`;

    const formatNumber = (value) => parseFloat(value).toFixed(2);

    return (
      <div className='custom-tooltip' style={{ backgroundColor: '#f4f4f4', padding: '10px', border: '1px solid #ccc' }}>
        <p className='label'>{`Timezone: ${localTimeZoneAbbreviation}`}</p>
        <p className='label'>{`Time: ${formattedDate}`}</p>
        <p className='intro'>{`Power: ${formatNumber(payload[0].value)} kW`}</p>
      </div>
    );
  }
  return null;
};

const Power = (
  {
    powerStartDate,
    powerEndDate,
    power,
  }
) => {
  const [powerData, setPowerData] = useState(power);
  const [getPng, { ref }] = useCurrentPng();

  const startDate = new Date(powerStartDate);
  const endDate = new Date(powerEndDate);
  const dateDifference = endDate - startDate;
  const dateDifferenceInDays = convertToDays(dateDifference);
  const xTicks = getTicks(powerData, dateDifferenceInDays);
  const username = localStorage.getItem('cdnzUser');

  useEffect(() => {
    const fetchNewPowerData = () => {
      const token = localStorage.getItem('cdnzAccessToken');
      api
        .post(
          `/device/power`,
          {
            startDate: startDate,
            endDate: endDate,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              username: username,
            },
          },
        )
        .then((res) => {
          const lastExistingTimestamp = powerData.length ? powerData[powerData.length - 1].timestamp : null;
          const newData = res.data.powerData.power.filter(data => {
            return !lastExistingTimestamp || data.timestamp > lastExistingTimestamp;
          });
          if (newData.length > 0) {
            setPowerData([...powerData, ...newData]);
          }
        })
        .catch((error) => {
          console.error('Error fetching new power data:', error);
          Notify.failure('Something went wrong. Please try later.', notifyOptions);
        });
    };
    const intervalId = setInterval(fetchNewPowerData, 60000);

    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let dummyData = [];

  if (!powerData || !Array.isArray(powerData) || powerData.length === 0) {
    let endTime;
    const currentDate = new Date();
    const currentDay = currentDate.toDateString();

    const powerEndDateString = new Date(powerEndDate).toDateString();

    if (powerEndDateString === currentDay) {
      endTime = new Date();
    } else {
      endTime = new Date(powerEndDate);
      endTime.setHours(23, 59, 59, 999);
    }

    // Calculate the number of half-hour segments between powerStartDate and endTime
    const startTime = new Date(powerStartDate);
    const segmentCount = Math.ceil((endTime - startTime) / (30 * 60 * 1000));

    // Create an array of objects with timestamps for each half-hour segment
    for (let i = 0; i < segmentCount; i++) {
      const timestamp = new Date(startTime.getTime() + i * 30 * 60 * 1000 + startTime.getTimezoneOffset() * 60000);

      const formattedTimestamp =
        `${timestamp.getFullYear()}-${(timestamp.getMonth() + 1).toString().padStart(2, '0')}-${timestamp.getDate().toString().padStart(2, '0')} ` +
        `${timestamp.getHours().toString().padStart(2, '0')}:${timestamp.getMinutes().toString().padStart(2, '0')}`;

      dummyData.push({
        timestamp: formattedTimestamp,
        SUM_value: 0,
      });
    }
  }

  const showData = (!powerData || !Array.isArray(powerData) || powerData.length === 0) ? dummyData : powerData;

  const rangeForFileName = formatRangeForFileNameLocalTime(new Date(powerStartDate), new Date(powerEndDate));

  const handleDownload = useCallback(async () => {
    const png = await getPng();

    if (png) {
      // Download with FileSaver
      FileSaver.saveAs(png, `Account power ${rangeForFileName}.png`);
    }
  }, [getPng, rangeForFileName]);

  const transformedData = transformDataForCSVLocalTime(showData, ['SUM_value']);

  return (
    <>
      {(
        <ResponsiveContainer>
          <AreaChart
            ref={ref}
            width={500}
            height={400}
            data={showData}
            margin={{
              top: 10,
              right: 30,
              left: 20,
              bottom: 0
            }}
          >
            <defs>
              <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                <stop offset='5%' stopColor='#0f3052' stopOpacity={0.8} />
                <stop offset='95%' stopColor='#0f3052' stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis
              dataKey='timestamp'
              ticks={xTicks}
              tickFormatter={(timestamp) => accountPowerTickFormatter(timestamp, dateDifference)}
            />
            <YAxis
              label={{
                value: 'kW',
                position: 'insideLeft',
                angle: -90
              }}
              tickFormatter={(value) => roundToNearestTwoDecimal(value)}
            />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type='monotone'
              dataKey='SUM_value'
              stroke='#0f3052'
              fillOpacity={1}
              fill='url(#colorUv)'
              animateNewValues={true}
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CSVLink
          data={transformedData}
          filename={`Account power ${rangeForFileName}.csv`}
          className='csv-link'
        >
          <button
            type="button"
            className='save-as-csv rounded-md px-1 py-1 text-xs'
            title="Save as CSV"
          >
            <IconContext.Provider value={{ color: '#00000' }}>
              <PiFileCsv size={24} />
            </IconContext.Provider>
          </button>
        </CSVLink>
        <button
          type="button"
          className='save-as-png rounded-md px-1 py-1 text-xs'
          onClick={handleDownload}
          title="Save as PNG"
        >
          <IconContext.Provider value={{ color: '#00000' }}>
            <PiFilePng size={24} />
          </IconContext.Provider>
        </button>
      </div>
    </>
  );
};

export default memo(Power);
