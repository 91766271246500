import React from 'react';

const UserDetails = props => {
  if (!props.user) return <div>No user details available</div>;
  return (
    <div className="flex flex-col items-center my-6">
      <h1 className="text-2xl font-bold text-center mb-6">View User</h1>
      <div className="md:grid grid-cols-2 gap-8 w-4/5">
        <div className="account-container static">
          <h3 className="mt-4 mb-2 font-bold">Account Type</h3>
          <p className="font-medium">{props.user.accountType}</p>

          <h3 className="mt-4 mb-2 font-bold">Account Name</h3>
          <p className="font-medium">{props.user.accountName}</p>
        </div>
        <div className="account-container static">
          <h3 className="mt-4 mb-2 font-bold">User Type</h3>
          <p className="font-medium">{props.user.userSubtype}</p>

          {/*<h3 className="mt-4 mb-2 font-bold">Account Number</h3>*/}
          {/*<p className="font-medium">{props.user.accountID}</p>*/}
        </div>
      </div>
      <div className="md:grid grid-cols-2 gap-8 my-10 w-4/5">
        <div className="static">
          <h3 className="mt-4 mb-2 font-bold">Name</h3>
          <p className="font-medium">{props.user.fullName}</p>

          <h3 className="mt-4 mb-2 font-bold">Email</h3>
          <p className="font-medium">{props.user.email}</p>
        </div>
        <div className="static">
          <h3 className="mt-4 mb-2 font-bold">Phone</h3>
          <p className="font-medium">{props.user.phoneNumber}</p>

          <h3 className="mt-4 mb-2 font-bold">Title/Role</h3>
          <p className="font-medium">{props.user.title}</p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
