import React, { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';

// Assets
import { FiFilter, FiUserPlus } from 'react-icons/fi';

// Components
import Modal from '../modals/Modal';
import UserDetails from '../modals/user/UserDetails';
import UserForm from '../modals/user/UserForm';
import UserRow from '../tables/UserRow';
import SearchFilter from '../controls/SearchFilter';
import UserDeactivate from '../modals/user/UserDeactivate';
import isAdministrator from '../../helpers/accessControl/isAdministrator';

const UserContainer = ({
  users: initialUsers,
  accounts,
  refetchData,
}) => {
  // State
  const [showFilterControls, setShowFilterControls] = useState(false);
  const [showViewModal, setViewModal] = useState(false);
  const [showEditModal, setEditModal] = useState(false);
  const [showDeactivateModal, setDeactivateModal] = useState(false);
  const [showCreateModal, setCreateModal] = useState(false);
  const [users, setUsers] = useState(initialUsers);
  const [activeUser, setActiveUser] = useState();
  const [search, setSearch] = useState();

  // Update users when props.users change
  useEffect(() => {
    setUsers(initialUsers);
  }, [initialUsers]);

  // Hide filter form on click outside of form
  const hideFilterControls = e => {
    if (
      e.target.className !== 'filter-controls' &&
      e.target.parentNode.className !== 'filter-controls' &&
      !e.target.parentNode.closest('.filter-controls') &&
      showFilterControls
    ) {
      setShowFilterControls(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', hideFilterControls);
    document.addEventListener('touchstart', hideFilterControls);
    return () => {
      document.removeEventListener('mousedown', hideFilterControls);
      document.removeEventListener('touchstart', hideFilterControls);
    };

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilterControls]);

  // Manage toggle of modal display
  const toggleViewModal = () => {
    setViewModal(!showViewModal);
  };

  const toggleEditModal = () => {
    setEditModal(!showEditModal);
  };

  const toggleDeactivateModal = () => {
    setDeactivateModal(!showDeactivateModal);
  };

  const toggleCreateModal = () => {
    setCreateModal(!showCreateModal);
  };

  // Get the details of the target account
  const getActiveUserDetails = userName => {
    const activeUser = users.filter(user => {
      return user.userName === userName;
    });

    return activeUser[0];
  };

  // Independent assignment of target account
  const assignActiveUser = userName => {
    if (!userName) {
      const loginUser = localStorage.getItem('cdnzUser') ? localStorage.getItem('cdnzUser') : ''
      const data = getActiveUserDetails(loginUser);
      setActiveUser(data);
      return;
    }
    const data = getActiveUserDetails(userName);
    setActiveUser(data);
  };

  // Data update from form filter
  const updateData = data => {
    setUsers();
    setUsers(data);
  };

  // Search parameters
  const updateSearchLabel = search => {
    setSearch(search);
  };
  return (
    <main className="flex flex-col items-center p-5 grow mb-6 max-h-85vh">
      <div className="container flex flex-row justify-end items-center mt-2 mb-6 md:mt-2 md:mb-6">
        {!!search && (
          <div className="mr-6 mt-1 border rounded-full px-4 py-1 border-slate-500 color-primary">
            Searching for {search}
          </div>
        )}
        <div className="mr-4 mt-1 flex items-center relative filter-controls">
          <button
            type="button"
            onClick={() => {
              setShowFilterControls(!showFilterControls);
            }}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiFilter size={24} />
            </IconContext.Provider>
          </button>
          <div
            className={`${showFilterControls ? 'block' : 'hidden'} 
            container-border bg-white py-4 px-6 absolute top-6 right-2 md:top-0 md:right-0 md:mr-10 z-40`}
          >
            <SearchFilter
              initialData={users}
              updateSearchLabel={updateSearchLabel}
              updateData={updateData}
              params={
                [
                  'account-Name',
                  'user-Name',
                  'full-Name',
                  'user-Type',
                ] /*'title', 'email', 'user-Subtype'*/
              }
            />
          </div>
        </div>
        {isAdministrator() && (
          <div className="flex items-center">
            <button
              type="button"
              className="mr-4 mt-1"
              onClick={() => {
                assignActiveUser();
                // toggleEditModal();
                toggleCreateModal();
              }}
            >
              <IconContext.Provider value={{ color: '#0F3052' }}>
                <FiUserPlus size={24} />
              </IconContext.Provider>
            </button>
          </div>
        )}
      </div>
      <section className="container container-border bg-white overflow-auto">
        <table className="table-auto table-primary w-full relative">
          <thead className="table-head">
            <tr>
              <th className="table-head-item"></th>
              <th className="table-head-item">Account Name</th>
              <th className="table-head-item">Username</th>
              <th className="table-head-item">Name</th>
              <th className="table-head-item">User Type</th>
            </tr>
          </thead>
          <tbody>
            {users?.map(user => (
              <UserRow
                {...user}
                key={user.userName}
                toggleModal={toggleViewModal}
                toggleEditModal={toggleEditModal}
                toggleDeactivateModal={toggleDeactivateModal}
                assignActiveUser={assignActiveUser}
              />
            ))}
          </tbody>
        </table>
      </section>
      {!!showViewModal && (
        <Modal
          toggleModal={toggleViewModal}
          isSmall={true}
          child={<UserDetails user={activeUser} />}
        />
      )}
      {!!showEditModal && (
        <Modal
          toggleModal={toggleEditModal}
          isSmall={true}
          child={
            <UserForm
              status="edit"
              user={activeUser}
              accounts={accounts}
              toggleModal={toggleEditModal}
              refetchData={refetchData}
            />
          }
        />
      )}

      {!!showDeactivateModal && (
        <Modal
          toggleModal={toggleDeactivateModal}
          isSmall={true}
          child={
            <UserDeactivate
              activeUser={activeUser}
              toggleModal={toggleDeactivateModal}
              refetchData={refetchData}
            />
          }
        />
      )}
      {!!showCreateModal && (
        <Modal
          toggleModal={toggleCreateModal}
          isSmall={true}
          child={
            <UserForm
              status="create"
              user={activeUser}
              accounts={accounts}
              toggleModal={toggleCreateModal}
              refetchData={refetchData}
            />
          }
        />
      )}
    </main>
  );
};

export default UserContainer;
