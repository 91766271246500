// Function to format timestamp to local date and time
export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const localTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000 * 2);
  const formattedDate = localTime.toISOString().replace(/T/, ' ').replace(/\..+/, '').substring(0, 16);
  return formattedDate;
};

// Function to transform and format the data
export const transformDataForCSVLocalTime = (data, columnsToInclude = []) => {
  return data.map(item => {
    const transformedItem = {
      'UTC Time': item.timestamp,
      'Local Time': formatTimestamp(item.timestamp)
    };

    // Add specified columns to the transformed data
    columnsToInclude.forEach(column => {
      transformedItem[column] = item[column];
    });

    return transformedItem;
  });
};
