const formatRangeForFileNameLocalTime = (startDate, endDate) => {
  if (startDate && endDate) {
    // Format the start date
    const startDay = startDate.getDate().toString().padStart(2, '0');
    const startMonth = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const startYear = startDate.getFullYear();

    // Format the end date
    const endDay = endDate.getDate().toString().padStart(2, '0');
    const endMonth = (endDate.getMonth() + 1).toString().padStart(2, '0');
    const endYear = endDate.getFullYear();

    // Create the formatted date strings
    const formattedStartDate = `${startMonth}.${startDay}.${startYear}`;
    const formattedEndDate = `${endMonth}.${endDay}.${endYear}`;

    // Combine into the desired format
    const formattedDateRange = `${formattedStartDate} to ${formattedEndDate}`;

    return formattedDateRange;
  } else {
    return null;
  }
};

export default formatRangeForFileNameLocalTime
