// React
import React, { useState, useEffect } from 'react';

// Icons
import { FiPlus } from 'react-icons/fi';
import { IconContext } from 'react-icons';

// Notification
import { Notify } from 'notiflix';
import notifyOptions from '../../../constants/notify.options';

// Components
import BatteryModules from '../../tables/BatteryModules';
import BatteryModuleForm from './BatteryModuleForm';
import Modal from '../Modal';

// // api
import api from '../../../api/axios.config';

// User credentials
const username = localStorage.getItem('cdnzUser');
const token = localStorage.getItem('cdnzAccessToken');

function convertDate(inputDate) {
  if (!inputDate) {
    return '';
  }

  const date = new Date(inputDate);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const BessInformation = ({
  assetName,
  assetDetails,
}) => {
  const [batteryModules, setBatteryModules] = useState([]);
  const [cadenzaModules, setCadenzaModules] = useState([]);

  const [ccblData, setCcblData] = useState({});
  const [firewallData, setFirewallData] = useState({});
  const [energyMeterData, setEnergyMeterData] = useState({});
  const [switchData, setSwitchData] = useState({});
  const [inverter1Data, setInverter1Data] = useState({});
  const [inverter2Data, setInverter2Data] = useState({});
  const [inverter3Data, setInverter3Data] = useState({});

  useEffect(() => {
    fetchBatteryModules();
    fetchCadenzaModules();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Filter data for each category for cadenzaModules and invertors
    setCcblData(cadenzaModules.find(item => item.category === "CloudBox"));
    setFirewallData(cadenzaModules.find(item => item.category === "Firewall"));
    setEnergyMeterData(cadenzaModules.find(item => item.category === "EnergyMeter"));
    setSwitchData(cadenzaModules.find(item => item.category === "Switch"));
    setInverter1Data(cadenzaModules.find(item => item.category === "Inverter1"));
    setInverter2Data(cadenzaModules.find(item => item.category === "Inverter2"));
    setInverter3Data(cadenzaModules.find(item => item.category === "Inverter3"));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cadenzaModules]);

  // Fetch cadenza modules
  const fetchCadenzaModules = () => {
    api.post(
      `/device/cadenza-modules`,
      {
        assetName
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setCadenzaModules(res.data.modules);
      })
      .catch(() => {
        Notify.warning('Cadenza modules are not loaded. Please try later.', notifyOptions);
      });
  }

  // Fetch battery modules
  const fetchBatteryModules = () => {
    api.post(
      `/device/battery-modules`,
      {
        assetName
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        setBatteryModules(res.data.batteryModules);
      })
      .catch(() => {
        Notify.warning('Battery modules are not loaded. Please try later.', notifyOptions);
      });
  }

  // Update cadenza modules
  const updateCadenzaModules = (newValues) => {
    api.post(
      `/device/cadenza-modules/update`,
      {
        ...newValues
      },
      {
        headers: {
          authorization: `Bearer ${token}`,
          username: username,
        },
      },
    )
      .then(res => {
        if (res.data.status === 'success') {
          Notify.success(
            `Cadenza modules were updated successfully`,
            notifyOptions,
          );
          fetchBatteryModules();
        }
      })
      .catch(() => {
        Notify.warning('Cadenza modules are not updated. Please try later.', notifyOptions);
      });
  }

  const [showCreateModal, setShowCreateModal] = useState(false);

  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };

  const handleChangeInverter1 = (event) => {
    const { name, value } = event.target;
    setInverter1Data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeInverter2 = (event) => {
    const { name, value } = event.target;
    setInverter2Data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeInverter3 = (event) => {
    const { name, value } = event.target;
    setInverter3Data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeCcbl = (event) => {
    const { name, value } = event.target;
    setCcblData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeFirewall = (event) => {
    const { name, value } = event.target;
    setFirewallData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeEnergyMeter = (event) => {
    const { name, value } = event.target;
    setEnergyMeterData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeSwitch = (event) => {
    const { name, value } = event.target;
    setSwitchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleUpdateClick = () => {
    const updatedValues = {
      assetName,
      cloudBox: {
        category: ccblData?.category || "CloudBox",
        identifier: ccblData?.identifier || '',
        replacement_installation_date: ccblData?.replacement_installation_date || '',
        name: ccblData?.name || '',
        serial_number: ccblData?.serial_number || '',
        model_number: ccblData?.model_number || '',
        software_version: ccblData?.software_version || '',
        manufacturer: ccblData?.manufacturer || '',
      },
      energyMeter: {
        category: energyMeterData?.category || "EnergyMeter",
        identifier: energyMeterData?.identifier || '',
        replacement_installation_date: energyMeterData?.replacement_installation_date || '',
        name: energyMeterData?.name || '',
        serial_number: assetDetails?.wattnode1SerialNumber || '',
        model_number: energyMeterData?.model_number || '',
        software_version: assetDetails?.wattnode1Firware || '',
        manufacturer: energyMeterData?.manufacturer || '',
      },
      firewall: {
        category: firewallData?.category || "Firewall",
        identifier: firewallData?.identifier || '',
        replacement_installation_date: firewallData?.replacement_installation_date || '',
        name: firewallData?.name || '',
        serial_number: firewallData?.serial_number || '',
        model_number: firewallData?.model_number || '',
        software_version: firewallData?.software_version || '',
        manufacturer: firewallData?.manufacturer || '',
      },
      switches: {
        category: switchData?.category || "Switch",
        identifier: switchData?.identifier || '',
        replacement_installation_date: switchData?.replacement_installation_date || '',
        name: switchData?.name || '',
        serial_number: switchData?.serial_number || '',
        model_number: switchData?.model_number || '',
        software_version: switchData?.software_version || '',
        manufacturer: switchData?.manufacturer || '',
      },
      inverter1: {
        category: inverter1Data?.category || "Inverter1",
        identifier: inverter1Data?.identifier || '',
        replacement_installation_date: inverter1Data?.replacement_installation_date || '',
        name: inverter1Data?.name || '',
        serial_number: assetDetails?.inverter1SerialNumber || '',
        model_number: inverter1Data?.model_number || '',
        software_version: assetDetails?.inverter1Firware || '',
        manufacturer: inverter1Data?.manufacturer || '',
      },
      inverter2: {
        category: inverter2Data?.category || "Inverter2",
        identifier: inverter2Data?.identifier || '',
        replacement_installation_date: inverter2Data?.replacement_installation_date || '',
        name: inverter2Data?.name || '',
        serial_number: assetDetails?.inverter2SerialNumber || '',
        model_number: inverter2Data?.model_number || '',
        software_version: assetDetails?.inverter2Firware || '',
        manufacturer: inverter2Data?.manufacturer || '',
      },
      inverter3: {
        category: inverter3Data?.category || "Inverter3",
        identifier: inverter3Data?.identifier || '',
        replacement_installation_date: inverter3Data?.replacement_installation_date || '',
        name: inverter3Data?.name || '',
        serial_number: assetDetails?.inverter3SerialNumber || '',
        model_number: inverter3Data?.model_number || '',
        software_version: assetDetails?.inverter3Firware || '',
        manufacturer: inverter3Data?.manufacturer || '',
      }
    }

    updateCadenzaModules(updatedValues);
  };

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-bold text-center mb-6">Hardware Details</h2>

      <div className="flex flex-col items-start">
        {/* Section 1: Inverter Systems */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Inverter Systems</h3>
          <p>
            Gateway SN:
            <input
              type="text"
              name='gatewaySN'
              value={assetDetails?.gatewaySerialNumber || ''}
              readOnly
              className="rounded-md w-full py-1 px-2 bg-gray-200"
            />
          </p>
        </div>

        {/* Section 2: Inverters */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Inverters</h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <p>
                Serial Number:
                <input
                  type="text"
                  name='serialNumber'
                  value={assetDetails?.inverter1SerialNumber || ''}
                  readOnly
                  className="rounded-md w-full py-1 px-2 bg-gray-200"
                />
              </p>
              <p>
                Software Version:
                <input
                  type="text"
                  name='softwareVersion'
                  value={assetDetails?.inverter1Firware || ''}
                  readOnly
                  className="rounded-md w-full py-1 px-2 bg-gray-200"
                />
              </p>
              <p>
                Name:
                <input
                  type="text"
                  name='name'
                  value={inverter1Data?.name || ''}
                  onChange={handleChangeInverter1}
                  className="rounded-md w-full py-1 px-2"
                />
              </p>
              <p>
                Installation Date:
                <input
                  type="date"
                  name='replacement_installation_date'
                  value={convertDate(inverter1Data?.replacement_installation_date)}
                  onChange={handleChangeInverter1}
                  className="rounded-md w-full py-1 px-2"
                />
              </p>
            </div>

            {assetDetails.inverter2SerialNumber && assetDetails.inverter3SerialNumber && (
              <>
                <div>
                  <p>
                    Serial Number:
                    <input
                      type="text"
                      name='serialNumber'
                      value={assetDetails.inverter2SerialNumber || ''}
                      readOnly
                      className="rounded-md w-full py-1 px-2 bg-gray-200"
                    />
                  </p>
                  <p>
                    Software Version:
                    <input
                      type="text"
                      name='softwareVersion'
                      value={assetDetails?.inverter2Firware || ''}
                      readOnly
                      className="rounded-md w-full py-1 px-2 bg-gray-200"
                    />
                  </p>
                  <p>
                    Name:
                    <input
                      type="text"
                      name='name'
                      value={inverter2Data?.name || ''}
                      onChange={handleChangeInverter2}
                      className="rounded-md w-full py-1 px-2"
                    />
                  </p>
                  <p>
                    Installation Date:
                    <input
                      type="date"
                      name='replacement_installation_date'
                      value={convertDate(inverter1Data?.replacement_installation_date)}
                      onChange={handleChangeInverter2}
                      className="rounded-md w-full py-1 px-2"
                    />
                  </p>
                </div>

                <div>
                  <p>
                    Serial Number:
                    <input
                      type="text"
                      name='serialNumber'
                      value={assetDetails.inverter3SerialNumber || ''}
                      readOnly
                      className="rounded-md w-full py-1 px-2 bg-gray-200"
                    />
                  </p>
                  <p>
                    Software Version:
                    <input
                      type="text"
                      name='softwareVersion'
                      value={assetDetails?.inverter3Firware || ''}
                      readOnly
                      className="rounded-md w-full py-1 px-2 bg-gray-200"
                    />
                  </p>
                  <p>
                    Name:
                    <input
                      type="text"
                      name='name'
                      value={inverter3Data?.name || ''}
                      onChange={handleChangeInverter3}
                      className="rounded-md w-full py-1 px-2"
                    />
                  </p>
                  <p>
                    Installation Date:
                    <input
                      type="date"
                      name='replacement_installation_date'
                      value={convertDate(inverter1Data?.replacement_installation_date)}
                      onChange={handleChangeInverter3}
                      className="rounded-md w-full py-1 px-2"
                    />
                  </p>
                </div>
              </>
            )}
          </div>
        </div>

        {/* Section 3: Cadenza Systems */}
        <div className="mb-6">
          <h3 className="text-lg font-semibold mb-2">Cadenza Systems</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            <div className="mb-4">
              <h4 className="font-semibold mb-2">CCB</h4>
              <div>
                <p>
                  Serial Number:
                  <input
                    type="text"
                    name='serial_number'
                    value={ccblData?.serial_number || ''}
                    onChange={handleChangeCcbl}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Software Version:
                  <input
                    type="text"
                    name='software_version'
                    value={ccblData?.software_version || ''}
                    onChange={handleChangeCcbl}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Model Number:
                  <input
                    type="text"
                    name='model_number'
                    value={ccblData?.model_number || ''}
                    onChange={handleChangeCcbl}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Device Identifier:
                  <input
                    type="text"
                    name='identifier'
                    value={ccblData?.identifier || ''}
                    readOnly
                    className="rounded-md w-full py-1 px-2 bg-gray-200"
                  />
                </p>
                <p>
                  Manufacturer:
                  <input
                    type="text"
                    name='manufacturer'
                    value={ccblData?.manufacturer || ''}
                    onChange={handleChangeCcbl}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
              </div>

            </div>
            <div className="mb-4">
              <h4 className="font-semibold mb-2">Firewall</h4>
              <div>
                <p>
                  Serial Number:
                  <input
                    type="text"
                    name='serial_number'
                    value={firewallData?.serial_number || ''}
                    onChange={handleChangeFirewall}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Software Version:
                  <input
                    type="text"
                    name='software_version'
                    value={firewallData?.software_version || ''}
                    onChange={handleChangeFirewall}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Model Number:
                  <input
                    type="text"
                    name='model_number'
                    value={firewallData?.model_number || ''}
                    onChange={handleChangeFirewall}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Device Identifier:
                  <input
                    type="text"
                    name='identifier'
                    value={firewallData?.identifier || ''}
                    onChange={handleChangeFirewall}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Manufacturer:
                  <input
                    type="text"
                    name='manufacturer'
                    value={firewallData?.manufacturer || ''}
                    onChange={handleChangeFirewall}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="font-semibold mb-2">Energy Meter</h4>
              <div>
                <p>
                  Serial Number:
                  <input
                    type="text"
                    name='serialNumber'
                    value={assetDetails.wattnode1SerialNumber}
                    readOnly
                    className="rounded-md w-full py-1 px-2 bg-gray-200"
                  />
                </p>
                <p>
                  Firmware Version:
                  <input
                    type="text"
                    name='softwareVersion'
                    value={assetDetails.wattnode1Firware}
                    readOnly
                    className="rounded-md w-full py-1 px-2 bg-gray-200"
                  />
                </p>
                <p>
                  Model Number:
                  <input
                    type="text"
                    name='model_number'
                    value={energyMeterData?.model_number || ''}
                    onChange={handleChangeEnergyMeter}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Device Identifier:
                  <input
                    type="text"
                    name='identifier'
                    value={energyMeterData?.identifier || ''}
                    onChange={handleChangeEnergyMeter}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Manufacturer:
                  <input
                    type="text"
                    name='manufacturer'
                    value={energyMeterData?.manufacturer || ''}
                    onChange={handleChangeEnergyMeter}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
              </div>
            </div>

            <div className="mb-4">
              <h4 className="font-semibold mb-2">Switch</h4>
              <div>
                <p>
                  Serial Number:
                  <input
                    type="text"
                    name='serial_number'
                    value={switchData?.serial_number || ''}
                    onChange={handleChangeSwitch}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Firmware Version:
                  <input
                    type="text"
                    name='software_version'
                    value={switchData?.software_version || ''}
                    onChange={handleChangeSwitch}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Model Number:
                  <input
                    type="text"
                    name='model_number'
                    value={switchData?.model_number || ''}
                    onChange={handleChangeSwitch}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Device Identifier:
                  <input
                    type="text"
                    name='identifier'
                    value={switchData?.identifier || ''}
                    onChange={handleChangeSwitch}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
                <p>
                  Manufacturer:
                  <input
                    type="text"
                    name='manufacturer'
                    value={switchData?.manufacturer || ''}
                    onChange={handleChangeSwitch}
                    className="rounded-md w-full py-1 px-2"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Update Button */}
        <div className="flex justify-end ml-auto">
          <button
            className="rounded-md px-4 py-2 text-sm flex justify-center items-center"
            onClick={handleUpdateClick}
          >
            Update
          </button>
        </div>
      </div>

      {/* Section 4: Battery Modules */}
      <div className="mb-6 w-full">
        <div className='flex items-center gap-1'>
          <h3 className="text-lg font-semibold mb-2">Battery Modules</h3>
          <button
            className="add-event-button mb-2"
            onClick={() => {
              toggleCreateModal();
            }}
          >
            <IconContext.Provider value={{ color: '#0F3052' }}>
              <FiPlus />
            </IconContext.Provider>
          </button>
        </div>
        <BatteryModules
          batteryModules={batteryModules}
          assetName={assetName}
          fetchBatteryModules={fetchBatteryModules}
        />
      </div>

      {!!showCreateModal && (
        <Modal
          toggleModal={toggleCreateModal}
          isSmall={true}
          child={
            <BatteryModuleForm
              status="create"
              assetName={assetName}
              toggleModal={toggleCreateModal}
              fetchBatteryModules={fetchBatteryModules}
            />
          }
        />
      )}
    </div>
  );
};

export default BessInformation;