import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist/es/constants';

import { persistedUserReducer } from './user/userSlice';
import { persistedDashboardDataReducer } from './dashboardData/dashboardDataSlice';
import { persistedAssetRealTimeDataReducer } from './assetRealTimeData/assetRealTimeDataSlice'

export const store = configureStore({
  reducer: {
    user: persistedUserReducer,
    dashboardData: persistedDashboardDataReducer,
    assetRealTimeData: persistedAssetRealTimeDataReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
