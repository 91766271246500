import { createSelector } from '@reduxjs/toolkit';
import getDateWithoutTime from '../../helpers/formatDate/getDateWithoutTime';

// All data
const getDashboardAllData = state => state.dashboardData.data;
const getDashboardAllDataLoading = state => state.dashboardData.loading;

// Events
const getScheduledEvents = state => state.dashboardData.data.events.data;
const getFilterScheduledEvent = state => state.dashboardData.data.events.filter;
const getFilteredEvents = createSelector(
  [getScheduledEvents, getFilterScheduledEvent],
  (events, statusFilter) => {
    if (statusFilter === null) {
      return events;
    } else {
      return events.filter(
        event =>
          getDateWithoutTime(event.startTime) ===
          getDateWithoutTime(statusFilter)
      );
    }
  }
);
const getAddScheduledEventsLoading = state =>
  state.dashboardData.data.events.addEvent.loading;

// Assets
const getCurrentAsset = state =>
  state.dashboardData.data.assetList.currentAsset.data;
const getCurrentAssetLoading = state =>
  state.dashboardData.data.assetList.currentAsset.loading;

export const dashboardDataSelectors = {
  // All data
  getDashboardAllData,
  getDashboardAllDataLoading,

  // Events
  getScheduledEvents,
  getFilterScheduledEvent,
  getFilteredEvents,
  getAddScheduledEventsLoading,

  // Assets
  getCurrentAsset,
  getCurrentAssetLoading,
};
