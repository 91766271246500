import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from './useAuth';
import { Role } from '../constants/role.enum';

const useValidateToken = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const tkn = localStorage.getItem('cdnzAccessToken');
  const user = localStorage.getItem('cdnzUser');
  const acct = localStorage.getItem('cdnzRole');

  useEffect(() => {
    const validRedirect = () => {
      setAuth(() => {
        return { user: user, jwt: tkn, role: acct };
      });
      navigate(location.state ? location.state.from.pathname : '/');
    };

    if (tkn && acct && user && (
      acct === Role.PLATFORM_ADMINISTRATOR ||
      acct === Role.CLIENT_ADMINISTRATOR ||
      acct === Role.CLIENT_USER ||
      acct === Role.RESIDENT)
    ) {
      validRedirect();
    } else {
      navigate('/login');
    }

    // NOTE: check deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return;
};

export default useValidateToken;
