import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Assets
import { ReactComponent as Building } from '../assets/bx-building-house.svg'; // Building icon
import {
  FiUser,
  FiUsers,
  FiChevronDown,
  FiDatabase,
  FiInfo,
} from 'react-icons/fi';
import isPlatformAdministrator
  from '../helpers/accessControl/isPlatformAdministrator'; // User icon

function Nav() {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  const toggleIsActive = () => {
    setIsActive(!isActive);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target) && isActive) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isActive]);

  const handleLogout = () => {
    navigate('/logout');
  };

  return (
    <div className='flex flex-row' ref={menuRef}>
      <div className='mr-6 flex items-center relative'>
        <FiUser className='mr-2' size={20} />
        <span className='mr-1'>{localStorage.getItem('cdnzUser')}</span>
        <FiChevronDown
          size={18}
          className={`cursor-pointer transition-transform ${isActive ? 'rotate-180' : null
            }`}
          onClick={toggleIsActive}
        />

        <div className={`container-border absolute top-7 right-0 bg-white px-4 py-2 w-52 ${isActive ? null : 'hidden'}`}>
          {isPlatformAdministrator() ?
            <Link
              to='/accounts'
              className='flex py-1'
              onClick={toggleIsActive}
            >
              <Building className='mr-1' width='22' height='22' />
              <span>Customer Accounts</span>
            </Link>
            : null}

          <Link
            to='/users'
            className='flex py-1'
            onClick={toggleIsActive}
          >
            <FiUsers className='mr-1' size={22} />
            <span>Users</span>
          </Link>
          <Link
            to='/profile'
            className='flex py-1'
            onClick={toggleIsActive}
          >
            <FiUser className='mr-1' size={22} />
            <span>Profile Details</span>
          </Link>

          {isPlatformAdministrator() ?
            <Link
              to='/dr-programs'
              className='flex py-1'
              onClick={toggleIsActive}
            >
              <FiDatabase className='mr-1' size={29} />
              <span>Demand Response Programs</span>
            </Link>
            : null}

          {isPlatformAdministrator() ?
            <Link
              to='/about'
              className='flex py-1'
              onClick={toggleIsActive}
            >
              <FiInfo className='mr-1' size={20} />
              <span>About</span>
            </Link>
            : null}
        </div>
      </div>

      {localStorage.getItem('cdnzUser') && (
        <button
          className='logout-button rounded-md px-4 py-2 text-sm flex justify-center items-center'
          onClick={handleLogout}
        >
          Log out
        </button>
      )}
    </div>
  );
}

export default Nav;
