import { Role } from '../../constants/role.enum';

const isAdministrator = () => {
  const userRole = localStorage.getItem('cdnzRole');

  return (
    userRole === Role.CLIENT_ADMINISTRATOR ||
    userRole === Role.PLATFORM_ADMINISTRATOR
  );
};

export default isAdministrator;
